<!-- Created by henian.xu on 2018/1/12. -->

<template>
  <!--<transition
        :name="playAni"
        @before-enter="beforeEnter"
    >-->
  <div
    class="tab-panel"
    v-show="active"
    ref="panel"
  >
    <slot />
  </div>
  <!--</transition>-->
</template>

<script>
export default {
  name: 'TabPanel',
  data() {
    return {
      index: -1,
    };
  },
  computed: {
    active() {
      return this.$parent.currIndex === this.index;
    },
    playAni() {
      return this.$parent.panesAni;
    },
    panel_() {
      return this.$refs.panel;
    },
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    sign: {
      type: [Number, String],
      default: 0,
    },
    activePanel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    beforeEnter: function() {
      this.$nextTick(() => {
        this.$parent.tabContentHeight = this.panel_.clientHeight;
      });
    },
  },
  mounted() {
    this.$parent.addPanes(this);
  },
};
</script>

<style lang="scss">
</style>
