<!-- Created by henian.xu on 2018/2/5. -->

<template>
  <div class="breadcrumb">
    <div class="inner">
      <div 
        :class="['item',{'active':item.active}]"
        v-for="(item,index) in data"
        :key="index"
        @click="onItem(item,index)"
      >
        {{ item.name }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    onItem(item, index) {
      this.$emit('clickItem', { ...item }, index);
    },
  },
};
</script>

<style lang="scss">
.breadcrumb {
  min-height: 0.8rem;
  padding: $padding-small;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    > .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 $padding;
      background-color: #fff;
      border: 1px solid $color-border;
      border-radius: 0.05rem;
      white-space: nowrap;
      margin-right: $margin-small;

      &.active {
        background-color: $color-main;
        color: #fff;
      }
    }
  }
  &.second-header {
  }
}
</style>
