<!-- Created by henian.xu on 2018/7/8. -->
<!-- 确认订单页面选择配送方式组件 -->

<template>
  <div class="header">
    <div>配送方式：</div>
    <div class="btn-group">
      <div
        v-for="(item) in shipMethodList"
        :key="item.id"
        :class="['btn radius all bor translucent', item.id === value?'btn-main':'btn-g5']"
        @click="onItem(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ShipMethodHeader',
  data() {
    return {};
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    shipMethod: {
      type: [Number, String],
      default: 0,
    },
    goodsSendMode: {
      type: [Number, String],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('global', ['shipMethodMap']),
    shipMethodList() {
      const res = [];
      const { shipMethod, shipMethodMap, goodsSendMode } = this;
      let isIn = false;
      for (const key in shipMethodMap) {
        if (shipMethodMap.hasOwnProperty(key) && shipMethod & +key) {
          if (this.value === +key) isIn = true;
          let name = shipMethodMap[key];
          if (typeof name === 'object') {
            name = name[goodsSendMode];
          }
          res.push({
            id: +key,
            name,
          });
        }
      }
      if (!isIn && res.length) this.$emit('input', res[0].id);
      return res;
    },
  },
  methods: {
    onItem(type) {
      if (this.disabled) return;
      this.$emit('input', type);
    },
  },
};
</script>

<style lang="scss">
.ship-method {
  > .header {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $color-border;
  }
}
</style>
