<!-- Created by henian.xu on 2018/8/8. -->

<template>
  <div
    class="store-cart"
    @click="onShow"
  >
    <slot />

    <!--popup-->
    <transition name="show">
      <div
        class="popup store-cart-popup"
        @click.self="onSwitch"
        ref="popup"
        v-show="popupShow"
      >
        <transition name="popup-down">
          <div
            class="inner"
            v-show="popupShow"
          >
            <div class="header">
              <div class="label ta-l">
                已选购商品
              </div>
              <div
                class="btn"
                @click="onClearCart"
              >
                <i class="f-icon">&#xf008;</i>清空
              </div>
            </div>
            <div class="body">
              <list>
                <StoreCartGoodsItem
                  v-for="(item) in carts"
                  :key="item.id"
                  :data="item"
                />
              </list>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
import storeMixin from './storeMixin';
import StoreCartGoodsItem from './_storeCartGoodsItem';

export default {
  name: 'StoreCart',
  mixins: [popupMixin, storeMixin],
  components: { StoreCartGoodsItem },
  data() {
    return {
      appLevel: true,
    };
  },
  methods: {
    onShow() {
      if (!this.totalQuantity) return;
      this.onSwitch();
    },
    onClearCart() {
      this.clearCarts();
      this.onSwitch();
    },
  },
};
</script>

<style lang="scss">
.popup.store-cart-popup {
  bottom: 1rem;
  z-index: $z-index-1;
  justify-content: flex-end;
  align-items: stretch;
  > .inner {
    width: 100%;
    background-color: #fff;
  }
}
</style>
