<!-- Created by henian.xu on 2017/10/12 0012. -->

<template>
  <div
    v-if="true"
    class="buyer-user-box"
  >
    <div
      class="content"
      :style="personalCenterBackground?`background-image: url('${personalCenterBackground}')`:''"
    >
      <div
        class="inner"
        :style="personalCenterBackground?`color:#fff;`:''"
      >
        <Linker
          v-if="!data.id"
          class="user-info"
          to="/buyer/login?backUrl=/buyer/home"
        >
          <Avatar
            border
            radius="all"
            size="1.2rem"
            :src="$options.filters.imgCropping($defaultAvatar,{width:200})"
          />
          <div class="info">
            <div class="nickname">
              您尚未登录，点击登录
            </div>
          </div>
        </Linker>
        <div
          v-else
          class="user-info"
        >
          <Avatar
            border
            radius="all"
            to="/buyer/userInfo"
            size="1.2rem"
            :src="$options.filters.imgCropping(userData.userAvatar||$defaultAvatar,{width:200})"
          />
          <div class="info">
            <div class="nickname">
              {{ ($globalVar.isRegisterIHealth ? userData.userName :userData.nickName)||'点击设置用户头像和昵称' }}
            </div>
            <Linker
              class="account"
              :to="`tel://${userData.mobile}`"
            >
              {{ userData.mobile }}
            </Linker>
          </div>
          <Linker
            v-if="$hasPower(['buyerBalance:entrance'])"
            class="extra ta-c"
            to="/pocketMoney/index"
          >
            <XIcon
              content="pocketMoney"
              size="60"
              svg
            />
            <!--<div style="line-height: 1">
                          {{ data.purseBalance | price }}
                      </div>-->
          </Linker>
        </div>
      </div>
      <div class="data-info">
        <Linker
          class="item"
          to="/buyer/growthList"
        >
          <div class="value">
            {{ data.totalGrowthValue || 0 }}
          </div>
          <div class="label">
            成长值
          </div>
        </Linker>
        <Linker
          class="item"
          to="/buyer/pointList"
        >
          <div class="value">
            {{ data.validPoint || 0 }}
          </div>
          <div class="label">
            积分
          </div>
        </Linker>
        <Linker
          class="item"
          to="/buyer/couponList/1"
        >
          <div class="value">
            {{ data.couponNum || 0 }}
          </div>
          <div class="label">
            优惠券
          </div>
        </Linker>
        <Linker
          v-if="isDistributor"
          class="item"
          to="/buyer/income"
        >
          <span class="value price">{{ (data.income || 0) | price }}</span>
          <div class="label">
            总收入
          </div>
        </Linker>
      </div>
    </div>

    <div class="order-wrap">
      <div class="inner">
        <list>
          <list-item to="/order/list">
            <div
              class="label fw-bold"
              slot="label"
            >
              我的订单
            </div>
            <div
              class="extra fs-little tc-g6"
              slot="extra"
            >
              查看全部
            </div>
          </list-item>
        </list>
        <cube-toolbar class="border-cube-toolbar small">
          <linker
            to="/order/list/10"
            class="item"
          >
            <XIcon
              content="f0a0"
              :badge="orderCount.waitPayNum"
            />
            <div class="label">
              待付款
            </div>
          </linker>
          <linker
            v-if="$hasPower(['groupBuy:use'])"
            to="/order/list/15"
            class="item"
          >
            <XIcon
              content="f13c"
              :badge="orderCount.groupBuyNum"
            />
            <div class="label">
              待拼团
            </div>
          </linker>
          <linker
            to="/order/list/20"
            class="item"
          >
            <XIcon
              content="f0a1"
              :badge="orderCount.waitShipNum"
            />
            <div class="label">
              待发货
            </div>
          </linker>
          <linker
            to="/order/list/30"
            class="item"
          >
            <XIcon
              content="f0a2"
              :badge="orderCount.waitRecNum"
            />
            <div class="label">
              待收货
            </div>
          </linker>
          <!--这里的权限是因为放置的位置有差异-->
          <!--v-if="$hasPower(['distributorEntrance:entrance'])"-->
          <linker
            class="item npa-l npa-r"
            to="/refund/list"
          >
            <XIcon
              content="f0a3"
              :badge="orderCount.waitRefundNum"
            />
            <div class="label">
              退款/售后
            </div>
          </linker>
          <!--<linker
                      v-else
                      to="/review/list/10"
                      class="item"
                  >
                      <XIcon
                          content="f136"
                          :badge="orderCount.waitReview"
                      />
                      <div class="label">
                          待评价
                      </div>
                  </linker>-->
        </cube-toolbar>
      </div>
    </div>
  </div>
  <div
    v-else
    class="user-box"
  >
    <div class="inner">
      <Linker
        class="content"
        to="/buyer/userInfo"
      >
        <div class="img-obj">
          <div
            class="inner"
            :style="`background-image: url(${$options.filters.imgCropping(userData.userAvatar||$defaultAvatar,{width:200})});`"
          />
          <!--<div
                        class="brand-logo"
                        :style="`background-image: url('${userData.levelIconUrl}');`"/>-->
        </div>
        <div class="info">
          <div class="user-name">
            {{ userData.nickName||'点击设置用户头像和昵称' }}
            <span
              class="badge badge-yellow fs-small"
              v-if="userData.levelName"
            >{{ userData.levelName }}</span>
          </div>
          <div class="account">
            会员ID:{{ userData.userName }}
          </div>
          <div
            v-if="userData.mobile"
            class="mobile"
          >
            <i class="f-icon tc-blue">&#xf026;</i>{{ userData.mobile }}
          </div>
          <!--                <div>我的零钱: <span class="price">{{ data.purseBalance }}</span></div>-->
        </div>
      </Linker>
      <Linker
        class="pocket-money-wrap"
        to="/pocketMoney/index"
      >
        <div>我的零钱: </div>
        <div class="price">
          {{ data.purseBalance | price }}
        </div>
      </Linker>
    </div>
    <Linker
      v-if="data.totalGrowthValue || data.upgradeGrowthValue"
      class="footer"
      to="/buyer/growthList"
    >
      <span v-if="data.totalGrowthValue">我的成长值 <span class="tc-red">{{ data.totalGrowthValue }}</span></span>
      <span v-if="data.upgradeGrowthValue && data.upgradeDistributorLevelName">&nbsp;还差 <span class="tc-red">{{ data.upgradeGrowthValue }}</span> 可升级至 <span class="badge badge-second">{{ data.upgradeDistributorLevelName }}</span></span>
    </Linker>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'UserBox',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters('user', [
      'userData',
      'isDistributor',
      'isLogin',
      'distributionIncomeMode',
    ]),
    ...mapGetters('portalInfo', ['personalCenterBackground']),

    ...mapGetters('countInfo', { orderCount: 'orderCount' }),
  },
};
</script>

<style lang="scss">
.buyer-user-box {
  position: relative;
  //background-color: #fff;
  > .content {
    padding: $padding-big $padding-big 0.8rem $padding-big;
    background: $color-main center no-repeat;
    background-size: cover;
    color: #fff;
    > .inner {
      position: relative;
      z-index: $z-index-1;
      > .user-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        > .avatar {
          flex: 0 0 auto;
          margin-right: $margin;
        }
        > .info {
          flex: 1 1 1%;
          font-weight: bold;
          //line-height: 1.2;
          > .nickname {
            font-size: 120%;
            line-height: 1;
          }
          > .account {
            display: inline-block;
            padding: 0 0.12rem;
            background-color: rgba(#fff, 0.5);
            border-radius: 100vw;
            font-size: 0.24rem;
          }
        }
        > .extra {
          flex: 0 0 auto;
        }
      }
    }
    > .data-info {
      position: relative;
      padding: $padding-big $padding;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      color: #fff;
      > .item {
        position: relative;
        z-index: $z-index-1;
        flex: 1 1 1%;
        text-align: center;
        line-height: 1.2;
        > .value {
          font-size: 110%;
          font-weight: bold;
          color: #fff;
          white-space: nowrap;
        }
        > .label {
          font-size: 85%;
        }
      }
    }
  }
  > .order-wrap {
    padding: $padding-big;
    margin-top: -(0.8rem + $padding-big);
    > .inner {
      border-radius: $padding-small;
      overflow: hidden;
    }
  }
}
.user-box {
  > .inner {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    // min-height: (240vw / 750 * 100);
    /*min-height: 2.4rem;*/
    //background: $color-main url('~@/assets/images/buyer/home_bg.png') no-repeat center bottom;
    //background-size: 100% auto;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    padding: $padding;

    > .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //text-align: center;
      //min-height: 3.6rem;
      //padding-bottom: $padding;

      > .img-obj {
        width: 0.8rem;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        //border: 2px solid #fff;
        box-shadow: 0 6px 12px -4px $gray6;
        > .inner {
          width: 100%;
          height: 0;
          padding-top: 100%;
          background: $gray1 center center;
          background-size: cover;
          border-radius: 50%;
        }
        > .brand-logo {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0.5rem;
          height: 0.5rem;
          background: transparent center no-repeat;
          background-size: cover;
          border-radius: 50%;
        }
      }
      > .info {
        padding-left: $padding-small;
        line-height: 1.2;
        > .user-name {
          /*font-size: 40px;*/
          font-weight: bold;
        }
        > .account {
          font-size: 90%;
        }
        > .mobile {
          /*color: #000;*/
          font-size: 90%;
        }
      }
    }

    > .pocket-money-wrap {
      text-align: right;
      line-height: 1.1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }

    .level {
      .level-icon {
        width: 1em;
        height: 1em;
        vertical-align: middle;
      }
    }
  }
  > .footer {
    display: block;
    background-color: #fff;
    padding: $padding-small $padding;
    border-bottom: 1px solid $color-border;
  }
}
</style>
