<!-- Created by henian.xu on 2018/2/6. -->

<template>
  <div class="input date">
    <div
      :class="['label',{'empty':!currentValue}]"
      @click="onClickLabel"
    >
      {{ currentValue||placeholder }}
    </div>
    <input
      ref="date"
      v-bind="$props"
      :value="currentValue"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    >
  </div>
</template>

<script>
import emitter from '@/mixins/emitter';

export default {
  name: 'XDate',
  mixins: [emitter],
  data() {
    return {
      currentValue: this.value,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    form: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },

    clear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _date() {
      return this.$refs['date'];
    },
  },
  methods: {
    handleInput(event) {
      const value = event.target.value;
      this.currentValue = value;
      this.$emit('input', value);
      this.dispatch('formItem', 'x.form.change', [value]);
    },
    handleFocus() {},
    handleBlur() {
      this.dispatch('formItem', 'x.form.blur', [this.currentValue]);
    },
    handleChange() {},

    onClickLabel() {},
  },
};
</script>

<style lang="scss">
.input.date {
  position: relative;
  > .label {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 $padding-small;

    &.empty {
      opacity: 0.75;
    }
  }
  input {
    position: relative;
    z-index: 100;
    opacity: 0;
    appearance: none;
    background-color: transparent;
  }
}
</style>
