<!-- Created by henian.xu on 2018/4/19. -->

<template>
  <div class="countdown-item">
    {{ currVal }}
  </div>
</template>

<script>
export default {
  name: 'CountdownItem',
  data() {
    return {
      currVal: this.value,
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: '00',
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!isNaN(val) && +val < 10) {
          this.currVal = '0' + +val;
        } else {
          this.currVal = val + '';
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.countdown-item {
  position: relative;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  border-radius: 0.03rem;
  background-color: $gray6;
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
}
</style>
