<!-- Created by henian.xu on 2018/12/7. -->

<template>
  <div class="group-status">
    <i
      class="f-icon ma-r fs-super lh-1em"
      v-html="currentStatus.icon"
    />
    <div class="label">
      {{ currentStatus.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupStatus',
  data() {
    return {};
  },
  props: {
    status: {
      type: Number,
      required: true,
      default: 0,
    },
    joinGroup: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    statusMap() {
      return {
        10: {
          icon: '&#xf036;',
          label: '待成团',
        },
        99: {
          icon: '&#xf037;',
          label: '主人，因未凑够拼团人数，拼团失败',
        },
        100: {
          icon: '&#xf036;',
          label: this.joinGroup ? '拼团成功' : '拼团已满',
        },
      };
    },
    currentStatus() {
      return this.statusMap[this.status] || {};
    },
  },
};
</script>

<style lang="scss">
.group-status {
  background-color: $color-main;
  padding: $padding-big $padding;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
