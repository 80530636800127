<!-- Created by henian.xu on 2018/5/4. -->

<template>
  <div
    class="address-item"
    v-on="$listeners"
  >
    <div class="header">
      <div class="name">
        {{ data.consignee }} <span
          class="badge badge-main"
          v-if="!isEdit&&+data.id === defaultId"
        >默认</span>
      </div>
      <div class="mobile">
        {{ data.mobile }}
      </div>
    </div>
    <div class="body">
      {{ data.regionNamePath }}{{ data.address }} {{ data.postcode }}
    </div>
    <div
      class="footer"
      v-if="isEdit"
    >
      <checkbox
        type="radio"
        v-model="defaultId"
        :val="data.id"
      >
        设置默认地址
      </checkbox>
      <div>
        <div
          class="btn btn-g4 radius tc-w"
          @click="onDelete"
        >
          <i class="f-icon">&#xf008;</i>删除
        </div>
        <div
          class="btn btn-main radius ma-l"
          @click="onEdit"
        >
          <i class="f-icon">&#xf009;</i>编辑
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressItem',
  data() {
    return {};
  },
  props: {
    /* eslint-disable vue/require-prop-types */
    /* eslint-disable vue/require-default-prop */
    value: {},
    data: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultId: {
      get() {
        return this.value;
      },
      set(val) {
        this.$api.Buyer.Mb.BuyerDeliveryAddress.setDefault({
          id: val,
        }).then(() => {
          this.$emit('input', val);
        });
      },
    },
  },
  methods: {
    onDelete() {
      this.$messageBox.confirm('是否删除地址', '提示').then(() => {
        this.$api.Buyer.Mb.BuyerDeliveryAddress.delete({
          ids: this.data.id,
        }).then(() => {
          this.$emit('delete');
        });
      });
    },
    onEdit() {
      this.$api.Buyer.Mb.BuyerDeliveryAddress.detail({
        id: this.data.id,
      }).then(json => {
        this.$emit('edit', json.data.data);
      });
    },
  },
};
</script>

<style lang="scss">
.address-item {
  background-color: #fff;
  padding: $padding;
  border-top: 1px solid $color-border;
  &:first-child {
    border-top: 0;
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  > .body {
    margin-top: $margin;
  }
  > .footer {
    margin-top: $margin;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
