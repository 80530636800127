<!-- Created by henian.xu on 2018/12/6. -->

<template>
  <div class="join-user">
    <div class="inner">
      <div
        class="item"
        v-for="(item,index) in buyerList"
        :key="index"
      >
        <span v-if="!index">团主</span>
        <div
          class="inner"
          :style="`background-image: url('${item.avatar || whIcon}');`"
        />
      </div>
    </div>
    <div
      class="fold"
      v-if="isFold"
      @click="folded = !folded"
    >
      {{ folded?'查看全部拼友':'折叠全部拼友' }}
    </div>
  </div>
</template>

<script>
import whIcon from '@/assets/images/comm/wh-icon.png';
export default {
  name: 'JoinUser',
  data() {
    return {
      whIcon,
      rowCount: 7,
      folded: true,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  computed: {
    lastNums() {
      return this.data.groupBuyNums - this.data.joinBuyNums;
    },
    isFold() {
      return this.data.groupBuyNums > 14;
    },
    buyerList() {
      const { data, lastNums, isFold, folded, rowCount } = this;
      const res = [{ avatar: data.avatar }, ...(data.buyerList || [])];
      if (lastNums) {
        for (let i = 0; i < lastNums; i++) {
          res.push({});
        }
      }
      if (isFold && folded) {
        res.splice(rowCount * 2, res.length);
      }
      return res;
    },
  },
};
</script>

<style lang="scss">
.join-user {
  //padding: $padding;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding-small;
    > .item {
      flex: 0 0 auto;
      width: (100% / 7);
      padding: $padding-small;
      position: relative;
      > .inner {
        width: 100%;
        height: 0;
        padding-top: 100%;
        background: transparent center no-repeat;
        background-size: cover;
        border-radius: 100em;
        background-color: $gray2;
      }
      > span {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-second;
        color: #fff;
        border-radius: 5px;
        padding: 0 $padding-small;
        font-size: 50%;
      }
    }
  }

  > .fold {
    padding: $padding;
    text-align: center;
  }
}
</style>
