<!-- Created by henian.xu on 2019/6/24. -->

<template>
  <div class="x-table">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'XTable',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.x-table {
  background-color: #fff;
  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $color-border;
    th,
    td {
      text-align: left;
      border: 1px solid $color-border;
      padding: $padding-small;
    }
    > thead {
      th,
      td {
        background-color: $gray2;
      }
    }
  }
}
</style>
