<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <!--<div class="ad-swiper">ad swiper</div>-->
  <swiper
    ref="swiper"
    v-if="data.length"
    class="ad-swiper"
    :options="swiperOption_"
    @slideChangeTransitionEnd="onTransitionStart"
  >
    <swiper-slide
      :style="slideHeight?`height: ${slideHeight};`:''"
      v-for="(item,index) in data"
      :key="index"
    >
      <div
        class="slide-inner"
        :is="(item.link||item.href)?'linker':'div'"
        :to="item.link||item.href"
        :style="isImagesBackground&&!item.isVideo?`background-image: url('${$options.filters.imgCropping(item.imgUrl||$defaultImg)}');`:''"
      >
        <XVideo
          ref="video"
          v-if="item.isVideo"
          :src="item.src"
          :cover="item.cover"
          :aspect-ratio="1"
          @ready="onVideoReady($event,index)"
        />
        <img
          v-else-if="!isImagesBackground"
          class="img"
          :src="item.imgUrl||$defaultImg | imgCropping"
          alt=""
        >
        <div
          class="label"
          v-if="item.label"
        >
          {{ item.label }}
        </div>
      </div>
    </swiper-slide>
    <div
      class="swiper-pagination"
      slot="pagination"
    />
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'AdSwiper',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      playerMap: {},
    };
  },
  props: {
    swiperOption: {
      type: Object,
      default() {
        return {
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
        };
      },
    },
    isImagesBackground: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    slideHeight: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: null,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    swiperOption_() {
      const { swiperOption, delay, loop } = this;
      const res = { ...swiperOption, loop };
      if (delay !== null) {
        if (!+delay) {
          res.autoplay = false;
        } else {
          res.autoplay = res.autoplay || {};
          res.autoplay = +delay;
        }
      }
      return res;
    },
  },
  methods: {
    onVideoReady(event, index) {
      const { player } = event;
      const { playerMap } = this;
      playerMap[index] = player;
      const { swiper } = this.$refs;
      const swiper_ = swiper.swiper;
      if (swiper_.activeIndex === index) {
        player.play();
      }
    },
    onTransitionStart() {
      const {
        $refs: { video, swiper },
        playerMap,
      } = this;
      if (!video || !video.length) return;
      video.forEach(item => {
        if (!item.isPlaying || !item.pause) return;
        item.pause();
      });

      if (!swiper || !swiper.swiper) return;
      const { activeIndex } = swiper.swiper;
      const player = playerMap[activeIndex];
      if (player) player.play();
    },
  },
};
</script>

<style lang="scss">
.ad-swiper {
  .swiper-pagination {
    pointer-events: none;
    text-align: right;
    padding-right: $padding;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 0.2rem;
    height: 0.2rem;
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    background: $color-main;
  }

  .swiper-slide {
    display: inline-block;
    position: relative;
    line-height: 0;
    .slide-inner {
      width: 100%;
      min-height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      /*display: flex;
                text-align: center;*/
      > .img {
        /*position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                    min-width: 100%;*/
        width: 100%;
        height: auto;
      }
      > .label {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        line-height: $line-height;
        padding: $padding-small $padding;
        text-align: left;
      }
    }
  }
}
</style>
