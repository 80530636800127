<!-- Created by henian.xu on 2018/8/24. -->

<template>
  <div class="page-nav">
    <div class="nav-btn-group">
      <slot name="leftBtn">
        <div
          class="item f-icon"
          @click="$router.back()"
        >
          &#xf011;
        </div>
      </slot>
    </div>
    <slot name="title">
      <div class="title">
        <div class="inner">
          {{ title }}
        </div>
        <div class="sub-title">
          {{ subTitle }}
        </div>
      </div>
    </slot>
    <div class="nav-btn-group">
      <slot name="rightBtn">
        <div class="item f-icon">
          &#xf000;
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNav',
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.page-nav {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  min-height: $navHeight;
  background-color: $color-main;
  color: #fff;

  > .title {
    flex: 1 1 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 120%;
    line-height: 1.2;
    > .inner {
    }
    > .sub-title {
      font-size: 70%;
    }
  }
  > .nav-btn-group {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    > .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 $padding;
      min-width: $navHeight;
    }
  }
}
</style>
