<!-- Created by henian.xu on 2019/2/28. -->

<template>
  <i
    :class="classes"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <svg
      v-if="svg"
      :style="styles"
      class="svg-inner"
    >
      <slot>
        <use
          :xlink:href="iconName"
          class="active"
          style="fill:blue"
        />
      </slot>
    </svg>
    <span
      v-else
      :style="styles"
      class="inner"
    >
      <slot>{{ content_ }}</slot>
    </span>
    <slot name="badge">
      <Badge
        v-if="badge"
        :label="badge"
        :size="badgeSize"
      />
    </slot>
  </i>
</template>

<script>
import(/* webpackChunkName: "svgs" */ './loadSvg');
import linkerMixin from '../mixins/linkerMixin';
import commonMixin from '../mixins/commonMixin';
export default {
  name: 'XIcon',
  mixins: [commonMixin, linkerMixin],
  data() {
    return {};
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    badge: {
      type: [String, Number],
      default: '',
    },
    badgeSize: {
      type: String,
      default: '0.26rem',
    },
    svg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const { theme, size, size_, pointer, svg } = this;
      return [
        'x-icon',
        'f-icon',
        theme,
        size_ ? '' : size,
        { svg },
        { pointer },
      ];
    },
    styles() {
      const { size_ } = this;
      if (!size_) return {};
      return { fontSize: size_ };
    },
    size_() {
      const { size } = this;
      const int = parseInt(size);
      if (isNaN(int)) return '';
      if (!+size) return size;
      return int + 'px';
    },
    content_() {
      let { content, svg } = this;
      if (svg) return '';
      const rex = /^&#x/;
      if (/^f[\d,a-f]{3}$/.test(content)) content = `&#x${content};`;
      if (!content || !rex.test(content)) return content;
      content = content.replace(rex, '\\u');
      content = content.replace(';', '');
      return this.$utils.Decode.uniDecode(content);
    },
    iconName() {
      return `#icon-${this.content}`;
    },
  },
};
</script>

<style lang="scss">
.x-icon {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &.svg {
    display: inline-block;
    > .svg-inner {
      width: 1em;
      height: 1em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }

  > .x-badge {
    position: absolute;
    top: 0;
    left: calc(100% - 0.5em);
    transform: translateY(-50%);
  }

  /* 主题颜色 */
  @each $key, $value in $colorList {
    &.#{$key + ''} {
      > .inner,
      > .svg-inner {
        color: $value;
      }
    }
  }
  /* 尺寸大小 */
  @each $key, $value in $sizeList {
    &.#{$key + ''} {
      > .inner,
      > .svg-inner {
        font-size: $value * 100%;
      }
    }
  }
}
</style>
