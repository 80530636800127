/**
 * Created by henian.xu on 2019/6/10.
 *
 */

// import MeiQia from '_MEIQIA';
// import loadMeiQia from './loadMeiQia';
import GlobalVar from 'globalVar';
const { userData } = GlobalVar;

export default new class MeiQiaControl {
  constructor() {
    // console.log('MeiQia class init');
    this.MeiQia = null;
    this.isShowPanel = false;
    this.meiQiaAllSet = false;
    // 未读消息
    this.unreadMsg = [];

    if (GlobalVar.meiQiaEntId) {
      // loadMeiQia(GlobalVar.meiQiaEntId).then(MeiQia => {
      this.MeiQia = this.loadMeiQia();
      this.MeiQia('entId', GlobalVar.meiQiaEntId);
      // 在这里开启手动模式（必须紧跟美洽的嵌入代码）
      this.MeiQia('manualInit');
      this.MeiQia('withoutBtn');
      this.MeiQia('fallback', 1);
      this.initClient();
      this.initEvent();
      this.MeiQia('init');
      // });
    }
  }
  loadMeiQia(global = window, doc = document) {
    const name = '_MEIQIA';
    global[name] =
      global[name] ||
      function() {
        (global[name].a = global[name].a || []).push(arguments);
      };
    const scriptNode = doc.createElement('script');
    const s = doc.getElementsByTagName('script')[0];
    scriptNode.async = true;
    scriptNode.charset = 'UTF-8';
    // scriptNode.src = 'https://static.meiqia.com/dist/meiqia.js?_=t';
    scriptNode.src = 'https://static.meiqia.com/widget/loader.js';
    s.parentNode.insertBefore(scriptNode, s);
    return global[name];
  }
  initClient() {
    // 设置 clientId
    const clientId = `${userData.countryCode || ''}${userData.mobile || ''}`;
    if (clientId) this.MeiQia('clientId', clientId);
    this.MeiQia('metadata', {
      name: userData.distributorName || userData.mobile, // 名字
      tel: userData.mobile, // 电话
      // weixin: '', // 微信
      // qq: '', // QQ
      // email: '', // 邮箱
      // address: '', // 地址
      // gender: '', // 性别
      // age: '', // 年龄
      // weibo: '', // 微博
      // comment: '', // 备注
    });
  }
  initEvent() {
    // 初始化成功时的回调
    this.MeiQia('allSet', this.onAllSet.bind(this));
    // 获取未读消息
    this.MeiQia('getUnreadMsg', this.onUnreadMsg.bind(this));
  }
  onAllSet() {
    // console.log('onAllSet');
    this.MeiQia = window._MEIQIA;
    this.meiQiaAllSet = true;
  }
  onUnreadMsg(msg) {
    if (msg === 'hasBeenRead') {
      this.clearUnread();
      return;
    }
    if (!Array.isArray(msg)) {
      console.error('meiQia 返回的消息不是数组类型');
      return;
    }
    this.unreadMsg = this.unreadMsg.concat(msg);
  }

  /**
   * 清空未读消息
   */
  clearUnread() {
    this.unreadMsg = [];
  }
  showPanel({ agentToken, groupToken } = {}) {
    this.MeiQia('showPanel', {
      agentToken,
      groupToken,
    });
    //659e1a90aff46c7e007b88c1f2fc62e7  xhn-test
    //6d0c1b1a93be5b125ff593f13f00bde5  xhn-test2
    //adc2ec8bc087b454cac355d8d0536655  xhn-test3
    //041bf18cff0e47df30655dc4d68831a8
    console.log(agentToken, groupToken);
    this.isShowPanel = true;
  }
  hidePanel() {
    this.MeiQia('hidePanel');
    this.isShowPanel = false;
  }
}();
