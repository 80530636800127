/**
 * Created by henian.xu on 2018/8/8.
 * 门店的数据统一管理
 */

import { Buyer } from '@/api';

export default {
  namespaced: true,
  modules: {},
  state: {
    storeId: '',
    goodsCatList: [],
    storeInfo: {},
    carts: [],
  },
  getters: {
    cartMap(state) {
      const res = {};
      state.carts.forEach((item, index) => {
        res[item.id] = {
          ...item,
          __index: index,
        };
      });
      return res;
    },
    cartGoodsMap(state) {
      const res = {};
      state.carts.forEach(item => {
        res[item.goodsId] = res[item.goodsId] || [];
        res[item.goodsId].push(item);
      });
      return res;
    },
    totalQuantity(state) {
      return state.carts.reduce((prev, curr) => {
        return prev + curr.quantity;
      }, 0);
    },
    totalAmount(state) {
      return state.carts.reduce((prev, curr) => {
        return prev + curr.quantity * curr.price;
      }, 0);
    },
  },
  mutations: {
    setGoodsCatList(state, val) {
      state.goodsCatList = val;
    },
    setStoreInfo(state, val) {
      state.storeInfo = val;
    },
    setStoreId(state, val) {
      state.carts = [];
      state.storeId = val;
    },
    clearCarts(state) {
      state.carts = [];
    },
    setCart(state, val) {
      state.carts = val;
    },
  },
  actions: {
    getGoodsCatList({ commit, state }) {
      Buyer.Sp.ShopStoreGoods.shopStoreGoodsCatList({
        storeId: state.storeId,
      }).then(json => {
        // state.goodsCatList = json.data.data;
        commit('setGoodsCatList', json.data.data);
      });
    },
    getShopStoreInfo({ commit, state }) {
      Buyer.Sp.ShopStore.shopStoreInfo({
        id: state.storeId,
      }).then(json => {
        commit('setStoreInfo', json.data.data);
      });
    },
    storeRemoveCart({ commit, state, getters }, val) {
      const carts = [...state.carts];
      const mapItem = getters.cartMap[val.id];
      if (!mapItem) return;
      mapItem.quantity--;
      const __index = mapItem.__index;
      if (mapItem.quantity > 0) {
        carts.splice(__index, 1, mapItem);
      } else {
        carts.splice(__index, 1);
      }
      commit('setCart', carts);
    },
    storeAddCart({ commit, state, getters }, val) {
      const carts = [...state.carts];
      const mapItem = getters.cartMap[val.id];
      const data = mapItem || { ...val, quantity: 0 };
      data.quantity += 1;
      if (mapItem) {
        const __index = mapItem.__index;
        carts.splice(__index, 1, data);
      } else {
        carts.push(data);
      }
      commit('setCart', carts);
    },
  },
};
