<!-- Created by henian.xu on 2021/11/11. -->

<template>
  <RawWaterfallSlot
    v-bind="realProps"
    :style="isShow?'':`opacity: 0;`"
    ref="waterfallSlot"
    move-class="vue-waterfall-slot-ani"
  >
    <div
      class="inner"
      ref="inner"
    >
      <slot />
    </div>
  </RawWaterfallSlot>
</template>

<script>
import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot';
import { addListener, removeListener } from 'resize-detector';
export default {
  name: 'WaterfallSlot',
  components: { RawWaterfallSlot: WaterfallSlot },
  data() {
    return {
      isShow: false,
      props: {},
    };
  },
  props: {
    width: {
      // required: true,
      validator: val => val >= 0,
      default: null,
    },
    height: {
      // required: true,
      validator: val => val >= 0,
      default: null,
    },
    order: {
      type: Number,
      default: 0,
    },
    moveClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    realProps() {
      const { $props, props } = this;
      return {
        ...$props,
        ...props,
      };
    },
  },
  methods: {
    // 原方法
    notify() {
      this.$parent.$emit('reflow', this);
    },
    getMeta() {
      const {
        $refs: { waterfallSlot },
      } = this;
      if (!waterfallSlot || !waterfallSlot.getMeta)
        return {
          vm: this,
          node: this.$el,
          ...this.realProps,
        };
      return waterfallSlot.getMeta();
    },
    // 自定方法
    listenSize() {
      const {
        $refs: { inner },
      } = this;
      if (!inner) return;
      // window.ResizeObserver = null;
      if (window.ResizeObserver) {
        const resizeObserver = new ResizeObserver(this.onResizeObserver);
        resizeObserver.observe(inner);
      } else {
        addListener(inner, this.onResizeObserver);
      }
    },
    onResizeObserver(entries) {
      let width = 0;
      let height = 0;
      if (window.ResizeObserver) {
        if (!entries || !entries.length) return;
        const [{ contentRect }] = entries;
        ({ width, height } = contentRect);
      } else {
        width = (entries || {}).clientWidth || 0;
        height = (entries || {}).clientHeight || 0;
      }
      if (!width && !height) return;
      this.$set(this.props, 'width', width);
      this.$set(this.props, 'height', height /*+ ~~(Math.random() * 150)*/);
      setTimeout(() => {
        this.isShow = true;
      }, 200);
    },
  },
  created() {
    this.$on('reflow', () => {
      this.notify();
    });
  },
  mounted() {
    this.$parent.$once('reflowed', () => {
      this.$emit('reflowed', this);
    });
    this.listenSize();
  },
  beforeDestroy() {
    if (!window.ResizeObserver) {
      removeListener(this.$refs.inner, this.onResizeObserver);
    }
  },
};
</script>

<style lang='scss'>
.vue-waterfall-slot-ani {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
</style>
