<!-- Created by henian.xu on 2018/9/7. -->

<template>
  <div
    v-if="+type===3"
    class="goods"
    style="padding: 0 0.1rem;"
  >
    <Waterfall
      :watch="goodsList"
      @reflowed="onReflowed"
    >
      <WaterfallSlot
        v-for="(item,index) in goodsList"
        :key="item.id"
        :order="index"
      >
        <GoodsItem
          :data="item"
          waterfall
        />
      </WaterfallSlot>
    </Waterfall>
    <infinite-loading
      ref="infinite"
      load-first
      :disabled="infiniteDisabled"
      @infinite="onInfinite"
    />
  </div>
  <list
    v-else
    class="goods"
  >
    <GoodsItem
      v-for="item in goodsList"
      :key="item.id"
      :data="item"
      :double-row="+type!==1"
    />
    <infinite-loading
      ref="infinite"
      load-first
      :disabled="infiniteDisabled"
      @infinite="onInfinite"
    />
  </list>
</template>

<script>
import infiniteLoading, { waterfallMixin } from '@/mixins/infiniteLoading';
export default {
  name: 'GoodsList',
  mixins: [infiniteLoading, waterfallMixin],
  data() {
    return {
      goodsList: [],
      innerAdList: [],
      insertedAdList: [],
      insertedIndex: 0,
    };
  },
  props: {
    // 单列:1;双列:2;
    type: {
      type: [Number, String],
      default: 1,
    },
    orderBy: {
      type: String,
      default: 'onSaleTime,asc',
      validator(val) {
        return val.split(',').length >= 2;
      },
    },
    adList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    orderByKey() {
      const orderByArr = this.orderBy.split(',');
      return orderByArr[0];
    },
    orderByType() {
      const orderByArr = this.orderBy.split(',');
      return orderByArr[1];
    },
  },
  watch: {
    orderBy: {
      handler(val) {
        if (!val) return;
        const orderByArr = val.split(',');
        if (!orderByArr || !orderByArr.length) return;
        this.$api.Buyer.Gd.Goods.goodsList({
          orderByKey: orderByArr[0],
          orderByType: orderByArr[1],
          pageSize: 6,
        }).then(json => {
          this.goodsList = json.data.data;
        });
      },
      // immediate: true,
    },
    adList: {
      handler(val) {
        this.innerAdList = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
    },
  },
  methods: {
    async getListData({ currentPage } = {}) {
      const json = await this.$api.Buyer.Gd.Goods.goodsList({
        orderByKey: this.orderByKey,
        orderByType: this.orderByType,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
      });
      const res = json.data;
      const { data: dataList, pagination } = res;
      let currentIndex =
        Math.max(pagination.currentPage - 1, 0) * pagination.pageSize;
      const resList = [];
      dataList.forEach(item => {
        if (!this.innerAdList.length) {
          this.innerAdList = this.insertedAdList;
          this.insertedAdList = [];
        }
        if (currentIndex === this.insertedIndex && this.innerAdList.length) {
          const adData = this.innerAdList.shift();
          this.insertedAdList.push(adData);
          this.insertedIndex = currentIndex + this.$utils.Comm.random(10, 15);
          resList.push(adData);
        }
        currentIndex += 1;
        resList.push(item);
      });
      this.goodsList = this.goodsList.concat(resList);
      this.isComplete(json);
      return {
        ...json,
        disabledWaterfall: +this.type !== 3,
      };
    },
    isComplete(json) {
      const result = json.data;
      const { currentPage, totalRows, totalPages } = result.pagination;
      if (!totalRows || currentPage === totalPages) {
        this.infiniteDisabled = true;
        this.$emit('complete');
      }
    },
  },
};
</script>

<style lang="scss">
</style>
