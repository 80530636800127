<!-- Created by henian.xu on 2019/5/15. -->

<template>
  <div
    class="proxy-store-edit-goods-item"
    v-on="$listeners_"
  >
    <!--.inner>(.img-obj+.body>(.label{label}+.desc{desc}+.price{888}))-->
    <div class="inner">
      <div class="img-obj">
        <checkbox
          v-if="model"
          :val="data.id"
          v-model="model"
        />
        <div
          class="inner"
          :style="`background-image: url('${data.mainImageUrl||$defaultImg}');`"
        />
      </div>
      <div class="content">
        <div class="body">
          <div class="label">
            {{ data.goodsName }}
          </div>
          <div class="desc">
            {{ data.goodsSubtitle }}
          </div>

          <div v-if="isCtrl">
            <div><span class="badge badge-main fs-small">售卖价</span>&nbsp;<span class="price">{{ data.addPrice | price }}</span></div>
            <div><span class="badge badge-main fs-small">进货价</span>&nbsp;<span class="price">{{ data.price | price }}</span></div>
          </div>
        </div>
        <div class="footer">
          <span
            v-if="!isCtrl"
            class="price"
          >{{ data.price | price }}</span>
          <div v-else />
          <div
            v-if="isCtrl"
            class="ctrl"
          >
            <Button
              label="取消代理"
              theme="red"
              size="small"
              radius
              @click="()=>{onCancelProxy()}"
            />
            <Button
              v-if="data.goodsCatIdList && data.goodsCatIdList.length>1"
              label="移出分类"
              theme="red"
              size="small"
              radius
              @click="()=>{onDel()}"
            />
            <Button
              theme="blue"
              size="small"
              radius
            >
              <ProxyStoreAddGoods
                :goods-id="data.id"
                @success="onEditSuccess"
              >
                编辑
              </ProxyStoreAddGoods>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProxyStoreEditGoodsItem',
  data() {
    return {};
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    isCtrl: {
      type: Boolean,
      default: false,
    },
    catId: {
      type: [String, Number],
      default: void 0,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    $listeners_() {
      const data = {
        ...this.$listeners,
      };
      delete data.input;
      return data;
    },
  },
  methods: {
    async onDel() {
      await this.$messageBox.confirm('确认从该分类删除此商品');
      const json = await this.$api.Buyer.Sp.ShopStoreGoods.delAgentGoodsCat({
        id: this.data.id,
        goodsCatId: this.catId,
      });
      this.$emit('delete');
      this.$messageBox.tips(json.data.msg);
    },
    async onCancelProxy() {
      await this.$messageBox.confirm(
        '取消代理将从所有分类中删除该商品,<br>确认要取消代理此商品?',
      );
      const json = await this.$api.Buyer.Sp.ShopStoreGoods.cancelAgentGoods({
        ids: this.data.id,
      });
      this.$emit('delete');
      this.$messageBox.tips(json.data.msg);
    },
    onEditSuccess() {
      this.$emit('editSuccess');
    },
  },
};
</script>

<style lang="scss">
.proxy-store-edit-goods-item {
  background-color: #fff;
  padding: $padding-small;
  flex: 1 1 1%;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .img-obj {
      position: relative;
      flex: 0 0 auto;
      width: 2.6rem;
      margin-right: $margin-small;
      > .inner {
        background: center no-repeat;
        background-size: cover;
        width: 100%;
        padding-top: 100%;
      }
      > .checkbox {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    > .content {
      flex: 1 1 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      > .body {
        flex: 1 1 1%;
        /*display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;*/
        .label {
          font-weight: bold;
          line-height: 1.2;
          @include text-line(2);
        }
      }
      > .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .ctrl {
          > .button {
            + .button {
              margin-left: $margin-small;
            }
          }
        }
      }
    }
  }
}
</style>
