/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Rs } from '@/api/comm/Rs/Rs';

class CountryCode extends Rs {
  constructor() {
    super();
    this.baseUrl += '/countryCode';
  }

  /**
   * 获取国家区域代码
   * @returns {AxiosPromise<any>}
   */
  findShopCountryCodeList() {
    return super.get(`/findShopCountryCodeList`);
  }
}

export default new CountryCode();
