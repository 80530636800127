<!-- Created by henian.xu on 2019/8/13. -->

<template>
  <div
    class="store-order-edit-price"
    @click.stop.prevent="onSwitch"
  >
    <slot />

    <!--popup-->
    <transition
      v-if="!delayedCreate"
      :name="aniName"
    >
      <Page
        class="bc-g2"
        ref="popup"
        v-show="popupShow"
      >
        <div class="footer">
          <div
            class="btn btn-main"
            @click="()=>{onSubmit()}"
          >
            提交
          </div>
        </div>
        <container>
          <StoreGoodsItem
            v-for="(item,index) in orderDetailList"
            is-edit-price
            :key="index"
            :data="item"
          />
        </container>
      </Page>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
export default {
  name: 'StoreOrderEditPrice',
  mixins: [popup],
  data() {
    return {
      orderDetailList: [],
    };
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async onSubmit() {
      const orderDetailJson = this.orderDetailList.map(item => ({
        id: item.id,
        price: item.finalPrice,
      }));
      const json = await this.$api.Buyer.Sp.ShopStoreOrder.editOrder({
        id: this.orderId,
        orderDetailJson: JSON.stringify(orderDetailJson),
      });
      const res = json.data;
      this.$messageBox.tips(res.msg);
      this.$emit('success', res.data);
      this.close();
    },
  },
  created() {
    this.orderDetailList = this.data.map(item => ({ ...item }));
  },
};
</script>

<style lang="scss">
.store-order-edit-price {
}
</style>
