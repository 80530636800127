<!-- Created by henian.xu on 2018/4/28. -->

<template>
  <div class="popup-menu">
    <!--<slot name="icon">
            <i class="f-icon" v-if="icon" v-html="icon"></i>
        </slot>
        <slot name="label">
            <div class="label" v-if="label">{{label}}</div>
        </slot>-->
    <div 
      class="inner"
      @click="onSwitch"
    >
      <slot name="inner" />
    </div>
    <div
      :class="['popup-wrap',position]"
      ref="popupWrap"
      v-show="popupShow"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMenu',
  data() {
    return {
      items: [],
      position: '',
      popupShow: false,
    };
  },
  props: {},
  computed: {
    $$popupWrap: {
      get() {
        return this.$refs['popupWrap'];
      },
      cache: false,
    },
  },
  watch: {
    popupShow: {
      handler(val) {
        if (!this.$$popupWrap || !val) return;
        this.$nextTick(() => {
          const DOMRect = this.$$popupWrap.getBoundingClientRect();
          const data = {
            // x: DOMRect.x || DOMRect.left,
            // y: DOMRect.y || DOMRect.top,
            width: DOMRect.width,
            height: DOMRect.height,
            top: DOMRect.top,
            right: DOMRect.right,
            bottom: DOMRect.bottom,
            left: DOMRect.left,
          };
          for (const key in data) {
            if (!data.hasOwnProperty(key) || data[key] >= 0) continue;
            this.position = key;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    addItem(item) {
      const index = this.$slots.default
        .filter(n => {
          return n.elm.nodeType === 1 && /\bmenu-item\b/.test(n.elm.className);
        })
        .indexOf(item.$vnode);
      this.items.splice(index, 0, item);
    },
    setPosition() {
      if (!this.$$popupWrap) {
        this.position_ = '';
      } else if (this.$$popupWrap.offsetLeft < 0) {
        this.position_ = 'left';
      } else if (
        this.$$popupWrap.offsetLeft + this.$$popupWrap.clientWidth >
        this.$globalVar.device.width
      ) {
        this.position_ = 'right';
      } else {
        this.position_ = '';
      }
    },
    onSwitch() {
      this.popupShow = !this.popupShow;
      if (this.popupShow) {
        document.body.addEventListener('mousedown', this.onTouchStart);
      } else {
        document.body.removeEventListener('mousedown', this.onTouchStart);
      }
    },
    onTouchStart() {
      setTimeout(() => {
        this.popupShow = false;
      }, 300);
      document.body.removeEventListener('mousedown', this.onTouchStart);
    },
  },
  mounted() {
    this.setPosition();
  },
};
</script>

<style lang="scss">
.popup-menu {
  display: inline-block;
  position: relative;
  > .inner {
    cursor: pointer;
  }
  > .popup-wrap {
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid $color-border;
    border-radius: 8px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }
    &:before {
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $color-border;
    }
    &:after {
      z-index: 100;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% - 2px);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #fff;
    }

    &.left {
      left: $padding-small;
      transform: none;
      &:before,
      &:after {
        left: 8px;
        transform: none;
      }
    }
    &.right {
      left: auto;
      right: 0;
      transform: none;
      &:before,
      &:after {
        left: auto;
        right: 8px;
        transform: none;
      }
    }
  }
}
</style>
