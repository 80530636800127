/**
 * Created by henian.xu on 2018/8/27.
 * label 混合器
 */

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    innerClass: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    defaultSlotAtInner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    __labelProps() {
      const { icon, iconClass, label, subLabel } = this;
      return { icon, iconClass, label, subLabel };
    },
    classes() {
      return [];
    },
    styles() {
      return [];
    },
    bindProps() {
      return {}; // this.$props;
    },
    listenerProps() {
      return {}; // this.$listeners;
    },
    hasIcon() {
      return !!this.icon || !!this.$slots.icon;
    },
    hasLabel() {
      return !!this.label || !!this.$slots.label;
    },
    hasSubLabel() {
      return !!this.subLabel || !!this.$slots.subLabel;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasInner() {
      const { $slots, hasLabel, hasDefaultSlot, hasSubLabel } = this;
      const { inner: $inner } = $slots;
      return !!$inner || ((hasLabel || hasDefaultSlot) && hasSubLabel);
    },
  },
};
