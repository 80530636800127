/**
 * Created by henian.xu on 2020/3/15.
 *
 */

import { addScript, addStyle } from 'utils/index';

export async function loadAliplayer() {
  if (window.Aliplayer) return window.Aliplayer;
  await Promise.all([
    addScript('//g.alicdn.com/de/prismplayer/2.9.0/aliplayer-h5-min.js'),
    addStyle(
      '//g.alicdn.com/de/prismplayer/2.9.0/skins/default/aliplayer-min.css',
    ),
  ]);
  const { Aliplayer } = window;
  return Aliplayer;
}
