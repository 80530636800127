<!-- Created by henian.xu on 2018/1/8. -->

<template>
  <div
    :class="['checkbox',{disabled}]"
    @click="onClick"
  >
    <div class="input">
      <template v-if="iconLabel">
        <i
          class="icon checked"
          v-if="checked"
        >{{ iconLabel }}</i>
        <i
          v-else
          class="icon"
        >{{ iconLabel }}</i>
      </template>
      <template v-else>
        <i
          class="f-icon checked"
          v-if="checked"
        >&#xf019;</i>
        <i
          v-else
          class="f-icon"
        >&#xf01b;</i>
      </template>
      <input
        v-if="type === 'radio'"
        :id="id"
        type="radio"
        :name="name"
        :disabled="disabled"
        v-model="model"
        :value="val"
      >
      <input
        v-else
        :id="id"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        v-model="model"
        :value="val"
      >
    </div>
    <label :for="id">
      <!--{{model}}-->
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  data() {
    return {};
  },
  props: {
    /* eslint-disable vue/require-prop-types */
    /* eslint-disable vue/require-default-prop */
    value: {},
    val: {
      type: [String, Number, Boolean, Array, Object],
      default: undefined,
    },
    iconLabel: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stopPropagation: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox', // radio
    },
    id: {
      type: String,
      default() {
        return this.$utils.Comm.getUniqueId(`${this.type}-`);
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    checked() {
      if (this.val && this.type === 'checkbox') {
        if (typeof this.model === 'boolean') return this.model;
        return this.model.indexOf(this.val) !== -1;
      } else {
        if (this.val === undefined || this.val === null) {
          return this.model;
        } else {
          return this.model === this.val;
        }
      }
    },
  },
  methods: {
    // 只为了阻止默认行为而存在
    onClick($event) {
      this.stopPropagation && $event.stopPropagation();
    },
  },
};
</script>

<style lang="scss">
$currSize: 0.4rem !default;

.checkbox {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*flex-wrap: wrap;*/

  > .input {
    display: block;
    flex: 0 0 auto;
    position: relative;
    line-height: 0;
    margin-right: $margin-small;
    border: 0;
    //line-height: $currSize;

    > .icon {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: $currSize;
      height: $currSize;
      //line-height: $currSize;
      font-style: inherit;
      border: 2px solid $color-text;
      border-radius: $currSize/2;
      padding: 0 $padding-small;
      text-align: center;

      &.checked {
        background-color: $color-main;
        border-color: $color-main;
        color: #fff;
      }
    }
    > .f-icon {
      font-size: $currSize;
      margin: 0;
      padding: 0;
      width: $currSize;
      min-width: $currSize;
      line-height: 1;
      text-align: center;
      &.checked {
        color: $color-main;
      }
    }
    > input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      appearance: none;
      opacity: 0;
    }
  }
  > label {
    flex: 1 1 auto;
    //line-height: $currSize;
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
