<!-- Created by henian.xu on 2018/8/15. -->

<template>
  <div
    v-show="catList && catList.length>1"
    class="cat-picker"
    ref="main"
  >
    <div
      class="cat-picker-nav"
      ref="nav"
    >
      <div
        class="inner"
        ref="inner"
      >
        <div
          ref="navItem"
          :class="['item',{'exact-active':item.id===model}]"
          :to="item.href"
          v-for="(item) in catList"
          :key="item.id"
          @click="model = item.id"
        >
          {{ item.name }}
          <!--{{item.href}}{{exactPath}}-->
        </div>
      </div>
      <!--v-if="isShowExpand"-->
      <div
        class="expand collapse"
        @click="onExpand"
      >
        <i
          class="f-icon"
          v-if="popupShow"
        >&#xf00e;</i>
        <i
          class="f-icon"
          v-else
        >&#xf010;</i>
      </div>
    </div>

    <!-- popup -->
    <transition name="show">
      <div
        class="popup cat-picker-popup"
        ref="popup"
        :style="popupTop"
        v-show="popupShow"
        @click="onSwitch"
      >
        <transition name="query-box">
          <div
            class="inner"
            v-show="popupShow"
          >
            <!--            <div class="header">
              {{ currentCat.name }}
            </div>-->
            <div class="body">
              <div class="cat-picker-list">
                <!--:to="`/index${(index-1)?index:''}`"-->
                <div
                  :class="['item',{'exact-active':item.id===model}]"
                  v-for="(item,index1) in catList"
                  :to="item.href"
                  :key="index1"
                  @click="model = item.id"
                >
                  <div
                    class="icon"
                    :style="`background-image: url('${item.goodsCatImageUrl||$defaultImg}');`"
                  />
                  <div
                    class="label"
                    v-if="expandLabelType"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import popupMixin from '@/mixins/popup';
import emitterMixin from '@/mixins/emitter';

const aNode = document.createElement('a');

export default {
  name: 'CatPicker',
  mixins: [popupMixin, emitterMixin],
  data() {
    return {
      isShowExpand: false,
      isOnTop: false,
      navClientRect: {},
      index: 0,
      top: 0,
    };
  },
  props: {
    data: {
      type: Array,
      // required: true,
      default() {
        return [];
        /*return new Array(10).fill({
                    name: 'name',
                    url: 'url',
                    icon: '',
                });*/
      },
    },
    value: {
      type: Number,
      default: 0,
    },
    activityType: {
      type: Number,
      default: 0,
    },
    expandLabelType: {
      type: Boolean,
      default: false,
    },
    affix: {
      type: Boolean,
      default: false,
    },
    distance: {
      type: Number,
      default: 4,
    },
    fulfilGiveId: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    ...mapGetters('goods', {
      catList_: 'catList',
      storeCatMap: 'catMap',
    }),
    storeCatList() {
      const { catList_, activityType, fulfilGiveId } = this;
      if (!catList_(activityType)) {
        this.getCatList({ activityType, fulfilGiveId });
        return null;
      }
      return catList_(activityType);
    },
    catList() {
      const { storeCatList, storeCatMap, model, activityType } = this;
      if (!storeCatList) return null;
      if (!model) return storeCatList;
      const catMap = storeCatMap[activityType];
      if (!catMap) return storeCatList;
      const catItem = catMap[model];
      if (!catItem || !catItem.parentId) return storeCatList;
      const parent = catMap[catItem.parentId];
      if (!parent) return storeCatList;
      return parent.children;
    },
    catListMap() {
      return (this.catList || []).reduce((pre, cur) => {
        pre[cur.id] = cur;
        return pre;
      }, {});
    },
    currentCat() {
      const { model, catListMap } = this;
      return catListMap[model] || {};
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    $$page() {
      let parent = this.$parent || this.$root;
      let name = parent.$options.componentName;

      while (parent && (!name || name !== 'Page')) {
        parent = parent.$parent;

        if (parent) {
          name = parent.$options.componentName;
        }
      }
      return parent;
    },
    $$container() {
      let parent = this.$parent || this.$root;
      let name = parent.$options.componentName;

      while (parent && (!name || name !== 'Container')) {
        parent = parent.$parent;

        if (parent) {
          name = parent.$options.componentName;
        }
      }
      return parent;
    },
    $$affix() {
      const node = document.createElement('div');
      node.classList.add(
        'second-header',
        'cat-picker-second-header',
        'fn-hide',
      );
      this.$$page.$el.appendChild(node);
      return node;
    },
    popupTop() {
      const { top } = this;
      return {
        top,
      };
    },
    exactPath() {
      const { fullPath, query } = this.$route;
      const { VNK } = query || {};
      return fullPath.replace(new RegExp(`(\\?|&)VNK=${VNK}`), '');
    },
    $$nav() {
      return this.$refs.inner;
    },
    $$currItem() {
      // return this.$refs[`navItem${this.index}`];
      return this.$refs['navItem'][this.index];
    },
    initInput() {
      const { model, catList } = this;
      const b = !model && catList && catList.length;
      return b ? catList[0].id : 0;
    },
  },
  watch: {
    index: {
      handler(val) {
        if (!val) return;
        this.keepCenter();
      },
      immediate: true,
    },
    initInput: {
      handler(val) {
        if (!val) return;
        this.model = val;
      },
      immediate: true,
    },
    catList: {
      handler(val) {
        if (val && !val.length) {
          console.log('getCatList', val);
          this.$emit('nothing');
        } else {
          this.$nextTick(() => {
            const { main } = this.$refs;
            this.top = main.offsetTop + main.clientHeight + 'px';
            // this.top = main.offsetTop + 'px';
          });
        }
      },
      immediate: true,
    },
    popupShow(val) {
      if (val) {
        const { nav } = this.$refs;
        this.navClientRect = nav.getBoundingClientRect();
      }
    },
  },
  methods: {
    ...mapActions('goods', ['getCatList']),
    onContainerScroll($event) {
      const { target } = $event;
      return new Promise((/*resolve, reject*/) => {
        if (this.getCurrentDistance(target) <= 0) {
          this.placedOnTop();
        } else {
          this.recoveryPosition();
        }
      });
    },
    /**
     * 获取离顶部区距离
     * @returns {Number} distance
     */
    getCurrentDistance(el) {
      const { main } = this.$refs;
      const top = main.getBoundingClientRect().top;
      const bottom =
        el === window ? window.innerHeight : el.getBoundingClientRect().top;
      return top - bottom - this.distance;
    },
    placedOnTop() {
      if (this.isOnTop) return;
      const { nav } = this.$refs;
      this.$$affix.appendChild(nav);
      this.$nextTick(() => {
        this.navClientRect = nav.getBoundingClientRect();
        this.keepCenter();
      });
      this.isOnTop = true;
      this.$$affix.classList.remove('fn-hide');
    },
    recoveryPosition() {
      if (!this.isOnTop) return;
      this.$$affix.classList.add('fn-hide');
      const { main, nav } = this.$refs;
      main.appendChild(nav);
      this.isOnTop = false;
      this.$nextTick(() => {
        this.keepCenter();
      });
    },
    onExpand() {
      /*if (!this.isOnTop) {
                const { nav } = this.$refs;
                this.$$container.$el.scrollTop = nav.offsetTop;
            }*/
      this.onSwitch();
    },
    hasExact(href, index) {
      const { path, query } = this.$route;
      aNode.href = href;
      const aNodeId = aNode.search.replace(/[?|&]+id=([^?&]*).*/, '$1');
      const aNodePath = aNode.pathname;
      // console.log(aNode, aNodePath, aNodeId, path, query);
      if (aNodePath === path && (aNodeId || '') === (query.id || '')) {
        this.$nextTick(() => {
          this.index = index;
        });
        return true;
      }
      return false;
    },
    keepCenter() {
      if (!this.$$nav || !this.$$currItem) return;
      const navEl = this.$$nav.$el || this.$$nav;
      const currItemEl = this.$$currItem.$el || this.$$currItem;
      const navWidth = navEl.clientWidth;
      const itemWidth = currItemEl.clientWidth;
      const itemOffset = currItemEl.offsetLeft;
      this.$$nav.scrollLeft = itemOffset - (navWidth - itemWidth) / 2;
    },
  },
  mounted() {
    const { nav, inner } = this.$refs;
    this.isShowExpand = inner.scrollWidth > nav.clientWidth;
    this.navClientRect = nav.getBoundingClientRect();
    if (this.affix) {
      // this.onContainerScroll();
      this.$nextTick(() => {
        this.dispatch('Container', 'createScrollListener', [
          this.onContainerScroll,
        ]);
      });
    }
  },
};
</script>

<style lang="scss">
.cat-picker {
  background-color: #fff;
}
.cat-picker-popup {
  > .inner {
    background-color: #fff;
    > .header {
      border: none;
      min-height: initial;
      line-height: 1.2;
      font-size: 110%;
      padding: $padding $padding 0 $padding;
    }
  }
}

.cat-picker-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 80vh;
  > .item {
    padding: $padding-small;
    width: 20%;
    text-align: center;
    > .icon {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 100vw;
      border: 1px solid $color-border;
    }
    &.exact-active {
      /*&.active {*/
      // background-color: mix($color-main, #fff, 20%);
      color: $color-main;
      > .icon {
        border: 1px solid $color-main;
      }
      > .label {
        font-weight: bold;
      }
    }
  }
}

.cat-picker-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  > .inner {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: hidden;
    overflow-x: auto;
    min-height: 0.8rem;

    > .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      padding: 0 $padding;
      border: solid transparent;
      border-width: 1px 0;
      &.exact-active {
        position: relative;
        color: $color-main;
        //border-bottom-color: $color-main;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 1em;
          height: 0.05rem;
          background-color: $color-main;
        }
      }
    }
  }
  > .expand {
    flex: 0 0 auto;
    width: 0.8rem;
    min-height: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: -6px 0 12px -6px $gray4;
  }
}

.popup-wrap {
  .popup.cat-picker-popup {
    justify-content: flex-start;
    align-items: stretch;
    /*top: .8rem;*/
    /*background-color: rgba(255, 255, 255, 0.5);*/
    > .inner {
      max-width: inherit;
      width: 100%;
      border-radius: 0;
      border-top: 1px solid $color-border;
    }
  }
}

.second-header.cat-picker-second-header {
  line-height: inherit;
  border-bottom: 0;
}
</style>
