<!-- Created by henian.xu on 2017/10/31. -->

<template>
  <div class="ad-list">
    <linker
      :to="item.href"
      :class="['item']"
      :style="`margin-bottom: ${margin};`"
      v-for="(item,index) in this.data"
      :key="index"
    >
      <img :src="item.imgUrl | imgCropping">
    </linker>
  </div>
</template>

<script>
export default {
  name: 'AdList',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    margin: {
      type: String,
      default: '0',
    },
    noZoom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ad-list {
  > .item {
    display: block;
    line-height: 0;
    > img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
