<!-- Created by henian.xu on 2018/6/26. -->

<template>
  <div class="team-item">
    <div class="img-obj">
      <div
        class="inner"
        :style="`background-image: url(${data.avatar||$defaultAvatar});`"
      />
    </div>
    <div class="inner">
      <div
        class="header"
        v-if="data.nickName"
      >
        <div class="label">
          {{ data.nickName }}
        </div>
      </div>
      <div class="body">
        <linker
          class="label tc-blue"
          :to="`tel://+${data.countryCode}${data.mobile}`"
        >
          <i class="f-icon">&#xf026;</i>+{{ data.countryCode }}{{ data.mobile }}
        </linker>
        <div class="tc-g6 fs-small">
          &nbsp;<i class="f-icon">&#xf027;</i>&nbsp;<span>{{ data.joinTime|date }}</span>
        </div>
      </div>
      <div class="footer" />
    </div>
    <div class="extra">
      <span
        class="badge badge-second fs-small"
        v-if="data.distributorLevelName"
      >{{ data.distributorLevelName }}</span>
      <span
        class="badge badge-blue fs-small"
        v-else
      >普通会员</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    console.log({ ...this.data });
  },
};
</script>

<style lang="scss">
.team-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: $padding;
  > .img-obj {
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: hidden;
    width: 1rem;
    height: 1rem;
    > .inner {
      width: 1rem;
      height: 1rem;
      background: no-repeat center center;
      background-size: cover;
    }
  }
  > .inner {
    flex: 1 1 1%;
    margin-left: $margin-small;
    > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid $color-border;
      padding: $padding-small 0;

      > .label {
        flex: 1 1 1%;
      }
      > .extra {
        flex: 0 0 auto;
      }
    }
    > .body {
    }
    > .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
