/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import * as Mb from './Mb';

export default {
  Mb,
};
