<!-- Created by henian.xu on 2018/5/8. -->

<template>
  <div
    class="coupon-item"
    v-on="$listeners"
  >
    <div class="body">
      <div class="price-wrap">
        <template v-if="data.couponAmountType==1">
          <div class="price">
            {{ data.couponAmount | price }}
          </div>
        </template>
        <template v-if="data.couponAmountType==2">
          <div class="discount">
            {{ data.couponAmount }}
          </div>
        </template>
        <div class="limit-price">
          {{ data.couponUseAmount>0?`满${data.couponUseAmount}可用`:'无门槛' }}
        </div>
      </div>
      <div class="info">
        <div class="label">
          {{ data.couponDesc }}
        </div>
        <div
          v-if="data.couponAmountType==2"
          class="desc"
        >
          <span v-if="data.discountAmount">当前可减 <span class="price">{{ data.discountAmount }}</span>&nbsp;&nbsp;</span>
          <span v-if="data.discountMaxAmount">最多可减 <span class="price">{{ data.discountMaxAmount }}</span></span>
        </div>
        <div class="desc">
          {{ data.expireTime|date }} 到期
        </div>
      </div>
      <div
        v-if="!isSelect"
        class="ctrl"
      >
        <div
          v-if="data.couponType===1"
          class="btn btn-red radius all"
          @click="onUse"
        >
          立即使用
        </div>
        <i
          v-if="data.couponType===2"
          class="status-icon f-icon tc-second"
        >&#xf02d;</i>
        <i
          v-if="data.couponType===3"
          class="status-icon f-icon tc-g5"
        >&#xf02e;</i>
      </div>
      <div
        v-else-if="data.isAvailable"
        class="ctrl"
      >
        <checkbox
          type="radio"
          :val="data.id"
          :stop-propagation="false"
          v-model="checked"
        />
      </div>
    </div>
    <div
      v-if="!(isSelect && !data.isAvailable) && data.couponUseScopeType === 2"
      class="footer"
    >
      <div class="tc-g6">
        仅限指定商品可用
      </div>
    </div>
    <div
      class="footer"
      v-if="isSelect && !data.isAvailable"
    >
      <div class="tc-red">
        <i class="f-icon">&#xf041;</i> 暂不可用
      </div>
      <div class="tc-g6">
        {{ data.limitDesc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponItem',
  data() {
    return {};
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    data: {
      type: Object,
      required: true,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', +val);
      },
    },
  },
  methods: {
    onUse() {
      const { isSelect, data } = this;
      const { couponUseScope } = data;
      if (isSelect) return;
      if (couponUseScope)
        this.$router.push(`/goods/list/0?ids=${couponUseScope}&footerShow=0`);
      else this.$router.push('/goods/list/0');
    },
  },
};
</script>

<style lang="scss">
.coupon-item {
  background-color: #fff;
  border: 1px solid $color-border;
  overflow: hidden;
  border-radius: 0.1rem;
  margin-top: $margin;
  &:first-child {
    margin-top: 0;
  }
  > .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    > .price-wrap {
      flex: 0 0 auto;
      white-space: nowrap;
      text-align: center;
      padding: 0 $padding;
      > .price,
      > .discount {
        color: $color-red;
        font-size: 0.48rem;
        font-weight: bold;
        line-height: 1.2;
      }
      > .discount {
        &:after {
          content: '折';
          font-size: 60%;
        }
      }
      > .limit-price {
        color: $gray6;
        font-size: 0.26rem;
      }
    }
    > .info {
      flex: 1 1 auto;
      padding: 0 $padding;
      > .label {
        font-weight: bold;
      }
      > .desc {
        color: $gray6;
        font-size: 0.26rem;
      }
    }
    > .ctrl {
      flex: 0 0 auto;
      > .status-icon {
        font-size: 1.2rem;
        line-height: 1;
      }
    }
  }
  > .footer {
    font-size: 0.26rem;
    border-top: 1px dashed $color-border;
    padding: $padding;
    background-color: $gray1;
  }
}
</style>
