<!-- Created by henian.xu on 2018/8/26. -->

<template>
  <div class="extends">
    <Label v-bind="__labelProps"><slot /></Label>
  </div>
</template>

<script>
import labelMixin from './labelMixin';
export default {
  name: 'Extends',
  mixins: [labelMixin],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
