/**
 * Created by henian.xu on 2018/2/5.
 * 地址管理接口
 */

import { Rs } from '@/api/comm/Rs/Rs';

class Menu extends Rs {
  constructor() {
    super();
    this.baseUrl += '/menu';
  }

  /**
   * 获取权限列表
   * @returns {AxiosPromise<any>}
   */
  getAppMenuResource() {
    return super.get(`/getAppMenuResource`);
  }
}

export default new Menu();
export { Menu };
