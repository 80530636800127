<!-- Created by henian.xu on 2019/1/4. -->

<template>
  <div class="order-edit-address">
    <div
      class="out-show"
      @click.prevent.stop="onSwitch"
    >
      <slot>
        <div class="btn btn-g6 link bor radius all">
          修改地址
        </div>
      </slot>
    </div>

    <transition
      v-if="!delayedCreate"
      :name="aniName"
    >
      <Page
        ref="popup"
        v-show="popupShow"
      >
        <Container>
          <XForm
            ref="form"
            :rules="formRules"
            :model="formModel"
          >
            <FormItem
              prop="deliveryConsignee"
              label="收货人："
            >
              <x-input
                type="text"
                v-model="formModel.deliveryConsignee"
                placeholder="请输入收货人"
                :disabled="data.goodsSendMode!==1"
              />
            </FormItem>
            <FormItem
              prop="deliveryPhone"
              label="手机号："
            >
              <x-input
                type="text"
                v-model="formModel.deliveryPhone"
                placeholder="请输入手机"
              />
            </FormItem>
            <FormItem
              prop="deliveryRegionId"
              label="收货地址："
            >
              <region-picker
                v-model="formModel.deliveryRegionId"
              />
            </FormItem>
            <FormItem
              prop="deliveryAddress"
              label="详细地址："
            >
              <x-input
                type="textarea"
                :auto-size="{minRows:2,maxRows:4}"
                v-model="formModel.deliveryAddress"
                placeholder="如街道，楼层，门牌号等"
              />
            </FormItem>
            <FormItem
              prop="deliveryPostcode"
              label="邮政编码："
            >
              <x-input
                type="text"
                v-model="formModel.deliveryPostcode"
                placeholder="请输入邮政编码"
              />
            </FormItem>
          </XForm>
        </Container>
        <PageBar>
          <Button
            theme="main"
            label="提交"
            @click="onSubmit"
          />
        </PageBar>
      </Page>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
export default {
  name: 'OrderEditAddress',
  mixins: [popupMixin],
  data() {
    return {
      delayedCreate: true,
      formModel: {
        orderId: '',
        deliveryConsignee: '',
        deliveryPhone: '',
        deliveryRegionId: '',
        deliveryAddress: '',
        deliveryPostcode: '',
      },
      formRules: {
        deliveryConsignee: {
          required: true,
          message: '收货人不能为空',
        },
        deliveryPhone: [
          {
            required: true,
            message: '电话不能为空',
          },
          {
            validator: this.$utils.Valid.mobile,
            message: '请输入正确的手机号码',
          },
        ],
        deliveryRegionId: {
          required: true,
          message: '收货地址不能为空',
        },
        deliveryAddress: {
          required: true,
          message: '邮政编码不能为空',
        },
      },
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        const { formModel } = this;
        Object.keys(formModel).forEach(key => {
          formModel[key] = val[key] || formModel[key];
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate().then(() => {
        const data = {
          ...this.formModel,
          orderId: this.data.id,
        };
        this.$api.Buyer.Ex.Order.editOrderDelivery(data).then(json => {
          console.log(json.data);
          const res = json.data.data;
          const { data } = this;
          Object.keys(data).forEach(key => {
            data[key] = res[key] || data[key];
          });
          this.done();
        });
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.order-edit-address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  .out-show {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .btn {
      width: 100%;
    }
  }
}
</style>
