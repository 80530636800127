/**
 * Created by henian.xu on 2017/10/24.
 *
 */

import { Mb } from '@/api/buyer/Mb/Mb';

class GiftCard extends Mb {
  constructor() {
    super();
    this.baseUrl += '/giftCard';
  }

  giftCardList({ isAllUse, currentPage, pageSize } = {}) {
    return super.get(`/giftCardList`, {
      isAllUse,
      currentPage,
      pageSize,
    });
  }
  bind({ cardSn } = {}) {
    return super.post(`/bind`, {
      cardSn,
      _isHandleError: true,
    });
  }
  cancelBind({ id } = {}) {
    return super.post(`/bind`, {
      id,
    });
  }
}

export default new GiftCard();
export { GiftCard };
