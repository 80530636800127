/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Mb } from '@/api/buyer/Mb/Mb';

class BuyerOpenStoreOrder extends Mb {
  constructor() {
    super();
    this.baseUrl += '/buyerOpenStoreOrder';
  }

  getOpenStoreInfo() {
    return super.get(`/getOpenStoreInfo`);
  }
  createBuyerOpenStoreOrder() {
    return super.post(`/createBuyerOpenStoreOrder`);
  }
}

export default new BuyerOpenStoreOrder();
