<!-- Created by henian.xu on 2019/5/15. -->

<template>
  <div class="proxy-store-goods-picker">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <slot>
        <ProxyStoreEditGoodsItem
          v-if="currentGoods"
          :data="currentGoods.goods"
        />
        <template v-else>
          <div>
                        <!--<Icon
                            code="&#xf015;"
                            class="tc-blue"
                        /> -->重新选择商品
          </div>
        </template>
      </slot>
      <!--{{ model }}-->
    </div>

    <transition :name="aniName">
      <Page
        class="bc-g2"
        ref="popup"
        v-show="popupShow"
      >
        <ProxyStoreGoodsCatPicker
          class="second-header"
          :data="catList"
          v-model="catId"
          @input="refreshData($event)"
        />
        <query-box
          class="second-header"
          top=".89rem"
          placeholder="请输入商品名称"
          show-search-btn
          v-model="goodsName"
          @query="refreshData($event,true)"
        />
        <Container>
          <List>
            <ProxyStoreEditGoodsItem
              class="ma-b"
              v-for="item in goodsList"
              :key="item.id"
              :data="item"
              @click="onItem(item)"
              v-model="model"
            />
          </List>
          <infinite-loading
            ref="infinite"
            :disabled="infiniteDisabled"
            @infinite="onInfinite"
          />
        </Container>
        <PageBar v-if="model.length">
          <Button
            label="选好了"
            theme="main"
            @click="onSelected"
          />
        </PageBar>
      </Page>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import popupMixin from '@/mixins/popup';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'ProxyStoreGoodsPicker',
  mixins: [popupMixin, infiniteLoading],
  data() {
    return {
      goodsName: '',
      appLevel: true,
      model_: [], // 当未绑定 model 时才有用
      currentGoods: null,
      infiniteDisabled: true,
      // catList: [],
      catId: 0,
      goodsList: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('goods', { catList_: 'catList' }),
    model: {
      get() {
        const { value, model_ } = this;
        return value === void 0 ? model_ : value;
      },
      set(val) {
        this.value === void 0 ? (this.model_ = val) : this.$emit('input', val);
      },
    },
    catList() {
      const { catList_, activityType = 0 } = this;
      if (!catList_(activityType)) {
        this.getCatList({ activityType });
        return null;
      }
      return catList_(activityType);
    },
  },
  watch: {
    popupShow(val) {
      if (val) {
        if (this.catId) this.refreshData();
      } else {
        this.infiniteDisabled = true;
      }
    },
  },
  methods: {
    ...mapActions('goods', ['getCatList']),
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreGoods.agentGoodsList({
        isAgent: 0,
        goodsName: this.goodsName,
        shopGoodsCatId: this.catId,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.goodsList = this.goodsList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onItem(item) {
      // this.model = item.id;
      const { model } = this;
      const index = model.indexOf(item.id);
      if (index === -1) {
        this.model = [...model, item.id];
      } else {
        const data = [...model];
        data.splice(index, 1);
        this.model = data;
      }
    },
    refreshData($event, search = false) {
      !search && (this.goodsName = '');
      this.infiniteDisabled = true;
      this.pagination = {};
      this.goodsList = [];
      this.$nextTick(() => (this.infiniteDisabled = false));
    },
    onSelected() {
      this.$emit('update', this.model);
      this.close();
    },
  },
};
</script>

<style lang="scss">
.proxy-store-goods-picker {
  flex: 1 1 1%;
  background-color: #fff;
  > .out-show {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    > .icon {
      margin-right: $margin-small;
    }
    > .button {
      flex: 1 1 1%;
    }
  }
}
</style>
