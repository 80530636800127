<!-- Created by henian.xu on 2019/5/16. -->

<template>
  <div class="proxy-store-goods-style-picker">
    <div
      class="item"
      v-for="item in data"
      :key="item.id"
    >
      <div class="header">
        <Checkbox
          type="radio"
          :val="item.id+''"
          v-model="model"
        >
          {{ item.styleName }}
        </Checkbox>
        <!--<div class="label">{{ item.styleName }}</div>-->
      </div>
      <div class="body">
        <div class="badge badge-g2 tc-g6 nma-t">
          标题
        </div>
        <div class="label">
          {{ item.goodsName }}
        </div>
        <div class="badge badge-g2 tc-g6">
          副标题
        </div>
        <div class="label">
          {{ item.goodsSubtitle }}
        </div>
        <div class="badge badge-g2 tc-g6">
          列表图
        </div>
        <ImgList
          :max-show-quantity="4"
          :data="item.goodsImageList"
        />
        <div class="badge badge-g2 tc-g6">
          详情图
        </div>
        <ImgList
          :max-show-quantity="4"
          :data="item.goodsDetailImageList"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProxyStoreGoodsStylePicker',
  data() {
    return {};
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value + '';
      },
      set(val) {
        this.$emit('input', val + '');
      },
    },
  },
  watch: {
    data: {
      handler(val) {
        const { model } = this;
        if (model || !val || !val.length) return;
        this.model = val[0].id;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.proxy-store-goods-style-picker {
  flex: 1 1 1%;
  > .item {
    + .item {
      border-top: 1px solid $color-border;
    }
    > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $padding 0;
      > .checkbox {
        flex: 0 0 auto;
      }
      > .label {
        flex: 1 1 1%;
      }
    }
    > .body {
      padding-bottom: $padding;
      > .label {
        line-height: 1;
      }
      > .badge {
        margin-top: $margin;
      }
    }
  }
}
</style>
