/**
 * Created by henian.xu on 2018/3/30.
 *
 */

import GlobalVar from 'globalVar';

if (GlobalVar.isVConsole) {
  import(/* webpackChunkName: "vconsole" */ 'vconsole').then(
    ({ default: vConsole }) => {
      GlobalVar.vConsole = new vConsole({
        defaultPlugins: ['system', 'network', 'element', 'storage'], // 可以在此设定要默认加载的面板
        maxLogNumber: 1000,
        // disableLogScrolling: true,
        onReady: function() {
          console.log('vConsole is ready.');
        },
        onClearLog: function() {
          console.log('on clearLog');
        },
      });
    },
  );
}
const { countryData, theme } = GlobalVar;
const doc = window.document;
const $head = doc.head;
let cssText = '';

if (theme) {
  const { color } = theme;
  if (color) {
    console.log(color);
    const colorVar = Object.keys(color)
      .reduce((pre, curr) => {
        pre.push(`--color-${curr}:${color[curr] || ''};`);
        return pre;
      }, [])
      .join('');
    cssText += `:root{${colorVar}}`;
  }
}
console.log(cssText);
if (countryData.currency) {
  cssText += `.price:before{content:'${countryData.currency}'!important;}`;
}
const style = doc.createElement('style');
style.innerText = cssText;
$head.appendChild(style);
