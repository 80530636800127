<!-- Created by henian.xu on 2019/5/14. -->

<template>
  <div class="category-tab">
    <CategoryTabNav :panels="panels" />
    <div class="content">
      <div
        ref="float"
        class="category-tab-panel-header float"
        :style="floatStyle"
      />
      <div
        class="scroll"
        ref="scroll"
        @scroll="onScroll"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryTabNav from './categoryTabNav';
import Animate from '@/utils/animate';
export default {
  name: 'CategoryTab',
  components: { CategoryTabNav },
  provide() {
    return { Tab: this };
  },
  data() {
    return {
      isMounted: false,
      onScrolling: false,
      scrollTop: 0,
      floatHeight: 0,
      panels: [],
      currentIndex_: 0, // 当未绑定 model 时才有用
      scrollTopAnimate: new Animate(
        this.onScrollTopAniStep,
        this.onScrollTopAniCompleted,
        this.onScrollTopAniCompleted,
      ),
      isAnimate: false,
    };
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    currentIndex: {
      get() {
        const { value, currentIndex_ } = this;
        return value === null ? currentIndex_ : value;
      },
      set(val) {
        this.value === null
          ? (this.currentIndex_ = val)
          : this.$emit('input', val);
        this.$emit('change', this.panels[val], val);
      },
    },
    defaultPanels() {
      return this.$slots.default.filter(VNode =>
        /^vue-component(.*)TabPanel$/.test(VNode.tag),
      );
    },
    currentPanel() {
      const { currentIndex, panels } = this;
      if (!panels || !panels.length) return null;
      return panels[currentIndex];
    },
    floatStyle() {
      const { currentPanel, floatHeight } = this;
      if (!currentPanel) return {};
      const { currentTop, currentBottom } = currentPanel;
      const top = currentTop + currentBottom;
      if (top > floatHeight) return {};
      return {
        top: `${top - floatHeight + 1}px`,
      };
    },
    $$float() {
      const { $refs, isMounted } = this;
      const { float } = $refs;
      return !isMounted ? null : float || null;
    },
    $$scroll() {
      const { $refs, isMounted } = this;
      const { scroll } = $refs;
      return !isMounted ? null : scroll || null;
    },
  },
  watch: {
    currentIndex: {
      handler() {
        this.checkPosition();
      },
      immediate: true,
    },
  },
  methods: {
    addPanel(panel) {
      const { panels, defaultPanels } = this;
      const index = defaultPanels.indexOf(panel.$vnode);
      if (index === -1) return;
      panels.splice(index, 0, panel);
    },
    floatAppendChild(el) {
      const { $$float } = this;
      $$float.appendChild(el);
      this.$nextTick(() => {
        this.floatHeight = $$float.clientHeight;
      });
    },
    onScroll() {
      if (this.onScrolling) return;
      this.onScrolling = true;
      window.requestAnimationFrame(() => {
        this.onScrolling = false;
        this.scrollTop = this.$$scroll.scrollTop;
      });
    },
    checkPosition() {
      const { currentPanel, scrollTopAnimate, scrollTop } = this;
      if (!currentPanel) {
        this.$nextTick(() => this.checkPosition());
        return;
      }
      if (currentPanel.inRange) return;
      // $$scroll.scrollTop = currentPanel.$el.offsetTop;
      scrollTopAnimate.stop();
      scrollTopAnimate.start(scrollTop, currentPanel.$el.offsetTop, 1000);
    },
    onScrollTopAniStep(val) {
      this.isAnimate = true;
      this.$$scroll.scrollTop = val;
    },
    onScrollTopAniCompleted() {
      this.isAnimate = false;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
.category-tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  > .content {
    position: relative;
    height: 100%;
    flex: 1 1 1%;
    background-color: #fff;
    .category-tab-panel-header {
      > .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: $formItemHeight + $padding-small * 2;
        padding: 0 $padding-small;
        background-color: rgba(246, 249, 252, 0.9);
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        > .label {
        }
      }
      &.float {
        position: absolute;
        z-index: $z-index-2;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    > .scroll {
      position: relative;
      z-index: $z-index-1;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-scrolling: touch;
    }
  }
}
</style>
