<!-- Created by henian.xu on 2018/6/24. -->

<template>
  <div class="panel">
    <slot name="header">
      <div
        class="header"
        v-bind="linkerProps"
      >
        <slot name="label">
          <div
            class="label"
            v-html="label"
          />
        </slot>
        <slot name="extra">
          <div
            class="extra"
            v-html="extra"
          />
        </slot>
      </div>
    </slot>
    <slot name="body">
      <div class="body">
        <slot />
      </div>
    </slot>
  </div>
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'Panel',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    extra: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.panel {
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    line-height: 0.6rem;
    padding: $padding;
    border-bottom: 1px solid $color-border;

    > .label {
      flex: 1 1 1%;
      font-weight: bold;
      color: $color-main;
    }
    > .extra {
      color: $gray6;
    }
  }
  > .body {
    padding: $padding;
  }
}
</style>
