/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreOrder extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStoreOrder';
  }

  /**
   * 活动订单列表
   * @returns {AxiosPromise<any>}
   */
  orderList({
    searchKeyword,
    orderSn,
    buyerMobile,
    deliveryConsignee,
    deliveryPhone,
    storeActivityId,
    shipMethod,
    orderStatus,
    orderSnScan,
    pickupTel,
    isStoreAgentSell,
    currentPage,
    pageSize,
  }) {
    return super.get(`/orderList`, {
      searchKeyword,
      orderSn, //订单号
      buyerMobile, //下单人电话
      deliveryConsignee, //收货人姓名
      deliveryPhone, //收货人电话
      storeActivityId,
      shipMethod,
      orderStatus,
      orderSnScan, // 扫描消单用
      pickupTel, // 手机号查找
      isStoreAgentSell, // 是否代理订单
      currentPage,
      pageSize,
    });
  }
  /**
   * 订单发货
   * @param orderId
   * @param expressName
   * @param trackingNumber
   * @returns {*}
   */
  shippingOrder({ orderId, expressName, trackingNumber } = {}) {
    return super.post(`/shippingOrder`, {
      id: orderId,
      expressName,
      trackingNumber,
    });
  }

  /**
   * 确认收货
   * @param orderId
   * @returns {Promise<any>}
   */
  completeOrder({ orderId } = {}) {
    return super.post(`/completeOrder`, {
      orderId,
    });
  }

  /**
   * 取消订单
   * @param orderId
   * @param cancelDesc
   * @returns {*}
   */
  cancelOrder({ orderId, cancelDesc } = {}) {
    return super.post(`/cancelOrder`, {
      id: orderId,
      cancelDesc,
    });
  }

  /**
   * 商品统计列表
   * @param storeActivityId
   * @param orderStatus
   * @param shipMethod
   * @returns {*}
   */
  goodsStatList({ storeActivityId, orderStatus, shipMethod } = {}) {
    return super.get(`/goodsStatList`, {
      storeActivityId,
      orderStatus,
      shipMethod,
    });
  }

  /**
   * 修改订单
   * @param id                    orderId
   * @param orderComments         备注
   * @param orderDetailJson       价格 [{id:1,price:100}]
   * @returns {*}
   */
  editOrder({ id, orderComments, orderDetailJson } = {}) {
    return super.post(`/editOrder`, {
      id,
      orderComments,
      orderDetailJson,
    });
  }

  exportGoodsStatExcel({ storeActivityId, orderStatus, shipMethod } = {}) {
    return super.download('/exportGoodsStatExcel', {
      storeActivityId,
      orderStatus,
      shipMethod,
    });
  }

  exportOrderExcel({ storeActivityId, orderStatus, shipMethod } = {}) {
    return super.download('/exportOrderExcel', {
      storeActivityId,
      orderStatus,
      shipMethod,
    });
  }

  getOrderCountInfo() {
    return super.get(`/getOrderCountInfo`);
  }
}

export default new ShopStoreOrder();
