let passiveSupported = false;

try {
  const options = Object.defineProperty({}, 'passive', {
    get: function() {
      return (passiveSupported = true);
    },
  });
  window.addEventListener('test', null, options);
} catch (err) {
  // continue regardless of error
}

module.exports = passiveSupported;
