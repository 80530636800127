/**
 * Created by henian.xu on 2018/4/27.
 *
 */

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
