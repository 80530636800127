<template>
  <span class="vux-label-desc"><slot /></span>
</template>

<script>
export default {
  name: 'InlineDesc',
};
</script>

<style>
.vux-label-desc {
  font-size: 14px;
  color: #666;
}
</style>
