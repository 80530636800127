/**
 * Created by henian.xu on 2017/10/24.
 *
 */

import Vue from 'vue';
import './directive'; // 自定义指令
import './filter'; // 过滤器
import './weixin'; // 微信功能
import Nprogress from 'nprogress';
import moment from 'moment';
import * as Api from '@/api';
import { HttpInstance } from '@/api/base/HttpBase';
import * as Utils from '@/utils';
import * as GlobalVar from 'globalVar';
import DefaultImg from '@/assets/images/comm/default.jpg';
import DefaultAvatar from '@/assets/images/comm/defaultAvatar.png';
import loadingIcon from '@/assets/images/comm/loading.gif';
// import loadingImg from '@/assets/images/loading/loading.gif';
const loadingImg = GlobalVar.moduleConfig.loadingImg || loadingIcon;

Nprogress.configure({
  minimum: 0.5, // 通过 minimum 来修改最小百分比。
  // template: `<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"><div class="heart"></div><div class="money01"></div><div class="money02"></div><div class="shadow"></div></div></div>`, // 你可以通过 template 修改标记结构。为了保证进度条正常工作，需要一个包含 role='bar' 属性的元素。
  template: `<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"><img class="gif" src="${loadingImg}"></div></div>`, // 你可以通过 template 修改标记结构。为了保证进度条正常工作，需要一个包含 role='bar' 属性的元素。
  ease: 'ease', // 通过 ease(一个 CSS 中的 easing 值) 调整动画设置和速度 speed （毫秒ms）。
  speed: 500,
  trickle: true, // 想关闭进度条步进？设置 trickle 为 false。
  trickleRate: 0.2, // 你可以调整 trickleRate (每次步进增长多少) 和 trickleSpeed (步进间隔，单位毫秒ms).
  trickleSpeed: 800,
  showSpinner: true, // 想禁用进度环？设置 showSpinner 为 false。
});
Nprogress.timeId = 0;

moment.locale('zh-cn');

Vue.prototype.$nprogress = Nprogress;
Vue.prototype.$defaultImg = DefaultImg;
Vue.prototype.$defaultAvatar = DefaultAvatar;
Vue.prototype.$loadingIcon = loadingIcon;
Vue.prototype.$bus = new Vue();
Vue.prototype.$api = Api;
Vue.prototype.$http = HttpInstance;
Vue.prototype.$globalVar = GlobalVar;
Vue.prototype.$utils = Utils;
Vue.prototype.$moment = moment;
