/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Mb } from '@/api/buyer/Mb/Mb';

class CardInfo extends Mb {
  constructor() {
    super();
    this.baseUrl += '/cardInfo';
  }

  cardInfoDetail({ cardInfoId } = {}) {
    return super.get(`/cardInfoDetail`, { cardInfoId });
  }
}

export default new CardInfo();
