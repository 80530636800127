<!-- Created by henian.xu on 2019/3/5. -->

<template>
  <div
    class="store-activity-item"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <div class="img-obj">
      <div
        class="inner"
        :style="`background-image: url('${data.activityCoverUrl||$defaultImg}');`"
      />
    </div>
    <div class="inner">
      <div class="body">
        <div class="label">
          {{ data.activityName }}
        </div>
        <div class="item fs-small tc-g6">
          {{ data.activityBeginTime | moment('YYYY-MM-DD HH:mm') }} - {{ data.activityEndTime | moment('YYYY-MM-DD HH:mm') }}
        </div>
      </div>
      <div class="footer">
        <span :class="['badge fs-small',data.activityStatus===1?'badge-second':'badge-g5']">{{ data.activityStatusName }}</span>
        <div class="ctrl">
          <Button
            :to="`./managementActivity/${data.id}`"
            theme="main"
            radius
            size="small"
            icon="&#xf007;"
            label="管理"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'StoreActivityItem',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.store-activity-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  background-color: #fff;
  padding: $padding;
  + .store-goods-item {
    margin-top: $margin;
  }
  > .img-obj {
    flex: 0 0 auto;
    width: 1.4rem;
    margin-right: $margin;
    > .inner {
      width: 100%;
      padding-top: 100%;
      background: transparent center no-repeat;
      background-size: cover;
    }
  }
  > .inner {
    flex: 1 1 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    > .body {
      flex: 1 1 1%;
    }
    > .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
