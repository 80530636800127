/**
 * Created by henian.xu on 2018/8/8.
 * 门店统一混合器
 */

import { createNamespacedHelpers } from 'vuex';

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers('store');

export default {
  data() {
    return {
      haha: 'haha',
    };
  },
  computed: {
    ...mapState(['storeId', 'storeInfo', 'goodsCatList', 'carts']),
    ...mapGetters(['cartMap', 'cartGoodsMap', 'totalQuantity', 'totalAmount']),
  },
  methods: {
    ...mapMutations(['clearCarts']),
    ...mapActions([
      'getGoodsCatList',
      'getShopStoreInfo',
      'storeRemoveCart',
      'storeAddCart',
    ]),
  },
};
