/**
 * Created by henian.xu on 2018/6/20.
 *
 */

import dll from './dll.png';

import dos from './dos.png';
import ppt from './ppt.png';
import xls from './xls.png';

import pdf from './pdf.png';
import psd from './psd.png';

import gif from './gif.png';
import jpg from './jpg.png';
import png from './png.png';

import mp3 from './mp3.png';
import mp4 from './mp4.png';
import wav from './wav.png';
import mkv from './mkv.png';
import rmvb from './rmvb.png';

import swf from './swf.png';

import other from './other.png';

export {
  dll,
  dos,
  ppt,
  xls,
  pdf,
  psd,
  gif,
  jpg,
  png,
  mp3,
  mp4,
  wav,
  mkv,
  rmvb,
  swf,
  other,
};
