/**
 * Created by henian.xu on 2019/2/28.
 * commonMixin.js
 */

export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 有些参数(prop)是在其它混合器或组件中
    pointer() {
      const { to, $listeners, link } = this;
      return link || !!to || !!$listeners.click;
    },
    __iconProps() {
      const { theme, iconTheme, subLabel, icon, iconSize, disabled } = this;
      return {
        content: icon,
        theme: iconTheme || theme,
        size: iconSize || (subLabel ? 'big' : ''),
        disabled,
      };
    },
  },
};
