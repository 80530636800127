<!-- Created by henian.xu on 2020/1/22. -->

<template>
  <div class="x-video">
    <div
      class="inner"
      :style="innerStyles"
    >
      <div class="img-obj">
        <XImage v-bind="xImageProps" />
        <div class="mask">
          <Badge
            v-if="isTask"
            class="main"
            size="small"
          >
            任务
          </Badge>
          <XIcon
            content="f077"
            size="super"
            @click="play"
          />
          <template v-if="isLive">
            <div class="label">
              (直播中)
            </div>
            <div class="content">
              <XIcon content="f043" /> 126
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="!disabled"
        v-show="playerStatus"
        :id="playerId"
        class="player"
      />
    </div>
  </div>
</template>

<script>
import { Comm } from 'utils/index';
// import Url from 'url';
import { loadAliplayer } from './loadAliplayer';

let aliplayer = null;
// 全局当前播放器
// const GlobalPlayer = null;
export default {
  name: 'XVideo',
  data() {
    return {
      isMounted: false,
      player: null,
      playerStatus: '',
      playerId: Comm.getUniqueId('player-'),
    };
  },
  props: {
    isTask: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },

    // XImage props
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: undefined,
    },
    // 确定图片如何适应容器框，同原生 object-fit
    fit: {
      type: String,
      default: 'cover',
      validator(val) {
        return /^(fill|contain|cover|none|scale-down)$/.test(val);
      },
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    // 圆角
    radius: {
      type: [Boolean, String],
      default: false,
      validator(val) {
        return !(typeof val === 'string' && val !== 'all');
      },
    },
    // 值必须是大于 0 的数值, 设置此值后组件的 display 为 block
    aspectRatio: {
      type: [Number, String],
      default: 0,
      /*validator(val) {
                return +val >= 0;
            },*/
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /* ---- aliplayer 属性 ----*/
    // 封面
    cover: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    borderRadius: {
      type: String,
      default: '',
    },
  },
  computed: {
    aspectRatio_() {
      const { aspectRatio } = this;
      if (typeof aspectRatio === 'string' && !+aspectRatio) {
        const [w, h] = aspectRatio.split(':');
        if (!+w || !+h) return 0;
        return +h / +w;
      }
      return +aspectRatio;
    },
    xImageProps() {
      const {
        cover,
        alt,
        fit,
        width,
        height,
        radius,
        aspectRatio_: aspectRatio,
      } = this;
      // console.log(width, height, aspectRatio);
      return {
        src: cover,
        alt,
        fit,
        width,
        height,
        radius,
        aspectRatio: aspectRatio || 9 / 16,
      };
    },
    aliplayerOptions() {
      const {
        options,
        playerId,
        autoplay,
        cover,
        src,
        // add
      } = this;
      const source = src;
      // const { path } = Url.parse(source);
      // source = `/devVideo${path}`;
      return {
        ...options,
        id: playerId,
        autoplay,
        width: '100%',
        height: '100%',
        cover,
        source,
        // source: '//player.alicdn.com/video/aliyunmedia.mp4',

        skinLayout: [
          { name: 'bigPlayButton', align: 'cc' },
          {
            name: 'H5Loading',
            align: 'cc',
          },
          { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
          { name: 'infoDisplay' },
          { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
          { name: 'thumbnail' },
          {
            name: 'controlBar',
            align: 'blabs',
            x: 0,
            y: 0,
            children: [
              { name: 'progress', align: 'blabs', x: 0, y: 44 },
              { name: 'playButton', align: 'tl', x: 15, y: 12 },
              { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
              { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
              // { name: 'subtitle', align: 'tr', x: 15, y: 12 },
              // { name: 'setting', align: 'tr', x: 15, y: 12 },
              { name: 'volume', align: 'tr', x: 5, y: 10 },
            ],
          },
        ],
      };
    },
    isPlaying() {
      return this.playerStatus === 'play';
    },
    srcChanged() {
      const { src, isMounted, disabled } = this;
      if (!isMounted || disabled) return false;
      return src;
    },
    // 当前播放时间
    currentTime: {
      get() {
        const { player } = this;
        return player ? player.getCurrentTime() : 0;
      },
      set(value) {
        if (this.player) {
          this.player.seek(value);
        }
      },
      cache: false,
    },
    innerStyles() {
      const { borderRadius } = this;
      if (!borderRadius) return {};
      return {
        borderRadius: borderRadius,
        overflow: 'hidden',
      };
    },
  },
  watch: {
    srcChanged: {
      async handler(val) {
        if (!val) return;
        if (!aliplayer) {
          aliplayer = await loadAliplayer();
        }
        this.$nextTick(() => {
          this.initPlayer(aliplayer);
        });
      },
      immediate: true,
    },
  },
  methods: {
    emitEvent(event) {
      const { type } = event;
      if (!type) return;
      const handler = `onPlayer${type.replace(/\b(\w)|\s(\w)/g, m =>
        m.toUpperCase(),
      )}`;
      if (this[handler]) this[handler](event);
      const { player } = this;
      this.$nextTick(() => {
        if (player) this.playerStatus = player.getStatus();
      });
      event.rawTarget = event.target;
      event.player = player;
      event.target = this;
      this.$emit(type, event);
    },
    initPlayer(Aliplayer) {
      const { aliplayerOptions } = this;
      this.player = new Aliplayer(aliplayerOptions, (/* player */) => {
        // noop;
      });

      const { player } = this;
      player.on('ready', this.emitEvent);
      player.on('play', this.emitEvent);
      player.on('pause', this.emitEvent);
      player.on('ended', this.emitEvent);
    },
    onPlayerEnded() {
      // 播放完毕进度归零
      this.currentTime = 0;
    },
    onPlayerPlay() {
      // const { player } = this;
      /* if (!GlobalPlayer || GlobalVar !== this) {
                if (GlobalPlayer) GlobalPlayer.pause();
                GlobalPlayer = this;
            } */
      console.log('onPlayerPlay');
    },
    play() {
      const { player } = this;
      if (!player) return;
      player.play();
    },
    pause() {
      const { player } = this;
      if (!player) return;
      player.pause();
    },
  },
  async mounted() {
    this.isMounted = true;
  },
  beforeDestroy() {
    this.pause();
    this.player = null;
  },
};
</script>

<style lang="scss">
.x-video {
  > .inner {
    position: relative;
    display: block;
    > .img-obj {
      position: relative;
      > .mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .badge {
          position: absolute;
          top: $padding;
          left: $padding;
        }
        > .x-icon {
          color: #fff;
        }
        > .label {
          margin-top: $margin;
          color: $color-main;
        }
        > .content {
          margin-top: $margin;
          color: #fff;
        }
      }
    }
    > .player {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
