<!-- Created by henian.xu on 2019/2/25. -->

<template>
  <div
    :class="classes"
    :style="`color: ${rateMap[model].color};`"
  >
    <div
      class="item f-icon"
      v-for="(item,index) in rateStr_"
      :key="index"
      @click="onItem(item,index)"
    >
      {{ item }}
    </div>
    <div
      v-if="!hideLabel"
      class="label"
    >
      {{ rateMap[model].label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rater',
  data() {
    return {
      rateStr: '\uf03b\uf03b\uf03b\uf03b\uf03b\uf03a\uf03a\uf03a\uf03a\uf03a',
      rateMap: {
        1: {
          label: '非常差',
          color: '#ff4600',
        },
        2: {
          label: '差',
          color: '#ff4600',
        },
        3: {
          label: '一般',
          color: '#ff4600',
        },
        4: {
          label: '满意',
          color: '#ff4600',
        },
        5: {
          label: '超赞',
          color: '#ff4600',
        },
      },
    };
  },
  props: {
    value: {
      type: Number,
      default: 5,
      validator(val) {
        return val >= 0 && val <= 5;
      },
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        if (val < 0) {
          val = 0;
        } else if (val > 5) {
          val = 5;
        }
        this.$emit('input', val);
      },
    },
    classes() {
      const { singleLine, disabled } = this;
      return ['rater', 'f-icon', { 'single-line': singleLine }, { disabled }];
    },
    rateStr_() {
      const { rateStr, model } = this;
      return rateStr.slice(5 - model, 10 - model);
    },
  },
  methods: {
    onItem(item, index) {
      if (this.readonly || this.disabled) return;
      index++;
      this.model = index;
      console.log(item === '\uf03b', index);
    },
  },
};
</script>

<style lang="scss">
.rater {
  text-align: center;
  line-height: 1.2;
  > .item {
    + .item {
      margin-left: ($margin-small/2);
    }
  }
  &.single-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .label {
      margin-left: $margin-small;
    }
  }
  &.disabled {
    color: $gray6 !important;
  }
}
</style>
