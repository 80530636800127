/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Mb } from '@/api/buyer/Mb/Mb';
import { Comm } from '@/utils';

class PrepaidCard extends Mb {
  constructor() {
    super();
    this.baseUrl += '/prepaidCard';
  }

  data({ currentPage, pageSize } = {}) {
    return super.get(`/data`, {
      currentPage,
      pageSize,
    });
  }
  get(id) {
    return super.get(`/get`, { id });
  }
  createBuyerPrepaidOrder(prepaidCardId) {
    return super.post(`/createBuyerPrepaidOrder`, { prepaidCardId });
  }

  /**
   * 是否已付款
   * @param paymentId
   * @returns {AxiosPromise}
   */
  isPay({ paymentId } = {}) {
    return super.get(`/isPay`, {
      buyerPrepaidOrderId: paymentId,
      _isHandleError: true,
    });
  }

  toPay(orderIds) {
    return super.get(`/toPay`, { orderIds });
  }

  /**
   * 获得订单付款信息
   * @returns {Promise<any>}
   */
  prepaidOrderDetail({ orderIds } = {}) {
    return super.get(`/prepaidOrderDetail`, { buyerPrepaidOrderId: orderIds });
  }

  /**
   * stripe 支付
   * @param orderIds
   * @param payType 1:快捷支付；2:普通支付
   * @param token
   * @param cardInfoId
   * @returns {AxiosPromise<any>}
   */
  payforStripe({ orderIds, payType, token, cardInfoId } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforStripe`, {
      orderIds,
      token,
      cardInfoId,
      payType,
    });
  }
  /**
   * 订单付款
   * @param orderIds               // 订单ID
   * @returns {AxiosPromise}
   */
  payforWeixin({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforWeixin`, { orderIds });
  }

  payforWeixinByScanCode({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforWeixinByScanCode`, { orderIds });
  }

  payforAllinPayWeixin({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforAllinPayWeixin `, { orderIds });
  }

  payforAllinPayWeixinByScanCode({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforAllinPayWeixinByScanCode`, { orderIds });
  }

  payforAlphaPayWeixinByScanCode({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforAlphaPayWeixinByScanCode`, { orderIds });
  }

  payforAlphaPayAlipayByScanCode({ orderIds } = {}) {
    Comm.required('orderIds', orderIds);
    return super.post(`/pay/payforAlphaPayAlipayByScanCode`, { orderIds });
  }
}

export default new PrepaidCard();
