<!-- Created by henian.xu on 2018/12/7. -->

<template>
  <div class="group-item">
    <div class="img-obj">
      <div
        class="inner"
        :style="`background-image: url('${data.avatar || $defaultImg}');`"
      />
    </div>
    <div class="info">
      <div class="nickname">
        {{ data.nickName }}
      </div>
      <div class="desc">
        还差 {{ data.groupBuyNums - data.joinBuyNums }} 人拼成
      </div>
      <div class="time">
        剩余 <GroupCountdown :end-time="data.endTime" />
      </div>
    </div>
    <div class="ctrl">
      <JoinGroupPopup
        :goods="goods"
        :group-buy-id="data.id"
      >
        <div class="btn btn-main">
          去参团
        </div>
      </JoinGroupPopup>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    goods: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.group-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
  background-color: #fff;

  + .group-item {
    margin-top: $margin;
  }

  > .img-obj {
    flex: 0 0 auto;
    width: 1rem;
    > .inner {
      width: 100%;
      height: 0;
      padding-top: 100%;
      background: transparent center no-repeat;
      background-size: cover;
      border-radius: 100%;
    }
  }
  > .info {
    padding: 0 $padding;
    flex: 1 1 1%;
    > .nickname {
      font-weight: bold;
    }
    > .desc {
      font-size: 80%;
    }
    > .time {
      font-size: 70%;
    }
  }
  > .ctrl {
    flex: 0 0 auto;
  }
}
</style>
