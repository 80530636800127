<!-- Created by henian.xu on 2021/11/11. -->

<template>
  <RawWaterfall
    v-bind="realProps"
    :auto-resize="false"
    ref="waterfall"
    @reflowed="onReflowed"
  >
    <slot v-if="isMounted" />
  </RawWaterfall>
</template>

<script>
import Waterfall from 'vue-waterfall/lib/waterfall';

export default {
  name: 'Waterfall',
  components: { RawWaterfall: Waterfall },
  data() {
    return {
      clientRect: null,
      props: {},
      isMounted: false,
    };
  },
  props: {
    // 原属性
    autoResize: {
      type: Boolean,
      default: true,
    },
    interval: {
      default: 200,
      validator: val => val >= 0,
    },
    align: {
      default: 'left',
      validator: val => ~['left', 'right', 'center'].indexOf(val),
    },
    line: {
      default: 'v',
      validator: val => ~['v', 'h'].indexOf(val),
    },
    lineGap: {
      // required: true,
      validator: val => val >= 0,
      default: null,
    },
    minLineGap: {
      validator: val => val >= 0,
      default: 0,
    },
    maxLineGap: {
      validator: val => val >= 0,
      default: 0,
    },
    singleMaxWidth: {
      validator: val => val >= 0,
      default: 0,
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    grow: {
      validator: val => val instanceof Array,
      default: undefined,
    },
    watch: {
      type: [Object, Array],
      default: () => ({}),
    },
    // 自定义属性
    column: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    isMobile() {
      return this.$globalVar.device.isMobile || this.$globalVar.isWeiXin;
    },
    realProps() {
      const { $props, props } = this;
      return {
        ...$props,
        ...props,
      };
    },
  },
  methods: {
    listenSize() {
      const {
        $refs: { waterfall },
      } = this;
      if (!waterfall) return;
      const resizeObserver = new ResizeObserver(this.onResizeObserver);
      resizeObserver.observe(waterfall.$el || waterfall);
    },
    onResizeObserver(entries) {
      if (!entries || !entries.length) return;
      const [{ contentRect }] = entries;
      const { width } = contentRect;
      const width_ = ~~(width / this.column);
      this.$set(this.props, 'lineGap', width_);
      // this.$set(this.props, 'maxLineGap', width_);
      // this.$set(this.props, 'minLineGap', width_);
      // this.$set(this.props, 'singleMaxWidth', width_);
    },
    onReflowed() {
      this.$emit('reflowed', this);
    },
  },
  mounted() {
    this.clientRect = JSON.parse(
      JSON.stringify(this.$el.getBoundingClientRect()),
    );
    const width = ~~(
      (this.clientRect.width - (this.isMobile ? 0 : 10)) /
      this.column
    );
    this.$set(this.props, 'lineGap', width);
    // this.$set(this.props, 'maxLineGap', width);
    // this.$set(this.props, 'minLineGap', width);
    // this.$set(this.props, 'singleMaxWidth', width);
    // this.listenSize();
    this.isMounted = true;
  },
};
</script>

<style lang='scss'>
</style>
