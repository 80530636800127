/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { Gd } from '@/api/buyer/Gd/Gd';
import { Comm, DataMap } from '@/utils';

class Goods extends Gd {
  constructor() {
    super();
    this.baseUrl += '/goods';
  }
  /**
   * 商品预览
   * @param id
   * @returns {Promise<any>}
   */
  goodsPreviewDetail({ id } = {}) {
    return super
      .get(`/goodsPreviewDetail`, {
        id,
      })
      .then(json => {
        const res = json.data.data;
        Comm.makeDataMap(res, DataMap.goods);
        return Promise.resolve(json);
      });
  }

  /**
   * 店铺前台分类
   * @returns {AxiosPromise}
   */
  shopGoodsCatList({ activityType, fulfilGiveId } = {}) {
    return super.get(`/shopGoodsCatList`, {
      activityType, // 0:普通商品,20:拼团商品
      fulfilGiveId,
      // _isRepeat: true,
      _isRepeat: true,
    });
  }

  goodsDetail({ id } = {}) {
    super.buyerVisitLog({ requestObjectType: 1, requestObjectId: id });
    return super
      .get(`/goodsDetail`, {
        id,
      })
      .then(json => {
        const res = json.data.data;
        Comm.makeDataMap(res.goods, DataMap.goods);
        return Promise.resolve(json);
      })
      .catch(_err => {
        return Promise.reject(_err);
      });
  }

  /**
   * 商品列表 (当选择/装修时用)
   * @param ids
   * @param activityType
   * @param orderByKey
   * @param orderByType
   *
   * @param goodsName
   * @param catId
   * @param pageSize
   * @param currentPage
   * @returns {Promise<any>}
   */
  goodsList({
    ids,
    fulfilGiveId,
    activityType,
    orderByKey,
    orderByType,
    goodsName,
    catId,
    pageSize,
    currentPage,
    _cancelSource,
  } = {}) {
    catId &&
      super.buyerVisitLog({ requestObjectType: 2, requestObjectId: catId });
    return super
      .get(`/goodsList`, {
        ids,
        fulfilGiveId,
        activityType,
        orderByKey,
        orderByType,
        goodsName,
        shopGoodsCatId: catId,
        pageSize,
        currentPage,
        _isRepeat: true,
        _cancelSource,
      })
      .then(json => {
        const res = json.data.data;
        Comm.makeDataMap(res, DataMap.goods);
        return Promise.resolve(json);
      });
  }
}

export default new Goods();
export { Goods };
