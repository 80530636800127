<template>
  <div class="progress-bar">
    <div class="bar">
      <div
        class="inner"
        :style="innerPosition()"
      />
      <div
        class="per"
        v-if="isLabel&&isLabelInner"
      >
        {{ label }}
      </div>
    </div>
    <slot name="label">
      <div
        class="label"
        v-if="isLabel&&!isLabelInner"
      >
        {{ label }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  data() {
    return {};
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    isLabelInner: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    label() {
      return `${((this.value / this.maxValue) * 100).toFixed(0)}%`;
    },
  },
  methods: {
    innerPosition() {
      const right = (100 - (this.value / this.maxValue) * 100).toFixed(0);
      return {
        right: right < 0 ? '0' : `${right}%`,
      };
    },
  },
};
</script>

<style lang="scss">
$barHeight: 0.22rem !default;
.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 0.2rem;

  > .bar {
    flex: 1 1 1%;
    position: relative;
    background-color: rgba(255, 204, 51, 0.5);
    height: $barHeight;
    border-radius: $barHeight/2;
    overflow: hidden;

    > .inner {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: 0;
      background-color: $color-main;
      border-radius: $barHeight/2;
    }

    > .per {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 0.2rem;
      line-height: $barHeight;
      text-align: right;
      color: mix(#e52779, #000, 30%);
      padding-right: $barHeight/4;
    }
  }
  > .label {
    flex: 0 0 auto;
    text-align: right;
    margin-left: $margin;
    white-space: nowrap;
    color: $color-main;
    line-height: 1;
  }
}
</style>
