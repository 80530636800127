/**
 * Created by henian.xu on 2018/5/7.
 *
 */

import { Sp } from '@/api/buyer/Sp/Sp';

class ShopDecoration extends Sp {
  constructor() {
    super();
    this.baseUrl += '/shopDecoration';
  }

  getShopIndexJson() {
    super.buyerVisitLog({ requestObjectType: 3 });
    return super.get('/getShopIndexJson');
  }

  getAdPageJson({ adPageType } = {}) {
    return super.get(`/getAdPageJson`, { adPageType, _isRepeat: true });
  }
}

export default new ShopDecoration();
export { ShopDecoration };
