/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Mb } from '@/api/feedback/Mb/Mb';

class Feedback extends Mb {
  constructor() {
    super();
    this.baseUrl += '/feedback';
  }

  feedbackList({ currentPage, pageSize } = {}) {
    return super.get(`/feedbackList`, { currentPage, pageSize });
  }

  getDetail({ id } = {}) {
    return super.get(`/getDetail`, { id });
  }

  submitFeedback({
    feedbackType, // 工单类型
    content, //正文
    feedbackImage, // 工单图片
  }) {
    return super.post('/submitFeedback', {
      feedbackType, // 工单类型
      content, //正文
      feedbackImage, // 工单图片
    });
  }
}

export default new Feedback();
export { Feedback };
