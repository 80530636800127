var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{attrs:{"name":"footer"}},[(_vm.type===1)?_c('div',{key:1,staticClass:"page-footer main"},[_c('linker',{staticClass:"btn",attrs:{"to":"/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[_c('XIcon',{attrs:{"content":isActive?'f08e':'f089'}}),_c('div',{staticClass:"label"},[_vm._v("\n          首页\n        ")])],1)]}}],null,false,2146571172)}),_c('linker',{class:['btn',{active:_vm.isGoodsList}],attrs:{"to":_vm.$globalVar.isAllowMoreLevel?'/goods/category/0':'/goods/list/0'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[_c('XIcon',{attrs:{"content":(isActive||_vm.isGoodsList)?'f090':'f08a'}}),_c('div',{staticClass:"label"},[_vm._v("\n          商品\n        ")])],1)]}}],null,false,2179057509)}),(_vm.$hasPower(['groupBuy:use']))?_c('linker',{staticClass:"btn group-buy-icon tc-main",attrs:{"to":"/goods/list/20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[_c('XIcon',{attrs:{"content":isActive?'f047':'f047'}}),_c('div',{staticClass:"label"},[_vm._v("\n          拼团\n        ")])],1)]}}],null,false,3554024677)}):_vm._e(),(_vm.$hasPower(['distributorEntrance:entrance']))?_c('linker',{staticClass:"btn",attrs:{"to":"/distributor/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[_c('XIcon',{attrs:{"content":isActive?'f048':'f048'}}),_c('div',{staticClass:"label"},[_vm._v("\n          代理\n        ")])],1)]}}],null,false,1013860286)}):_vm._e(),(_vm.$hasPower(['cart:use']))?_c('linker',{staticClass:"btn",attrs:{"to":"/cart"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[(_vm.cartCount)?_c('div',{staticClass:"mark"},[_vm._v("\n          "+_vm._s(_vm.cartCount)+"\n        ")]):_vm._e(),_c('XIcon',{attrs:{"content":isActive?'f004':'f08b'}}),_c('div',{staticClass:"label"},[_vm._v("\n          购物车\n        ")])],1)]}}],null,false,2466505614)}):_vm._e(),_c('linker',{staticClass:"btn",attrs:{"to":"/buyer/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('div',{staticClass:"inner"},[_c('XIcon',{attrs:{"content":isActive?'f003':'f08c'}}),_c('div',{staticClass:"label"},[_vm._v("\n          我的\n        ")])],1)]}}],null,false,2189272438)})],1):(_vm.type===3)?_c('StoreFooter',{key:3}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }