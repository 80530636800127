<!-- Created by henian.xu on 2019/6/4. -->

<template>
  <div
    v-if="html"
    class="custom-code"
    ref="self"
  >
    <!--<div class="ta-c pa-a bc-g2 tc-g6">自定义代码(CustomCode)组件</div>-->
  </div>
</template>

<script>
// const $div = document.createDocumentFragment().appendChild(document.createElement('div'));
export default {
  name: 'CustomCode',
  data() {
    return {
      rcleanScript: /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,
      preservedScriptAttributes: {
        type: true,
        src: true,
        nonce: true,
        noModule: true,
      },
    };
  },
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  computed: {
    htmlCode() {
      const { html } = this;
      if (!html) return null;
      const fragment = document.createDocumentFragment();
      const div = fragment.appendChild(document.createElement('div'));
      div.innerHTML = html;
      return div;
    },
  },
  methods: {
    append() {
      const { htmlCode, $refs } = this;
      if (!htmlCode) return;
      const { children } = htmlCode;
      const { self: $self } = $refs;
      // 无节点时返回
      if (!$self || !children || !children.length) return;
      const scriptList = Array.from(htmlCode.querySelectorAll('script'));
      const childrenList = Array.from(children);
      childrenList.forEach(node => {
        $self.appendChild(node);
      });
      // 如果有脚本节点
      scriptList.forEach(node => this.DOMEval(node));
    },
    DOMEval(node, doc = document) {
      if (!node) return;
      const { preservedScriptAttributes, rcleanScript } = this;
      const code = node.textContent.replace(rcleanScript, '');
      const script = doc.createElement('script');
      script.text = code;
      // 处理要保留的属性
      Object.keys(preservedScriptAttributes).forEach(key => {
        const val = node[key] || (node.getAttribute && node.getAttribute(key));
        if (!val) return;
        script.setAttribute(key, val);
      });
      doc.head.appendChild(script).parentNode.removeChild(script);
    },
  },
  mounted() {
    this.append();
    /*this.$nextTick(() => {
            const { htmlCode, $refs, html } = this;
            const $self = $refs['self'];
            /!*const $html = window.$(html);
            $html.each(function(node) {
                console.log(node,this);
                // $self.appendChild(this);
                window.$($self).append(this)
            });*!/
            htmlCode.forEach(node => {
                const tagName = (node.tagName || '').toLocaleLowerCase();
                if (tagName === 'script') {
                    const code = node.innerHTML;
                    const n = document.createElement('script');
                    n.innerText = code;
                    console.log(n.type);
                    console.dir(node.type);
                    window.$($self).append(node);
                    // $self.appendChild(node);
                } else {
                    $self.appendChild(node);
                }
            });
            // $self.appendChild(htmlCode);
        });*/
  },
};
</script>

<style lang="scss">
.custom-code {
}
</style>
