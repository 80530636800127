/**
 * Created by henian.xu on 2018/6/22.
 *
 */

import GlobalVar from 'globalVar';
import Vue from 'vue';
const vm = new Vue();

export default {
  namespaced: true,
  state: {
    userData: GlobalVar.userData || {},
    type: GlobalVar.userType,
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    isLogin(state) {
      return !!+state.userData.id;
    },
    isSalesman(state) {
      return 4 & state.userData.type;
    },
    isDistributor(state) {
      return (
        vm.$hasPower(['distributorEntrance:entrance']) &&
        !!(2 & state.userData.type)
      );
    },
    distributionIncomeMode(state) {
      return state.userData.distributionIncomeMode;
    },
  },
  mutations: {
    updateUserData(state, data) {
      const { userData } = state;
      Object.keys(userData).forEach(key => {
        const item = data[key];
        userData[key] = !isNaN(item) ? item : item || userData[key];
      });
    },
    addIdentity(state, type) {
      if (!(type & 2)) {
        if (GlobalVar.isDev) {
          throw new Error('身份标识错误. 1,2,4,8,16');
        }
        return;
      }
      state.userData.type |= type;
    },
  },
  actions: {},
};
