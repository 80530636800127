<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <div class="group-goods-item">
    <div
      class="img-wrap"
      :style="`background-image: url('${data.mainImageUrl || $defaultImg}');`"
    />
    <div class="inner">
      <div class="name">
        {{ data.goodsName }}
      </div>
      <div
        class="sku"
        v-if="data.specDesc"
      >
        <span>{{ data.specDesc }}</span>
      </div>
      <div class="price-wrap">
        <div class="price">
          {{ data.activityPrice }}
        </div>
        <div class="quantity">
          拼团省 {{ data.minPrice - data.activityMinPrice | price }}
        </div>
        <div class="quantity">
          {{ data.groupBuyNums }} 人团
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupGoodsItem',
  data() {
    return {
      countdown: {},
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.group-goods-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: $padding;
  background-color: #fff;
  border-top: 1px solid $color-border;

  &:first-child {
    border-top: 0;
  }
  > .img-wrap {
    flex: 0 0 auto;
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: $margin;

    > .sold-out {
      background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png')
        center no-repeat;
      background-size: 70%;
      width: 100%;
      height: 100%;
    }
  }
  > .inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    > .name {
      line-height: 1.2;
      font-size: 0.3rem;
      @include text-line(2);
    }
    > .sku {
      > span {
        display: inline-block;
        font-size: 0.22rem;
        line-height: 1.5;
        padding: 0 $padding-small;
        border: 1px solid $color-main;
        color: $color-main;
        border-radius: (0.22rem * 1.5)/2;
      }
    }
    > .price-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      > .quantity {
        font-size: 75%;
        color: $gray6;
      }
    }
  }
}
</style>
