/**
 * Created by henian.xu on 2019/8/2.
 * PortalInfo
 */

import { Comm } from '@/api';
import Store from '@/store';

export default {
  namespaced: true,
  state: {
    rawData: null,
  },
  getters: [
    // { key: 'personalCenterImage', default: '', isSingleImg: true },
    { key: 'personalCenterBackground', default: '', isSingleImg: true },
    // add
  ].reduce(
    (pre, cur) => {
      const { key } = cur;
      pre[key] = function handler(state, getters) {
        const { rawData } = getters;
        let value = rawData[key] || cur.default;
        if (cur.isSingleImg && Array.isArray(value)) {
          value = (value[0] || {}).image || value;
        }
        return value;
      };
      return pre;
    },
    {
      rawData(state) {
        const { rawData } = state;
        if (rawData === null) Store.dispatch('portalInfo/updated');
        return rawData || {};
      },
    },
  ),
  mutations: {
    // 设置原始数据
    _setRawData(state, data) {
      state.rawData = data;
    },
  },
  actions: {
    // 更新原始数据
    updated: (() => {
      let requestPromises = null;
      return async function func({ commit }) {
        if (requestPromises) {
          return new Promise((resolve, reject) => {
            requestPromises.push({ resolve, reject });
          });
        }
        requestPromises = [];
        let res = null;
        try {
          commit('_setRawData', {});
          res = await Comm.Pt.PortalInfoConfig.getPortalInfoConfig();
          commit('_setRawData', res.data.data || {});
        } catch (e) {
          res = Promise.reject(e);
        }

        requestPromises.forEach(({ resolve, reject }) => {
          if (res instanceof Promise) {
            res
              .then(r => {
                resolve(r);
              })
              .catch(e => {
                reject(e);
              });
          } else {
            resolve(res);
          }
        });
        requestPromises = null;
        return res;
      };
    })(),
  },
};
