<!-- Created by henian.xu on 2019/5/14. -->

<template>
  <div class="proxy-store-add-category">
    <div
      class="out-show"
      @click="onOpen"
    >
      <slot />
    </div>

    <transition :name="aniName">
      <Page
        ref="popup"
        v-show="popupShow"
      >
        <Container>
          <XForm
            ref="form"
            :model="formModel"
            :rules="formRules"
          >
            <FormItem
              label="类目名称"
              prop="goodsCatName"
            >
              <XInput v-model="formModel.goodsCatName" />
            </FormItem>
            <FormItem
              label="排序号"
              prop="orderNum"
            >
              <XInput v-model="formModel.orderNum" />
            </FormItem>
            <FormItem prop="isAvailable">
              <Checkbox v-model="formModel.isAvailable">
                是否启用
              </Checkbox>
            </FormItem>
          </XForm>
        </Container>
        <PageBar>
          <Button
            label="保存类目"
            theme="main"
            @click="()=>{onSubmit()}"
          />
        </PageBar>
      </Page>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
export default {
  name: 'ProxyStoreAddCategory',
  mixins: [popupMixin],
  data() {
    return {
      formModel: {
        id: '',
        goodsCatName: '', //类目名称
        orderNum: '', //排序号
        isAvailable: true, //是否可用
      },
      formRules: {
        goodsCatName: {
          required: true,
          message: '不能为空',
        },
        orderNum: {
          required: true,
          message: '不能为空',
        },
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        const { data, formModel } = this;
        Object.keys(formModel).forEach(key => {
          if (data.hasOwnProperty(key)) {
            formModel[key] = data[key];
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async onSubmit() {
      await this.$refs['form'].validate();
      const { formModel } = this;
      const data = {
        ...formModel,
        isAvailable: +formModel.isAvailable,
      };
      const json = await this.$api.Buyer.Sp.ShopStoreGoodsCat.save(data);
      this.$emit('update', json.data.data);
      this.done().then(() => {
        this.$messageBox.tips(json.data.msg);
      });
    },
    onOpen() {
      this.open();
      this.$refs['form'].resetFields();
    },
  },
};
</script>

<style lang="scss">
.proxy-store-add-category {
  flex: 1 1 1%;
  > .out-show {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .button {
      flex: 1 1 1%;
    }
  }
}
</style>
