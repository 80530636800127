/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Ex } from '@/api/buyer/Ex/Ex';

class OrderInvoice extends Ex {
  constructor() {
    super();
    this.baseUrl += '/orderInvoice';
  }

  /**
   * 发票下载
   * @param orderId           订单ID
   * @param emailAddress
   * @returns {AxiosPromise}
   */
  sendOrderEmail({ orderId, emailAddress } = {}) {
    return super.post(`/sendOrderEmail`, {
      orderId,
      emailAddress,
    });
  }
}

export default new OrderInvoice();
export { OrderInvoice };
