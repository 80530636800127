/**
 * Created by henian.xu on 2018/9/11.
 * 商品模块
 */
import { Buyer } from '@/api/buyer/Buyer';

class Sp extends Buyer {
  constructor() {
    super();
    this.baseUrl += '/sp';
  }
}

export default new Sp();
export { Sp };
