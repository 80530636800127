<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <div 
    :to="`/distributor/detail/${data.buyerId}`" 
    class="dis-item"
  >
    <div class="header">
      <div class="name">
        {{ data.name }}
      </div>
      <div class="mobile">
        {{ data.mobile }}
      </div>
    </div>
    <div 
      class="body" 
      v-if="data.regionName"
    >
      <div class="region">
        {{ data.regionName }}
      </div>
    </div>
    <div class="footer">
      <div />
      <div
        class="btn btn-main"
        @click="onResetPassword"
      >
        重置密码
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisItem',
  data() {
    return {
      countdown: {},
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    onResetPassword() {
      this.$api.Buyer.Mb.Distributor.resetBuyerPassword({
        buyerId: this.data.buyerId,
      }).then(json => {
        const res = json.data;
        this.$messageBox.tips(res.msg);
      });
    },
  },
};
</script>

<style lang="scss">
.dis-item {
  display: block;
  padding: $padding;
  background-color: #fff;
  margin-top: $margin;
  &:first-child {
    margin-top: 0;
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small 0;
  }
  > .body {
    padding: $padding-small 0;
  }
  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small 0;
  }
}
</style>
