/**
 * Created by henian.xu on 2018/8/29.
 * 权限
 */

import { Comm } from '@/api';

export default {
  namespaced: true,
  modules: {},
  state: {
    dataList: null,
  },
  getters: {
    dataMap(state) {
      const { dataList } = state;
      if (!dataList) return {};
      return dataList.reduce((prev, curr) => {
        prev[curr] = true;
        return prev;
      }, {});
    },
  },
  mutations: {
    setDataList(state, data) {
      state.dataList = data;
    },
    clearData(state) {
      state.dataList = null;
    },
  },
  actions: {
    getMenuResource({ commit, state }) {
      // console.log(111111);
      if (state.dataList !== null) return Promise.resolve(state.dataList);
      // console.log(222222, Buyer.User);
      return Comm.Rs.Menu.getAppMenuResource().then(json => {
        const res = json.data;
        commit('setDataList', res.data);
        return Promise.resolve(res.data);
      });
    },
  },
};
