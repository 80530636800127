<!-- Created by henian.xu on 2018/1/12. -->

<!--<template>
    <div class="tabs">
        <div class="header">
            <div class="btn prev"><i class="f-icon">&#xf011;</i></div>
            <div class="tab-nav">
                <div class="inner">
                    <div class="item"><div class="label">1item item item</div></div>
                    <div class="item"><div class="label">2item item item</div></div>
                    <div class="item"><div class="label">3item item item</div></div>
                    <div class="item"><div class="label">4item item item</div></div>
                    <div class="item"><div class="label">5item item item</div></div>
                </div>
            </div>
            <div class="btn next"><i class="f-icon">&#xf012;</i></div>
            <div class="btn btn-main">btn</div>
        </div>
        <div class="body">
            <div class="tab-panel">panel1</div>
            <div class="tab-panel">panel2</div>
            <div class="tab-panel">panel3</div>
            <div class="tab-panel">panel4</div>
            <div class="tab-panel">panel5</div>
        </div>
    </div>
</template>-->

<script>
import TabNav from '@/components/tabs/tabNav';

export default {
  components: { TabNav },
  name: 'Tabs',
  data() {
    return {
      panes: [],
      currIndex: this.index,
      panesAni: '',
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    average: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabContent_() {
      return this.$refs.tabContent;
    },
    tabContentHeight: {
      get() {
        return this.$refs.tabContent_.clientHeight;
      },
      set(val) {
        val = Number(val) ? `${val}px` : 'auto';
        this.tabContent_.style.height = val;
      },
    },
  },
  methods: {
    addPanes(panel) {
      const index = this.$slots.default
        .filter(item => {
          return (
            item.elm.nodeType === 1 && /\btab-panel\b/.test(item.elm.className)
          );
        })
        .indexOf(panel.$vnode);
      if (index === -1) return;
      this.panes.splice(index, 0, panel);
      this.panes.forEach((item, i) => {
        if (item.$slots.nav && item.$slots.nav.length) {
          delete item.label;
          item._navItem = item.$slots.nav[0];
        }
        if (item.activePanel) this.currIndex = i;
      });
    },
    onTabClick($event, item, index) {
      if (this.currIndex === index) return;
      if (this.currIndex < index) {
        this.panesAni = 'left';
      } else {
        this.panesAni = 'right';
      }
      this.currIndex = index;
      this.$emit('tabChange', item, index);
    },
  },
  watch: {
    index(val) {
      this.currIndex = val;
    },
  },
  created() {},
  render() {
    const { panes, onTabClick, average } = this;
    const navData = {
      props: {
        panes,
        onTabClick,
      },
    };
    return (
      <div class={['tabs', { average }]}>
        <tab-nav {...navData} />
        <div class="tab-content" ref="tabContent">
          {this.$slots.default}
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss">
$navSize: 0.8rem;
.tabs {
  > .tab-nav-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    min-height: $navSize;
    background-color: #fff;

    > .btn {
      flex: 0 0 auto;
    }
    > .prev,
    > .next {
      padding: $padding-small;
    }
    > .tab-nav {
      flex: 1 1 auto;
      overflow: auto;
      overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
        opacity: 0;
      }
      > .inner {
        min-width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        border-bottom: 1px solid $color-border;

        > .item {
          padding: $padding $padding-big;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          white-space: nowrap;

          + .item {
            //border-left: 1px solid $color-border;
          }

          > .label {
            white-space: nowrap;
          }

          &.active {
            position: relative;
            //border-bottom: 2px solid $color-main;
            color: $color-main;
            &:after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: 0;
              width: 1em;
              height: 0.05rem;
              background-color: $color-main;
              border-radius: 100vw;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  > .tab-content {
    position: relative;
    overflow: hidden;
    transition-property: height;
    transition-duration: 0.5s;

    .tab-panel {
      /*position: absolute;*/
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      /*background-color: #fff;*/

      &.left-leave-to,
      &.left-enter-to,
      &.right-leave-to,
      &.right-enter-to {
        position: absolute;
      }
    }
  }

  &.average {
    > .tab-nav-wrap {
      > .tab-nav {
        > .inner {
          display: flex;
          justify-content: space-between;

          > .item {
            width: 1%;
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}
</style>
