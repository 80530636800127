<!-- Created by henian.xu on 2019/8/8. -->

<template>
  <div
    class="proxy-store-selected-item"
    v-on="$listeners_"
  >
    <div class="inner">
      <div class="img-obj">
        <checkbox
          v-if="model"
          :val="goods.id"
          v-model="model"
        />
        <div
          class="inner"
          :style="`background-image: url('${goods.mainImageUrl||$defaultImg}');`"
        />
      </div>
      <div class="content">
        <div class="header">
          <div class="label">
            {{ goods.goodsName }}
          </div>
        </div>
        <div class="body">
          <div class="item">
            <span class="badge badge-main fs-small">样式</span>
            <span class="detail">默认</span>
          </div>
          <div class="item">
            <span class="badge badge-main fs-small">分类</span>
            <Selector
              class="detail"
              :data="catSelectList"
              placeholder="未分类"
              multiple
              readonly
              v-model="formModel.goodsCatIds"
            />
          </div>
          <div class="item">
            <span class="badge badge-main fs-small">进货价</span>
            <span class="detail price">{{ goods.price | price }}</span>
          </div>
          <div class="item">
            <span class="badge badge-main fs-small">售卖价</span>
            <span class="detail price">{{ salePrice | price }}</span>
          </div>
        </div>
        <div class="footer">
          <Button
            label="删除"
            theme="red"
            radius
            size="small"
            @click.stop="onDel"
          />
          <Button
            theme="blue"
            radius
            size="small"
            @click.stop="()=>{}"
          >
            <ProxyStoreAddGoods
              :goods-id="goods.id"
              @success="onEditSuccess"
              :init-form-model="formModel"
            >
              编辑
            </ProxyStoreAddGoods>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProxyStoreSelectedItem',
  data() {
    return {};
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    catSelectList() {
      return this.data.catSelectList || [];
    },
    goods() {
      return this.data.goods || {};
    },
    skuList() {
      return this.goods.skuList || [];
    },
    salePrice() {
      const { skuList, skuJson } = this;
      const skuJsonMap = skuJson.reduce(
        (pre, cur) => ((pre[cur.skuId] = cur), pre),
        {},
      );
      const priceList = skuList.map(item => {
        return item.price + ((skuJsonMap[item.skuId] || {}).addPrice || 0);
      });
      const max = Math.max.apply(null, priceList);
      const min = Math.min.apply(null, priceList);
      if (max === min) {
        return `${max}`;
      } else {
        return `${min}-${max}`;
      }
    },
    formModel() {
      return this.data.formModel || {};
    },
    // object
    skuJson() {
      return JSON.parse(this.formModel.skuJson || '[]');
    },
    $listeners_() {
      const data = {
        ...this.$listeners,
      };
      delete data.input;
      return data;
    },
  },
  methods: {
    onDel() {
      this.$emit('delete');
    },
    onEditSuccess(data) {
      if (typeof data !== 'object' || Array.isArray(data)) return;
      this.$emit('editSuccess', JSON.parse(JSON.stringify(data)));
    },
  },
};
</script>

<style lang="scss">
.proxy-store-selected-item {
  background-color: #fff;
  padding: $padding-small;
  flex: 1 1 1%;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .img-obj {
      position: relative;
      flex: 0 0 auto;
      width: 2.6rem;
      margin-right: $margin-small;

      > .inner {
        background: center no-repeat;
        background-size: cover;
        width: 100%;
        padding-top: 100%;
      }

      > .checkbox {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    > .content {
      flex: 1 1 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      > .header {
        > .label {
          font-weight: bold;
          line-height: 1.2;
          @include text-line(1);
        }
      }
      > .body {
        > .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          > .badge {
            flex: 0 0 auto;
            margin-right: $margin-small;
            min-width: 4em;
            text-align: center;
          }
          > .detail {
            flex: 1 1 1%;
            &.selector {
              padding: 0;
              > .out-show {
                > .inner {
                  @include text-line(1);
                }
              }
            }
          }
        }
      }
      > .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > .button + .button {
          margin-left: $margin-small;
        }
      }
    }
  }
}
</style>
