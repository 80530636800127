<!-- Created by henian.xu on 2019/3/6. -->

<template>
  <div class="store-freight-picker">
    <div
      class="item"
      v-for="(item,index) in model"
      :key="index"
    >
      <FormItem label="支持送货的 Zip Code:">
        <XInput
          placeholder="请输入邮编"
          v-model="item.zipcode"
        />
      </FormItem>
      <FormItem label="免邮费金额:">
        <XInput
          placeholder="请输入邮编"
          v-model="item.freeFreightOrderAmount"
        />
      </FormItem>
      <FormItem label="运费金额:">
        <XInput
          placeholder="请输入邮编"
          v-model="item.freightAmount"
        />
      </FormItem>
      <div class="ctrl">
        <div
          class="item del f-icon"
          @click="onDel(index)"
        >
          &#xf01a;
        </div>
        <div
          v-if="index!==0"
          class="item up f-icon"
          @click="onUp(index)"
        >
          &#xf043;
        </div>
        <div
          v-if="index+1!==model.length"
          class="item down f-icon"
          @click="onDown(index)"
        >
          &#xf044;
        </div>
      </div>
    </div>
    <Button
      class="w-100per"
      size="small"
      theme="second"
      radius
      label="添加"
      icon="&#xf013;"
      @click="onAdd"
    />
  </div>
</template>

<script>
export default {
  name: 'StoreFreightPicker',
  data() {
    return {
      formModel: {},
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(111);
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onAdd() {
      this.model.push({
        zipcode: '',
        freeFreightOrderAmount: '',
        freightAmount: '',
      });
    },
    onDel(index) {
      this.model.splice(index, 1);
    },
    onUp(index) {
      const item = this.model.splice(index, 1)[0];
      this.model.splice(index - 1, 0, item);
    },
    onDown(index) {
      const item = this.model.splice(index, 1)[0];
      this.model.splice(index + 1, 0, item);
    },
  },
};
</script>

<style lang="scss">
.store-freight-picker {
  flex: 1 1 1%;
  > .item {
    position: relative;
    border: 1px solid $color-border;
    margin-bottom: $margin;

    > .ctrl {
      position: absolute;
      top: -0.6em;
      right: -0.6em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      > .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 1.6em;
        height: 1.6em;
        font-size: 120%;
        &.del {
          color: $color-red;
        }
        &.up {
          color: $gray4;
        }
        &.down {
          color: $gray4;
        }
      }
    }
  }
}
</style>
