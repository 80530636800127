<!-- Created by henian.xu on 2019/3/6. -->

<template>
  <div class="store-goods-picker">
    <div
      class="item"
      v-for="(item,index) in model"
      :key="index"
    >
      <div
        class="store-goods-pick-item"
        v-for="(sku) in item.skuList"
        :key="sku.skuId"
      >
        <div class="img-obj">
          <div
            class="inner"
            :style="`background-image: url('${sku.specImageUrl||$defaultImg}');`"
          />
        </div>
        <div class="inner">
          <div class="body">
            <div class="label">
              {{ sku.goodsName }}
            </div>
          </div>
          <div class="footer">
            <FormItem
              class="npa-a"
              label="数量:"
            >
              <XInput
                placeholder="请输入邮编"
                v-model="sku.salableQuantity"
              />
            </FormItem>
            <FormItem
              class="npa-a nbor-a"
              label="价格:"
            >
              <XInput
                placeholder="请输入邮编"
                v-model="sku.price"
              />
            </FormItem>
            <!--<div class="ctrl">
                            <Button
                                theme="red"
                                radius
                                size="small"
                                icon="&#xf008;"/>
                        </div>-->
          </div>
        </div>
      </div>
      <div class="ctrl">
        <div
          class="item del f-icon"
          @click="onDel(index)"
        >
          &#xf01a;
        </div>
        <div
          v-if="index!==0"
          class="item up f-icon"
          @click="onUp(index)"
        >
          &#xf043;
        </div>
        <div
          v-if="index+1!==model.length"
          class="item down f-icon"
          @click="onDown(index)"
        >
          &#xf044;
        </div>
      </div>
    </div>
    <GoodsPopup @input="onAdd">
      <Button
        class="w-100per"
        size="small"
        theme="second"
        radius
        label="添加"
        icon="&#xf013;"
      />
    </GoodsPopup>
  </div>
</template>

<script>
import GoodsPopup from './_StoreGoodsPopup';
export default {
  name: 'StoreGoodsPicker',
  components: { GoodsPopup },
  data() {
    return {
      formModel: {},
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(111);
        this.$emit('input', [...val]);
      },
    },
  },
  methods: {
    onAdd(item) {
      item = JSON.parse(JSON.stringify(item));
      delete item.id;
      item.skuList.forEach(sku => {
        sku.salableQuantity = 1;
        delete sku.id;
      });
      this.model.push(item);
      this.model = this.value;
    },
    onDel(index) {
      this.model.splice(index, 1);
    },
    onUp(index) {
      const item = this.model.splice(index, 1)[0];
      this.model.splice(index - 1, 0, item);
    },
    onDown(index) {
      const item = this.model.splice(index, 1)[0];
      this.model.splice(index + 1, 0, item);
    },
  },
};
</script>

<style lang="scss">
.store-goods-picker {
  flex: 1 1 1%;
  > .item {
    position: relative;
    border: 1px solid $color-border;
    margin-bottom: $margin;

    > .ctrl {
      position: absolute;
      top: -0.6em;
      right: -0.6em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      > .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 1.6em;
        height: 1.6em;
        font-size: 120%;
        &.del {
          color: $color-red;
        }
        &.up {
          color: $gray4;
        }
        &.down {
          color: $gray4;
        }
      }
    }
  }
}

.store-goods-pick-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  background-color: #fff;
  padding: $padding;
  + .store-goods-item {
    margin-top: $margin;
  }
  > .img-obj {
    flex: 0 0 auto;
    width: 140px;
    margin-right: $margin;
    > .inner {
      width: 100%;
      padding-top: 100%;
      background: transparent center no-repeat;
      background-size: cover;
    }
  }
  > .inner {
    flex: 1 1 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    > .body {
      flex: 1 1 1%;
    }
    > .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
