/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Comm } from '@/utils';
import { Ex } from '@/api/buyer/Ex/Ex';

class GroupBuy extends Ex {
  constructor() {
    super();
    this.baseUrl += '/groupBuy';
  }
  shareLinkGroupBuyDetail({ orderId } = {}) {
    Comm.required('shareLinkGroupBuyDetail', orderId);
    return super.get(`/shareLinkGroupBuyDetail`, { orderId });
  }
  groupBuyDetail({ id } = {}) {
    return super.get('/groupBuyDetail', { id });
  }
  groupBuyList({ goodsId, currentPage, pageSize } = {}) {
    return super.get('/groupBuyList', { goodsId, currentPage, pageSize });
  }
}

export default new GroupBuy();
export { GroupBuy };
