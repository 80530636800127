/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStore extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStore';
  }

  /**
   * 获取门店详情
   * @returns {AxiosPromise<any>}
   */
  getShopStoreDetail() {
    return super.get(`/getShopStoreDetail`);
  }

  /**
   * 获取店铺可用付款模式
   * @returns {AxiosPromise<any>}
   */
  getShopStorePaymentTypeList() {
    return super.get(`/getShopStorePaymentTypeList`);
  }

  /**
   * 保存门店信息
   * @param storeName
   * @param storeLogo
   * @param storeAdImage
   * @param storeDesc
   * @param storeRegionId
   * @param storeDetailAddress
   * @param houseNum
   * @param contactEmail
   * @param settlementCurrency
   * @param shipMethod
   * @param paymentType
   * @returns {AxiosPromise<any>}
   */
  saveShopStore({
    storeName, //门店名称
    storeLogo, //门店logo
    storeAdImage, //门店广告
    storeDesc, //门店描述
    storeRegionId, //地区ID
    storeDetailAddress, //详细地址
    houseNum, //门牌号
    contactEmail, //邮箱地址
    settlementCurrency, //结算币种
    shipMethod, //送货方式
    paymentType, //付款类型
    storeStyle, //店铺风格
    customerServiceImage, //客服联系方式
    sendConsignor,
    sendTel,
  }) {
    return super.post(`/saveShopStore`, {
      storeName, //门店名称
      storeLogo, //门店logo
      storeAdImage, //门店广告
      storeDesc, //门店描述
      storeRegionId, //地区ID
      storeDetailAddress, //详细地址
      houseNum, //门牌号
      contactEmail, //邮箱地址
      settlementCurrency, //结算币种
      shipMethod, //送货方式
      paymentType, //付款类型
      storeStyle, //店铺风格
      customerServiceImage, //客服联系方式
      sendConsignor,
      sendTel,
    });
  }

  /**
   * 获取活动可用付款方式
   * @returns {AxiosPromise<any>}
   */
  getStorePaymentTypeList() {
    return super.get(`/getStorePaymentTypeList`);
  }
  /**
   * 获取活动可用送货方式
   * @returns {AxiosPromise<any>}
   */
  getStoreShipMethodList() {
    return super.get(`/getStoreShipMethodList`);
  }
  // 已弃用，统一用分销商的提现接口
  shopStoreWithdraw({ withdrawAmount }) {
    return super.post(`/shopStoreWithdraw`, {
      withdrawAmount,
      _isHandleError: true,
    });
  }

  /**
   * 提现记录  已弃用统一用分销商的提现记录
   * @param currentPage
   * @param pageSize
   * @return {*}
   */
  shopStoreWithdrawList({ currentPage, pageSize }) {
    return super.get(`/shopStoreWithdrawList`, { currentPage, pageSize });
  }

  /**
   * 一键开店
   * @param addPrice
   * @param addPriceType
   * @return {*}
   */
  autoInitShopStore({ addPrice, addPriceType }) {
    return super.post('/autoInitShopStore', {
      addPrice,
      addPriceType,
    });
  }

  /**
   * 修改店铺装修信息
   * @param storeName
   * @param storeLogo
   * @param storeAdImage
   * @param customerServiceImage
   * @param storeDecorationJson
   * @return {*}
   */
  saveShopStoreDecoration({
    storeName,
    storeLogo,
    storeAdImage,
    customerServiceImage,
    storeDecorationJson,
  }) {
    return super.post('/saveShopStoreDecoration', {
      storeName,
      storeLogo,
      storeAdImage,
      customerServiceImage,
      storeDecorationJson,
    });
  }

  /**
   * 修改发货信息
   * @param sendConsignor
   * @param sendTel
   * @return {*}
   */
  saveShopStoreSendInfo({ sendConsignor, sendTel }) {
    return super.post('/saveShopStoreSendInfo', {
      sendConsignor,
      sendTel,
    });
  }

  orderSettlementList({ settlementStatus, currentPage, pageSize }) {
    return super.get(`/orderSettlementList`, {
      settlementStatus,
      currentPage,
      pageSize,
    });
  }
}

export default new ShopStore();
