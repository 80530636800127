/**
 * Created by henian.xu on 2018/4/19.
 *
 */

import { Mb } from '@/api/buyer/Mb/Mb';
// import {DataMap, Comm} from '@/utils';

class CollectGoods extends Mb {
  constructor() {
    super();
    this.baseUrl += '/collectGoods';
  }

  /**
   * 收藏列表
   * @param currentPage
   * @param pageSize
   * @returns {Promise<any>}
   */
  collectGoodsList({ currentPage, pageSize } = {}) {
    return super.get(`/collectGoodsList`, {
      currentPage,
      pageSize,
    });
  }

  /**
   * 收藏商品
   * @param goodsId
   * @param storeId
   * @returns {AxiosPromise}
   */
  collectGoods({ goodsId, storeId } = {}) {
    return super.post(`/collectGoods`, {
      goodsId,
      storeId,
    });
  }

  /**
   * 取消收藏
   * @param ids
   * @returns {AxiosPromise}
   */
  delCollectGoods({ ids } = {}) {
    return super.post(`/delCollectGoods`, {
      ids,
    });
  }
}

export default new CollectGoods();
export { CollectGoods };
