<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <div class="list">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.list {
  &.goods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
</style>
