<!-- Created by henian.xu on 2018/8/23. -->

<template>
  <div class="pocket-money-item">
    <div class="header">
      <div class="label">
        {{ data.balanceChangeDesc }}
      </div>
      <div class="date">
        {{ data.changeTime | dateTime }}
      </div>
    </div>
    <div class="body">
      <span
        class="fs-big fw-bold"
        :class="{
          'tc-red':data.balanceChangeValue<0,
          'tc-green':data.balanceChangeValue>0,
        }"
      >{{ data.balanceChangeValue>0?'+':'' }}{{ data.balanceChangeValue | price }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PocketMoneyItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.pocket-money-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  padding: $padding;

  + .pocket-money-item {
    border-top: 1px solid $color-border;
  }

  > .header {
    flex: 1 1 1%;
    > .label {
      font-size: 0.3rem;
      font-weight: bold;
    }
  }
  > .body {
    flex: 0 0 auto;
  }
}
</style>
