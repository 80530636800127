/**
 * Created by henian.xu on 2018/9/11.
 * Rs 基类 继承类
 */
import { Comm } from '@/api/comm/Comm';

class Pt extends Comm {
  constructor() {
    super();
    this.baseUrl += '/pt';
  }
}

export default new Pt();
export { Pt };
