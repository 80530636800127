/**
 * Created by henian.xu on 2018/9/11.
 * 商品模块
 */
import { Feedback } from '@/api/feedback/Feedback';

class Mb extends Feedback {
  constructor() {
    super();
    this.baseUrl += '/mb';
  }
}

export default new Mb();
export { Mb };
