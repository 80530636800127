/**
 * Created by henian.xu on 2019/6/10.
 *
 */

import MeiQiaControl from './MeiQiaControl';
export default {
  namespaced: true,
  modules: {},
  state: {
    meiQiaControl: MeiQiaControl,
  },
  getters: {
    meiQiaAllSet(state) {
      return state.meiQiaControl.meiQiaAllSet;
    },
    unreadMsg(state) {
      return state.meiQiaControl.unreadMsg || [];
    },
    unreadMsgCount(state, getters) {
      return getters.unreadMsg.length;
    },
  },
  mutations: {},
  actions: {},
};
