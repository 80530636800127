<!-- Created by henian.xu on 2018/9/13. -->

<template>
  <GridGroup
    class="toolbar"
    :columns="column"
    :grid-line="gridLine"
  >
    <!--<Grid
            icon="&#xf01c;"
            label="茅台专区"
        />-->
    <Grid
      v-for="(item,index) in data"
      :key="item.id || index"
      :to="item.href"
      icon="&#xf01c;"
      :label="item.label"
    >
      <div
        class="icon"
        slot="icon"
        :style="`background-image: url('${$options.filters.imgCropping(item.src,{width:200})}');`"
      />
      <div
        class="label"
        slot="label"
      >
        {{ item.label }}
      </div>
    </Grid>
  </GridGroup>
</template>

<script>
export default {
  name: 'Toolbar',
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    column: {
      type: [Number, String],
      default: 4,
    },
    gridLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.toolbar {
  //padding: $padding;
  > .inner {
    //background-color: #fff;
    //border-radius: $padding-small;
  }
  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.1rem;
    height: 1.1rem;
  }
  .label {
    min-height: $body-font-size * 1.5;
    font-size: 85%;
    margin-top: $margin-small;
    color: $color-text-minor;
    @include text-line(1);
  }
}
</style>
