<!-- Created by henian.xu on 2018/1/18. -->

<template>
  <div
    class="infinite-loading"
    v-show="!disabled && !(noShowComplete&&isComplete)"
  >
    <div
      v-show="!isComplete && !disabled"
      class="infinite-status-prompt"
    >
      <slot name="loading">
        <div class="ta-c">
          加载中...
        </div>
      </slot>
    </div>
    <div
      v-show="isNoResults"
      class="infinite-status-prompt"
    >
      <slot name="no-result">
        <div class="ta-c pa-a ma-t">
          <img
            class="img-obj"
            style="opacity: .3;"
            src="~@/assets/images/icon/icon01.png"
            width="40%"
          >
          <div class="tc-g6">
            没有数据
          </div>
        </div>
      </slot>
    </div>
    <div
      v-show="isNoMore"
      class="infinite-status-prompt"
    >
      <slot name="no-more">
        <div class="ta-c">
          <div class="page-subtitle nma-a pa-t pa-b">
            <span>我是有底线的</span>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfiniteLoading',
  data() {
    return {
      scrollParent: null,
      isLoading: false,
      isComplete: false,
      isFirstLoad: true,
      // 反抖动计时器
      debounceTimer: null,
      debounceDuration: 50,
      infiniteState: {
        loaded: this.onStateLoaded,
        complete: this.onStateComplete,
        reset: this.onStateReset,
        // isFirstLoad: this.isFirstLoad,
      },
    };
  },
  computed: {
    isNoResults: {
      get() {
        return !this.isLoading && this.isComplete && this.isFirstLoad;
      },
    },
    isNoMore: {
      get() {
        return !this.isLoading && this.isComplete && !this.isFirstLoad;
      },
    },
  },
  props: {
    distance: {
      type: Number,
      default: 100,
    },
    direction: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noShowComplete: {
      type: Boolean,
      default: false,
    },
    loadFirst: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    disabled(val) {
      !val ? this.open() : this.close();
    },
  },
  methods: {
    /**
     * 获取滚动条父节点
     * @param elm
     * @returns {*}
     */
    getScrollParent(elm = this.$el) {
      let result;
      if (elm.tagName === 'BODY') {
        // 保留
      } else if (
        ['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1
      ) {
        result = elm;
      } else if (
        elm.hasAttribute('infinite-wrapper') ||
        elm.hasAttribute('data-infinite-wrapper')
      ) {
        result = elm;
      }
      return result || this.getScrollParent(elm.parentNode);
    },
    /**
     * 滚动事件处理(原始)
     * @param ev
     */
    scrollHandlerOriginal(ev) {
      if (this.isLoading) return;
      clearTimeout(this.debounceTimer);
      if (ev && ev.constructor === Event) {
        this.debounceTimer = setTimeout(
          this.attemptLoad,
          this.debounceDuration,
        );
      } else {
        this.attemptLoad();
      }
    },
    /**
     * 尝试触发加载
     */
    attemptLoad() {
      if (this.isComplete) {
        this.isLoading = false;
        return;
      }
      const currentDistance = this.getCurrentDistance();
      if (
        currentDistance < this.distance &&
        this.$el.offsetWidth + this.$el.offsetHeight > 0
      ) {
        this.isLoading = true;
        this.$emit('infinite', {
          loaded: this.onStateLoaded,
          complete: this.onStateComplete,
          reset: this.onStateReset,
          // isFirstLoad: this.isFirstLoad,
        });
      } else {
        this.isLoading = false;
      }
    },
    /**
     * 获取离可视区距离
     * @returns {Number} distance
     */
    getCurrentDistance() {
      let distance;
      if (this.direction === 'top') {
        distance = isNaN(this.scrollParent.scrollTop)
          ? this.scrollParent.pageYOffset
          : this.scrollParent.scrollTop;
      } else {
        const infiniteElmOffsetTopFromBottom = this.$el.getBoundingClientRect()
          .top;
        const scrollElmOffsetTopFromBottom =
          this.scrollParent === window
            ? window.innerHeight
            : this.scrollParent.getBoundingClientRect().bottom;

        distance =
          infiniteElmOffsetTopFromBottom - scrollElmOffsetTopFromBottom;
      }
      return distance;
    },
    /* ----- 状态变更 ----- */
    onStateLoaded() {
      this.isFirstLoad = false;
      if (this.isLoading) {
        this.$nextTick(() => {
          this.attemptLoad();
        });
      }
    },
    onStateComplete() {
      this.isLoading = false;
      this.isComplete = true;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
      this.scrollParent.removeEventListener(
        'scroll',
        this.scrollHandlerOriginal,
      );
    },
    onStateReset() {
      this.close();

      // 触发第一次加载
      this.$nextTick(() => {
        this.open();
      });
    },
    open() {
      if (this.disabled) return;
      if (this.isFirstLoad && this.loadFirst) {
        this.isLoading = true;
        this.$emit('infinite', {
          loaded: this.onStateLoaded,
          complete: this.onStateComplete,
          reset: this.onStateReset,
          // isFirstLoad: this.isFirstLoad,
        });
      }
      setTimeout(this.scrollHandlerOriginal, 1);
      this.scrollParent.addEventListener('scroll', this.scrollHandlerOriginal);
    },
    close() {
      this.isLoading = false;
      this.isComplete = false;
      this.isFirstLoad = true;
      this.scrollParent.removeEventListener(
        'scroll',
        this.scrollHandlerOriginal,
      );
    },
  },
  mounted() {
    this.scrollParent = this.getScrollParent();

    // 触发第一次加载
    this.open();
  },
};
</script>

<style lang="scss">
.infinite-loading {
  width: 100%;
  > .infinite-status-prompt {
    margin-bottom: 0.3rem;
  }
}
</style>
