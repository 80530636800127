<!-- Created by henian.xu on 2018/9/12. -->

<template>
  <div class="widget-shell">
    <div
      ref="widget"
      v-if="isSupport"
      :is="componentName"
      :style="componentStyles"
      @complete="onWidgetComplete"
      v-bind="componentProps"
    />
    <div
      v-else
      class="ta-c pa-a bc-g2 tc-g6"
    >
      暂不支持 {{ componentName }} 组件
    </div>
  </div>
</template>

<script>
import * as Components from './decoration';

function getMarginValue(data) {
  const value = `${data || ''}`.split(' ').reduce((pre, cur) => {
    if (!cur.trim()) return pre;
    let int = parseFloat(cur);
    if (Number.isNaN(int)) return pre;
    let unit = cur.replace(/(\d)*/g, '').trim();
    if (!unit) {
      unit = 'rem';
      int = int / 100;
    }
    pre.push(int + unit);
    return pre;
  }, []);
  return value.join(' ');
}

export default {
  name: 'Shell',
  components: { ...Components },
  data() {
    return {
      timerId: 0,
      timerCount: 0,
      componentMap: {
        Video: 'XVideo',
      },
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    componentType() {
      return this.data.componentType;
    },
    componentName() {
      const { componentType, componentMap } = this;
      return componentMap[componentType] || componentType;
    },
    isSupport() {
      const { componentName } = this;
      return !!this.$options.components[componentName];
    },
    hasComponentMargin() {
      return this.$utils.Comm.isDef(this.data.componentMargin);
    },
    hasComponentPadding() {
      return this.$utils.Comm.isDef(this.data.componentPadding);
    },
    hasComponentRadius() {
      return this.$utils.Comm.isDef(this.data.componentRadius);
    },
    componentStyles() {
      const res = {};
      if (this.hasComponentMargin) {
        const margin = getMarginValue(this.data.componentMargin);
        if (margin) res.margin = margin;
      }
      if (this.hasComponentPadding) {
        const padding = getMarginValue(this.data.componentPadding);
        if (padding) res.padding = padding;
      }
      return res;
    },
    componentProps() {
      const { data } = this;
      const res = { ...data.props };
      if (this.hasComponentRadius) {
        const componentRadius = getMarginValue(this.data.componentRadius);
        if (componentRadius) res.borderRadius = componentRadius;
      }
      return res;
    },
    isPaginationComponent() {
      if (!this.isSupport) return false;
      switch (this.componentName) {
        case 'GoodsList':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    onWidgetComplete() {
      this.$emit('complete');
    },
    isLoaded() {
      if (!this.isSupport) {
        // 不支持的组件 直接完成
        this.$emit('complete');
        return;
      } else if (this.isPaginationComponent) {
        // 是颁布组件就不在这里处理
        return;
      }
      const { widget } = this.$refs;
      let oldHeight = 0;
      this.timerId = setInterval(() => {
        this.timerCount++;
        if (this.timerCount < 100 && (!widget.$el || !widget.$el.clientHeight))
          return;
        const clientHeight = widget.$el.clientHeight;
        if (oldHeight !== clientHeight) {
          oldHeight = clientHeight;
          return;
        }
        clearInterval(this.timerId);
        this.$emit('complete');
      }, 200);
    },
  },
  mounted() {
    this.isLoaded();
  },
  deactivated() {
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss">
</style>
