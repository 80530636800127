<!-- Created by henian.xu on 2019/10/22. -->

<template>
  <div class="withdraw-item">
    <div class="header">
      <div class="label">
        {{ data.withdrawTypeName }}
      </div>
      <div class="amount price fw-bold">
        {{ data.withdrawAmount }}
      </div>
    </div>
    <div
      class="body"
      v-if="+data.withdrawType === 2"
    >
      <div class="account-num">
        账号: {{ data.accountNum }}
      </div>
      <div class="account-name">
        姓名: {{ data.accountName }}
      </div>
    </div>
    <div class="footer">
      <div class="time">
        {{ data.withdrawTime | moment('YYYY-MM-DD HH:mm:ss') }}
      </div>
      <div class="status badge badge-second fs-small">
        {{ data.withdrawStatusName }}
      </div>
    </div>
    <div
      v-if="data.withdrawStatus === 99"
      class="footer"
    >
      <div class="time tc-red">
        审核不通过: {{ data.auditRemarks }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WithdrawItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.withdraw-item {
  background-color: #fff;
  margin-bottom: $margin;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    > .label {
      font-weight: bold;
    }
  }
  > .body,
  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    border-top: 1px solid $color-border;
  }
  > .body {
    justify-content: flex-start;
    > .account-name {
      margin-left: $margin;
    }
  }
}
</style>
