<!-- Created by henian.xu on 2018/8/21. -->

<template>
  <div
    v-if="type"
    class="quick-navigation"
  >
    <div
      ref="inner"
      :class="['inner',{open:isOpen,move:isMove}]"
      :style="innerStyle"
    >
      <div
        class="header"
        @touchstart="onTouchstart"
        @touchend="onTouchend"
        @mousedown="onTouchstart"
      >
        快速<br>导航
        <em
          v-if="unreadCount"
          class="badge badge-red"
        >{{ unreadCount }}</em>
      </div>
      <div class="body">
        <icon
          class="item"
          icon-cls="fs-more"
          code="&#xf089;"
          label="首页"
          @click="onHome"
          column
        />
        <icon
          v-if="$hasPower(['distributorEntrance:entrance'])"
          class="item"
          icon-cls="fs-more"
          code="&#xf048;"
          label="代理"
          @click="onProxy"
          column
        />
        <!--<icon
                    class="item"
                    icon-cls="fs-more"
                    code="&#xf023;"
                    label="附近门店"
                    @click="onNearby"
                    column
                />-->
        <icon
          v-if="$hasPower(['cart:use'])"
          class="item"
          icon-cls="fs-more"
          code="&#xf08b;"
          label="购物车"
          :mark="cartCount"
          @click="onCart"
          column
        />
        <template v-if="$globalVar.meiQiaEntId">
          <icon
            v-show="meiQiaAllSet"
            class="item"
            icon-cls="fs-more"
            code="&#xf128;"
            label="客服"
            :mark="unreadMsgCount"
            @click="onService"
            column
          />
        </template>
        <icon
          v-else
          class="item"
          icon-cls="fs-more"
          code="&#xf128;"
          label="客服"
          @click="onService"
          column
        />
        <icon
          class="item"
          icon-cls="fs-more"
          code="&#xf08c;"
          label="我的"
          @click="onCenter"
          column
        />
      </div>
    </div>

    <!-- popup -->
    <transition name="show">
      <div
        class="popup"
        v-show="isOpen"
        @click="onHeader"
      />
    </transition>
  </div>
</template>

<script>
import meiQiaMixin from '@/mixins/meiQiaMixin';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';
const $body = document.body;

export default {
  name: 'QuickNavigation',
  mixins: [meiQiaMixin],
  data() {
    return {
      isOpen: false,
      isMove: false,
      currentPageY: 0,
      offsetTop: null,
      startPageY: 0,
    };
  },
  computed: {
    type() {
      const { query, meta } = this.$route;
      if (query.hasOwnProperty('quickNav')) return +query.footerShow || 0;
      else if (meta.hasOwnProperty('quickNav')) return +meta.footerShow || 0;
      return 1;
    },
    $$self() {
      return this.$refs.self;
    },
    selfSize() {
      return {
        width: this.$$self.clientWidth,
        height: this.$$self.clientHeight,
      };
    },
    innerStyle() {
      const { offsetTop } = this;
      if (offsetTop === null) return {};
      return {
        top: `${offsetTop}px`,
        bottom: 'auto',
      };
    },
    unreadCount() {
      return this.unreadMsgCount;
    },
    cartCount() {
      return this.$store.getters['countInfo/cartCount'];
    },
  },
  methods: {
    getToucheData($event) {
      const { touches, changedTouches, timeStamp } = $event;
      const touche = touches
        ? touches.length
          ? touches[0]
          : changedTouches[0]
        : $event;
      return {
        timeStamp: timeStamp || new Date().getTime(),
        pageY: touche.pageY,
      };
    },
    onTouchstart(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isMove = false;
      const { pageY } = this.getToucheData(event);
      this.startPageY = this.currentPageY = pageY;
      if (event.type === 'mousedown') {
        $body.addEventListener('mousemove', this.onTouchmove, false);
        $body.addEventListener('mouseup', this.onTouchend, false);
      } else {
        $body.addEventListener('touchmove', this.onTouchmove, false);
        $body.addEventListener('touchend', this.onTouchend, false);
      }
    },
    onTouchmove(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isMove = true;
      const { pageY } = this.getToucheData(event);
      const offset = this.offsetTop + pageY - this.currentPageY;
      this.offsetTop = offset < 0 ? 0 : offset;
      this.currentPageY = pageY;
    },
    onTouchend(event) {
      event.stopPropagation();
      event.preventDefault();
      const { pageY } = this.getToucheData(event);
      if (!this.isMove || Math.abs(this.startPageY - pageY) < 10) {
        this.isMove = false;
        this.isOpen = !this.isOpen;
      }
      setTimeout(() => {
        this.isMove = false;
      }, 200);
      $body.removeEventListener('mousemove', this.onTouchmove);
      $body.removeEventListener('mouseup', this.onTouchend);
      $body.removeEventListener('touchmove', this.onTouchmove);
      $body.removeEventListener('touchend', this.onTouchend);
    },
    onHeader() {
      if (this.isMove) return;
      this.isOpen = !this.isOpen;
    },
    onHome() {
      this.onHeader();
      this.$router.push('/index');
    },
    onProxy() {
      this.onHeader();
      this.$router.push('/distributor/home');
    },
    onCart() {
      this.onHeader();
      this.$router.push('/cart');
    },
    onNearby() {
      this.onHeader();
      this.$router.push('/store/nearby');
    },
    onCenter() {
      this.onHeader();
      this.$router.push('/buyer/home');
    },
    onService() {
      this.onHeader();
      if (!this.$globalVar.userData.id) {
        this.$router.replace('/buyer/login');
        return;
      }
      if (this.meiQiaAllSet) {
        if (
          !this.$globalVar.meiQiaCustomerService ||
          !this.$globalVar.meiQiaCustomerService.length
        ) {
          MeiQiaControl.showPanel();
          return;
        }
        this.$router.push('/feedback/customerService');
      } else {
        // this.$router.push('/feedback/list');
        window.location.href = this.$globalVar.feedbackUrl;
      }
    },
  },
  mounted() {
    this.offsetTop = this.$refs['inner'].offsetTop;
  },
};
</script>

<style lang="scss" scoped>
.popup {
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
<style lang="scss">
.quick-navigation {
  user-select: none;

  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 3rem;
    z-index: $z-index-7 + 1;
    transform: translateX(100%);
    transition-duration: 0.5s;
    font-size: 0.26rem;

    > .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      white-space: nowrap;
      flex: 0 0 auto;
      background-color: $color-main;
      color: #fff;
      line-height: 1.2;
      vertical-align: middle;
      padding: $padding-small;
      border-top-left-radius: 0.05rem;
      border-bottom-left-radius: 0.05rem;

      > .badge {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }

    > .body {
      flex: 0 0 auto;
      background-color: $gray8;
      padding: $padding-small;
      border-top-left-radius: 0.05rem;
      border-bottom-left-radius: 0.05rem;
      color: $gray4;

      > .item {
        min-width: 4em;
        padding: $padding-small;
      }
    }

    &.move {
      transition-duration: 0s;
    }
    &.open {
      transform: translateX(0);
    }
  }
}
</style>
