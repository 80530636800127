<!-- Created by henian.xu on 2020/1/13. -->

<template>
  <div
    class="notification-box"
  >
    <div
      class="inner"
      :style="styles"
    >
      <div class="icon">
        <XIcon
          content="f088"
        />
      </div>
      <div
        class="scroll-bar"
        ref="scrollBar"
      >
        <div
          ref="inner"
          class="inner"
          :style="innerStyles"
        >
          <div
            class="item"
            :is="item.href?'Linker':'div'"
            :to="item.href"
            v-for="(item) in options"
            :key="item.id"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  data() {
    return {
      isMounted: false,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: String,
      default: '',
    },
  },
  computed: {
    styles() {
      const res = {};
      const { color, backgroundColor, borderRadius } = this;
      if (color) res.color = color;
      if (backgroundColor) res.backgroundColor = backgroundColor;
      if (borderRadius) res.borderRadius = borderRadius;
      return res;
    },
    options() {
      const { data } = this;
      return data.map(item => item);
    },
    innerStyles() {
      const { isMounted } = this;
      if (!isMounted) return {};
      const { scrollBar, inner } = this.$refs;
      const scrollBarWidth = scrollBar.clientWidth;
      const innerWidth = inner.clientWidth;
      if (innerWidth <= scrollBarWidth) return {};
      console.log(scrollBarWidth, innerWidth);
      return {
        animationName: 'notification-ani',
        animationDuration: `${(innerWidth / scrollBarWidth) * 10}s`,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
};
</script>

<style lang="scss">
@keyframes notification-ani {
  0% {
    transform: translateX(90vw);
  }
  100% {
    transform: translateX(-100%);
  }
}
.notification-box {
  //padding: 0 $padding;

  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    //border-radius: $padding-small;

    > .icon {
      flex: 0 0 auto;
      margin-right: $margin-small;
      background-color: $color-main;
      border-radius: 100vw;
      padding: $padding-small;
      color: #fff;
    }
    > .scroll-bar {
      flex: 1;
      overflow: hidden;

      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > .inner {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .item {
          display: block;
          padding: 0 $padding-small;
          white-space: nowrap;
        }
      }
      // 动画
      > .inner {
        /*transform: translateX(90vw);*/
        /*animation: notification-ani 15s linear infinite;*/
      }
    }
  }
}
</style>
