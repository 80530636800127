<!-- Created by henian.xu on 2018/12/7. -->

<template>
  <div class="join-group-popup">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <slot />
    </div>

    <!-- popup -->
    <transition name="bounce">
      <div
        class="popup join-group-popup"
        ref="popup"
        v-show="popupShow"
        @click.self="onSwitch"
      >
        <transition name="show">
          <div
            class="inner"
            v-show="popupShow"
          >
            <div class="body">
              <GroupInfo
                :data="groupBuyData"
                :goods="goods"
                :activity-available="true"
              />
            </div>
            <!--<div class="footer">
                            <div class="btn btn-main">分享拼团</div>
                        </div>-->
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
export default {
  name: 'JoinGroupPopup',
  mixins: [popupMixin],
  data() {
    return {
      appLevel: true,
      groupBuyData: {},
    };
  },
  props: {
    groupBuyId: {
      type: Number,
      required: true,
      default: 0,
    },
    goods: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    groupBuyDetail() {
      return this.groupBuyData.groupBuyDetail || {};
    },
    inited() {
      return this.groupBuyDetail.id || (this.popupShow && this.groupBuyId);
    },
  },
  watch: {
    inited: {
      handler(val) {
        if (!val) return;
        this.getGroupBuyDetail();
      },
      immediate: true,
    },
  },
  methods: {
    getGroupBuyDetail() {
      this.$api.Buyer.Ex.GroupBuy.groupBuyDetail({ id: this.groupBuyId }).then(
        json => {
          const res = json.data.data;
          this.groupBuyData = res;
          console.log(res);
        },
      );
    },
  },
};
</script>

<style lang="scss">
.popup.join-group-popup {
  > .inner {
    background-color: #fff;
    width: 90%;
    max-height: 90%;
    border-radius: 0.1rem;
    overflow: hidden;
  }
}
</style>
