<!-- Created by henian.xu on 2018/9/13. -->

<template>
  <div
    v-if="+type===3"
    class="goods"
    style="padding: 0 0.1rem;"
  >
    <Waterfall
      :watch="goodsList"
    >
      <WaterfallSlot
        v-for="(item,index) in goodsList"
        :key="item.id"
        :order="index"
      >
        <GoodsItem
          :data="item"
          waterfall
        />
      </WaterfallSlot>
    </Waterfall>
  </div>
  <list
    v-else
    class="goods"
  >
    <GoodsItem
      v-for="(item) in goodsList"
      :key="item.id"
      :data="item"
      :double-row="+type === 1"
    />
  </list>
</template>

<script>
export default {
  name: 'Goods',
  data() {
    return {
      goodsList: [],
      innerAdList: [],
      insertedAdList: [],
      insertedIndex: 0,
    };
  },
  props: {
    // 单列:1;双列:2;
    type: {
      type: [Number, String],
      default: 1,
    },
    ids: {
      type: Array,
      default() {
        return [];
      },
    },
    adList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    ids: {
      handler(val) {
        if (!val || !val.length) {
          return;
        }
        this.$api.Buyer.Gd.Goods.goodsList({
          ids: val.join(','),
        }).then(json => {
          const resList = [];
          const dataList = json.data.data;
          dataList.forEach((item, currentIndex) => {
            if (!this.innerAdList.length) {
              this.innerAdList = this.insertedAdList;
              this.insertedAdList = [];
            }
            if (
              currentIndex === this.insertedIndex &&
              this.innerAdList.length
            ) {
              const adData = this.innerAdList.shift();
              this.insertedAdList.push(adData);
              this.insertedIndex =
                currentIndex + this.$utils.Comm.random(10, 15);
              resList.push(adData);
            }
            resList.push(item);
          });
          this.goodsList = resList;
        });
      },
      immediate: true,
    },
    adList: {
      handler(val) {
        this.innerAdList = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
</style>
