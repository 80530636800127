<!-- Created by henian.xu on 2022/1/21. -->

<template>
  <div class="delivery-address-show">
    <div class="stripe" />
    <div class="inner">
      <div class="icon">
        <XIcon content="f0a4" />
      </div>
      <div class="content">
        <div class="header">
          <div class="label">
            <div
              v-if="dataset.isDefault"
              class="badge"
            >
              <div class="badge badge-red">
                默认
              </div>
            </div>
            <span class="name">
              {{ dataset.deliveryConsignee }}
            </span>
            <span class="mobile">
              {{ dataset.deliveryPhone }}
            </span>
          </div>
        </div>
        <div class="address">
          {{ dataset.deliveryRegionName }} {{ dataset.deliveryAddress }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryAddressShow',
  data() {
    return {};
  },
  props: {
    dataset: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.delivery-address-show {
  > .stripe {
    height: 10px;
    $stripeSize: 10px;
    background-image: repeating-linear-gradient(
      -50deg,
      #de706a,
      #de706a $stripeSize,
      #fff $stripeSize,
      #fff $stripeSize * 2,
      #7665a5 $stripeSize * 2,
      #7665a5 $stripeSize * 3,
      #fff $stripeSize * 3,
      #fff $stripeSize * 4
    );
    background-size: 100% 30px;
  }
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: $padding;
    > .icon {
      flex: 0 0 auto;
      margin-right: $margin-small;
      font-size: 110%;
    }
    > .content {
      flex: 1 1 1%;
      > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          color: $color-text-minor;
          > .badge {
            margin-right: $margin-small;
          }
          > .mobile {
            margin-left: $margin-small;
          }
        }
      }
      > .address {
        margin-top: $margin-small;
      }
    }
  }
}
</style>
