/**
 * Created by henian.xu on 2017/11/7.
 *
 */

import Routes from './routes';
import Navigator from './navigator';
import NavComponent from './components';
import { genKey, isObjEqual } from './utils';
import Nprogress from 'nprogress';

export default {
  install(
    Vue,
    { router, store, moduleName = 'navigation', keyName = 'VNK' } = {},
  ) {
    if (!router) {
      throw new Error('navigation components need options: router');
    }

    const bus = new Vue();
    const navigator = Navigator(bus, store, moduleName, keyName);
    // hack vue-router replace for replaceFlag
    const routerReplace = router.replace.bind(router);
    let replaceFlag = false;
    router.replace = (location, onComplete, onAbort) => {
      replaceFlag = true;
      routerReplace(location, onComplete, onAbort);
    };

    // 为url 加上key
    router.beforeEach((to, from, next) => {
      if (!to.query[keyName]) {
        const query = { ...to.query };
        // go to the same route will be set the same key
        if (
          to.path === from.path &&
          isObjEqual(
            { ...to.query, [keyName]: null },
            { ...from.query, [keyName]: null },
          ) &&
          from.query[keyName]
        ) {
          query[keyName] = from.query[keyName];
        } else {
          query[keyName] = genKey();
        }
        // debugger;
        next({
          path: to.path,
          query,
          replace: replaceFlag || !from.query[keyName],
        });
      } else {
        // debugger;
        if (!Nprogress.timeId) {
          Nprogress.timeId = setTimeout(() => {
            Nprogress.start();
          }, 1000);
        }
        next();
      }
    });

    // record router change
    router.afterEach((to, from) => {
      navigator.record(to, from, replaceFlag);
      replaceFlag = false;
      if (Nprogress.timeId) {
        clearTimeout(Nprogress.timeId);
        Nprogress.timeId = 0;
        Nprogress.done();
      }
    });

    Vue.component('navigation', NavComponent(keyName));
    Vue.navigation = Vue.prototype.$navigation = {
      on: (event, callback) => {
        bus.$on(event, callback);
      },
      once: (event, callback) => {
        bus.$once(event, callback);
      },
      off: (event, callback) => {
        bus.$off(event, callback);
      },
      getRoutes() {
        return Routes.slice();
      },
      cleanRoutes() {
        navigator.reset();
      },
    };
  },
};
