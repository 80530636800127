/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';
import { Comm, DataMap } from '@/utils';

class ShopStoreOrderRefund extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStoreOrderRefund';
  }

  /**
   * 订单售后列表
   * @param refundType        售后类型 1订单取消 2订单售后
   * @param refundStatus      售后状态 1待审核 2待打款 3已退款 4已关闭
   * @param currentPage
   * @param pageSize
   * @returns {Promise<any>}
   */
  data({ refundType, refundStatus, currentPage, pageSize } = {}) {
    return super
      .get(`/data`, {
        refundType,
        refundStatus,
        currentPage,
        pageSize,
      })
      .then(json => {
        const res = json.data.data;
        Comm.makeDataMap(res, DataMap.refund);
        return Promise.resolve(json);
      });
  }

  /**
   * 订单售后详情
   * @param id
   * @returns {Promise<any>}
   */
  detail({ id } = {}) {
    return super.get(`/detail`, { id }).then(json => {
      const res = json.data.data;
      Comm.makeDataMap(res.order, DataMap.order);
      return Promise.resolve(json);
    });
  }

  /**
   * 订单售后
   * @param orderId
   * @param orderDetailId
   * @returns {AxiosPromise}
   */
  refund({ orderId, orderDetailId } = {}) {
    return super.get(`/refund`, { orderId, orderDetailId });
  }

  /**
   * 保存订单售后
   * @param orderId           订单ID
   * @param orderDetailId     订单详情ID
   * @param refundAmount      退款金额
   * @param refundQuantity    退款数量
   * @param orderRefundType   退款类型
   * @param refundReason      退款原因
   * @param refundDesc        退款说明
   * @param refundAttachment  退款凭证
   * @returns {AxiosPromise}
   */
  saveRefund({
    orderId,
    orderDetailId,
    refundAmount,
    refundQuantity,
    orderRefundType,
    refundReason,
    refundDesc,
    refundAttachment,
  } = {}) {
    return super.post(`/saveRefund`, {
      orderId,
      orderDetailId,
      refundAmount,
      refundQuantity,
      orderRefundType,
      refundReason,
      refundDesc,
      refundAttachment,
    });
  }

  /**
   * 取消售后申请
   * @param id
   * @returns {AxiosPromise}
   */
  cancelRefund({ id } = {}) {
    return super.post(`/cancelRefund`, { id });
  }

  orderRefundShipping({ id, expressId, trackingNumber } = {}) {
    return super.post(`/orderRefundShipping`, {
      id,
      expressId,
      trackingNumber,
    });
  }

  shopExpressList() {
    return super.get(`/shopExpressList`);
  }
}

export default new ShopStoreOrderRefund();
