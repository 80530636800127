/**
 * Created by henian.xu on 2018/5/30.
 *
 */

const noop = () => {};

class Animate {
  constructor(
    stepCallback = noop,
    completedCallback = noop,
    stopCallback = noop,
  ) {
    // this.dom = dom; // 进行运动的dom 节点
    this.startTime = 0; // 动画开始时间
    this.currPos = 0;
    this.startPos = 0; // 动画开始时，dom 节点的位置，即dom 的初始位置
    this.endPos = 0; // 动画结束时，dom 节点的位置，即dom 的目标位置
    this.easing = null; // 缓动算法
    this.duration = null; // 动画持续时间
    this.timeId = 0; // 动画时间id

    this.stepCallback = stepCallback;
    this.completedCallback = completedCallback;
    this.stopCallback = stopCallback;
  }

  stop() {
    clearInterval(this.timeId);
    this.stopCallback(this.currPos);
  }

  start(startPos, endPos, duration, easing = Animate.easeOutQuart) {
    this.startTime = +new Date(); // 动画启动时间
    this.startPos = startPos; // this.dom.getBoundingClientRect()[propertyName]; // dom 节点初始位置
    this.endPos = endPos; // dom 节点目标位置
    this.duration = duration; // 动画持续事件
    this.easing = easing; // 缓动算法
    this.timeId = setInterval(() => {
      if (this.step() === false) {
        clearInterval(this.timeId);
      }
    }, 10);
  }

  step() {
    const currTime = +new Date();
    if (currTime >= this.startTime + this.duration) {
      // (1)
      this.currPos = this.endPos;
      this.completedCallback(this.endPos); // 修正位置
      return false;
    }
    this.currPos = this.easing(
      currTime - this.startTime,
      this.startPos,
      this.endPos - this.startPos,
      this.duration,
    );
    return this.stepCallback(this.currPos);
  }

  // 缓动方法

  static easeInQuad(t, b, c, d) {
    return c * (t /= d) * t + b;
  }

  static easeOutQuad(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }

  static easeInOutQuad(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
    return (-c / 2) * (--t * (t - 2) - 1) + b;
  }

  static easeInCubic(t, b, c, d) {
    return c * (t /= d) * t * t + b;
  }

  static easeOutCubic(t, b, c, d) {
    return c * ((t = t / d - 1) * t * t + 1) + b;
  }

  static easeInOutCubic(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
    return (c / 2) * ((t -= 2) * t * t + 2) + b;
  }

  static easeInQuart(t, b, c, d) {
    return c * (t /= d) * t * t * t + b;
  }

  static easeOutQuart(t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  }

  static easeInOutQuart(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
    return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
  }

  static easeInQuint(t, b, c, d) {
    return c * (t /= d) * t * t * t * t + b;
  }

  static easeOutQuint(t, b, c, d) {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  }

  static easeInOutQuint(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t * t + b;
    return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
  }

  static easeInSine(t, b, c, d) {
    return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
  }

  static easeOutSine(t, b, c, d) {
    return c * Math.sin((t / d) * (Math.PI / 2)) + b;
  }

  static easeInOutSine(t, b, c, d) {
    return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
  }

  static easeInExpo(t, b, c, d) {
    return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
  }

  static easeOutExpo(t, b, c, d) {
    return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  }

  static easeInOutExpo(t, b, c, d) {
    if (t === 0) return b;
    if (t === d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  }

  static easeInCirc(t, b, c, d) {
    return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
  }

  static easeOutCirc(t, b, c, d) {
    return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
  }

  static easeInOutCirc(t, b, c, d) {
    if ((t /= d / 2) < 1) return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
    return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
  }

  static easeInElastic(t, b, c, d) {
    let s = 1.70158;
    let p = 0;
    let a = c;
    if (t === 0) return b;
    if ((t /= d) === 1) return b + c;
    if (!p) p = d * 0.3;
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    return (
      -(
        a *
        Math.pow(2, 10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p)
      ) + b
    );
  }

  static easeOutElastic(t, b, c, d) {
    let s = 1.70158;
    let p = 0;
    let a = c;
    if (t === 0) return b;
    if ((t /= d) === 1) return b + c;
    if (!p) p = d * 0.3;
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    return (
      a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) +
      c +
      b
    );
  }

  static easeInOutElastic(t, b, c, d) {
    let s = 1.70158;
    let p = 0;
    let a = c;
    if (t === 0) return b;
    if ((t /= d / 2) === 2) return b + c;
    if (!p) p = d * (0.3 * 1.5);
    if (a < Math.abs(c)) {
      a = c;
      s = p / 4;
    } else {
      s = (p / (2 * Math.PI)) * Math.asin(c / a);
    }
    if (t < 1)
      return (
        -0.5 *
          (a *
            Math.pow(2, 10 * (t -= 1)) *
            Math.sin(((t * d - s) * (2 * Math.PI)) / p)) +
        b
      );
    return (
      a *
        Math.pow(2, -10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p) *
        0.5 +
      c +
      b
    );
  }

  static easeInBack(t, b, c, d, s) {
    if (s === undefined) s = 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
  }

  static easeOutBack(t, b, c, d, s) {
    if (s === undefined) s = 1.70158;
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
  }

  static easeInOutBack(t, b, c, d, s) {
    if (s === undefined) s = 1.70158;
    if ((t /= d / 2) < 1)
      return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
    return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
  }

  /* easeInBounce(t, b, c, d) {
        return c - jQuery.easing.easeOutBounce(d - t, 0, c, d) + b;
    } */

  static easeOutBounce(t, b, c, d) {
    if ((t /= d) < 1 / 2.75) {
      return c * (7.5625 * t * t) + b;
    } else if (t < 2 / 2.75) {
      return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
    } else if (t < 2.5 / 2.75) {
      return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
    } else {
      return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
    }
  }

  /* easeInOutBounce(t, b, c, d) {
        if (t < d / 2) return jQuery.easing.easeInBounce(t * 2, 0, c, d) * 0.5 + b;
        return jQuery.easing.easeOutBounce(t * 2 - d, 0, c, d) * 0.5 + c * 0.5 + b;
    } */
}

export default Animate;
