<!-- Created by henian.xu on 2018/5/10. -->
<!-- 售后申请弹框 -->

<template>
  <div class="refund-popup">
    <div
      class="inner"
      @click.stop.prevent="onSwitch"
    >
      <slot />
    </div>

    <!--popup-->
    <transition
      :name="aniName"
      v-if="!delayedCreate"
    >
      <Page
        class="bc-g2"
        ref="popup"
        v-show="popupShow"
      >
        <div class="footer">
          <div
            class="btn btn-main"
            @click="onSubmit"
          >
            提交
          </div>
        </div>
        <container>
          <x-form
            ref="form"
            :model="formData"
            :rules="rules"
          >
            <form-item
              v-if="orderDetailId"
              class="ta-r"
              prop="refundQuantity"
              label="退款数量"
            >
              <!--<x-input
                                type="number"
                                placeholder="请输入"
                                v-model.number="formData.refundQuantity"
                            />-->
              <input-number
                :min="1"
                :max="maxRefundQuantity"
                v-model.number="formData.refundQuantity"
              />
            </form-item>
            <form-item
              class="ta-r"
              prop="refundAmount"
              label="退款金额"
            >
              <x-input
                type="number"
                placeholder="请输入"
                v-model.number="formData.refundAmount"
              />
              <!--<input-number
                                :min="1"
                                :max="maxRefundAmount"
                                v-model.number="formData.refundAmount"
                            />-->
            </form-item>
            <form-item
              v-if="refundType===2"
              class="ta-r"
              prop="orderRefundType"
              label="退款类型"
            >
              <x-select
                class="ta-r"
                v-model="formData.orderRefundType"
              >
                <option value="">
                  请选择
                </option>
                <option
                  v-for="(item) in orderRefundTypeList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </x-select>
            </form-item>
            <form-item
              class="ta-r"
              prop="refundReason"
              label="退款原因"
            >
              <x-select
                class="ta-r"
                v-model="formData.refundReason"
              >
                <option value="">
                  请选择
                </option>
                <option
                  v-for="(item) in refundReasonList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </x-select>
            </form-item>
            <form-item
              class="ta-r"
              prop="refundDesc"
              label="退款说明"
            >
              <x-input
                type="textarea"
                placeholder="请输入"
                :auto-size="{maxRows:4}"
                v-model="formData.refundDesc"
              />
            </form-item>
            <form-item
              v-if="refundType !== 1"
              class="column"
              prop="refundAttachment"
              label="退款凭证"
            >
              <div>
                <div class="tips tc-red">
                  请务必提供快递面单照及快递外包装照片
                </div>
                <upload
                  :size="5*1024*1024"
                  action="/rpc/comm/rs/attachment/uploadFileLibrary"
                  accept="image/*"
                  :max-files="5"
                  :column="3"
                  v-model="formData.refundAttachment"
                />
              </div>
            </form-item>
          </x-form>
        </container>
      </Page>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';

export default {
  name: 'RefundPopup',
  mixins: [popup],
  data() {
    return {
      delayedCreate: true,
      appLevel: true,
      formData: {
        orderId: 0,
        orderDetailId: 0,
        refundAmount: '',
        refundQuantity: '',
        orderRefundType: '',
        refundReason: '',
        refundDesc: '',
        refundAttachment: [],
      },
      rules: {
        refundAmount: [
          {
            required: true,
            message: '退款金额不能为空',
          },
        ],
        refundQuantity: [
          {
            required: true,
            message: '退款数量不能为空',
          },
        ],
        orderRefundType: {
          required: true,
          message: '退款类型不能为空',
        },
        refundReason: {
          required: true,
          message: '退款原因不能为空',
        },
        refundDesc: {
          required: true,
          message: '退款说明不能为空',
        },
        /*refundAttachment: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '退款凭证不能为空',
                },*/
        refundAttachment: {
          validator: this.checkRefundAttachment,
          message: '退款凭证不能为空',
        },
      },
      maxRefundAmount: 0,
      maxRefundQuantity: 0,
      order: {},
      orderRefundTypeList: [],
      refundReasonList: [],
      code: 0,
    };
  },
  props: {
    orderId: {
      type: Number,
      default: 0,
    },
    orderDetailId: {
      type: Number,
      default: undefined,
    },
    isShopStore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    $$form() {
      return this.$refs.form;
    },
    /**
     * 1: 订单级；2: 商品级
     * @returns {number}
     */
    refundType() {
      if (!this.orderDetailId) {
        return 1;
      } else if (!this.orderId) {
        return 2;
      }
      return 0;
    },
    APIClass() {
      return this.isShopStore
        ? this.$api.Buyer.Sp.ShopStoreOrderRefund
        : this.$api.Buyer.Ex.OrderRefund;
    },
  },
  watch: {
    delayedCreate() {
      // if (val) return;
      // this.getData();
    },
    popupShow(val) {
      if (!val) return;
      if (this.code !== 1000) {
        this.getData();
      }
    },
    'formData.refundQuantity'(val) {
      // 最大退款金额 / 最大退款数据 * 退款数量
      const { maxRefundAmount, maxRefundQuantity } = this;
      this.formData.refundAmount = +(
        (+maxRefundAmount / +maxRefundQuantity) *
        +val
      ).toFixed(2);
    },
  },
  methods: {
    getData() {
      return this.APIClass.refund({
        orderId: this.orderId,
        orderDetailId: this.orderDetailId,
      })
        .then(json => {
          this.code = json.data.code;
          const res = json.data.data;
          this.maxRefundAmount = res.maxRefundAmount;
          this.maxRefundQuantity = res.maxRefundQuantity;
          this.order = res.order;
          this.orderRefundTypeList = res.orderRefundTypeList;
          this.refundReasonList = res.refundReasonList;
          // 验证规设定
          this.formData.refundAmount = this.maxRefundAmount || '';
          this.formData.refundQuantity = this.maxRefundQuantity || '';
          this.rules.refundAmount.push({
            type: 'number',
            min: 0.01,
            max: this.maxRefundAmount,
            message: `必须是数值，且 0< 数值 ≤ ${this.maxRefundAmount}`,
          });
          this.rules.refundQuantity.push({
            type: 'number',
            min: 1,
            max: this.maxRefundQuantity,
            message: `必须是整数值，且 0< 数值 ≤ ${this.maxRefundQuantity}`,
          });
        })
        .catch(err => {
          this.code = err.data.code;
        });
    },
    async checkRefundAttachment(rule, value, callback) {
      if (
        this.refundType !== 1 &&
        (!Array.isArray(value) || value.length < 1)
      ) {
        callback(new Error(rule.message));
      }
      callback();
    },
    onSubmit() {
      this.$$form.validate().then(() => {
        const arr = [];
        this.formData.refundAttachment.forEach(item => {
          arr.push(item.name);
        });

        const data = {
          ...this.formData,
          orderId: this.orderId,
          orderDetailId: this.orderDetailId,
          refundAttachment: arr.join(','),
        };
        this.$nprogress.start();
        this.APIClass.saveRefund(data)
          .then(json => {
            const res = json.data;
            this.$nprogress.done();
            this.done({ isAni: false }).then(() => {
              this.$emit('applySuccess');
              this.isAni = true;
              this.$router.push(
                `/refund/detail/${res.data}${
                  this.isShopStore ? '?isShopStore' : ''
                }`,
              );
              this.$messageBox.tips(res.msg);
            });
          })
          .catch(() => {
            this.$nprogress.done();
          });
      });
    },
  },
};
</script>

<style lang="scss">
</style>
