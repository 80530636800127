/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Pt } from '@/api/comm/Pt/Pt';

class PortalInfoConfig extends Pt {
  constructor() {
    super();
    this.baseUrl += '/portalInfoConfig';
  }

  getPortalInfoConfig() {
    return super.get(`/getPortalInfoConfig`);
  }
}

export default new PortalInfoConfig();
