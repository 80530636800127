<!-- Created by henian.xu on 2018/4/20. -->

<template>
  <transition-group name="footer">
    <div
      class="page-footer main"
      :key="1"
      v-if="type===1"
    >
      <linker
        to="/index"
        class="btn"
        v-slot="{isActive}"
      >
        <div class="inner">
          <XIcon :content="isActive?'f08e':'f089'" />
          <div class="label">
            首页
          </div>
        </div>
      </linker>
      <!--<popup-menu
                class="btn">
                <template slot="inner">
                    <XIcon content="f08a" />
                    <div class="label">商品</div>
                </template>
                <menu-item
                    v-for="(item) in catList"
                    :key="item.id"
                    :to="`/goods/list/${item.id}`"
                    :label="item.name"/>
            </popup-menu>-->
      <linker
        :to="$globalVar.isAllowMoreLevel?'/goods/category/0':'/goods/list/0'"
        :class="['btn',{active:isGoodsList}]"
        v-slot="{isActive}"
      >
        <div class="inner">
          <XIcon :content="(isActive||isGoodsList)?'f090':'f08a'" />
          <div class="label">
            商品
          </div>
        </div>
      </linker>
      <linker
        v-if="$hasPower(['groupBuy:use'])"
        to="/goods/list/20"
        class="btn group-buy-icon tc-main"
        v-slot="{isActive}"
      >
        <div class="inner">
          <XIcon :content="isActive?'f047':'f047'" />
          <div class="label">
            拼团
          </div>
        </div>
      </linker>
      <linker
        v-if="$hasPower(['distributorEntrance:entrance'])"
        to="/distributor/home"
        class="btn"
        v-slot="{isActive}"
      >
        <div class="inner">
          <XIcon :content="isActive?'f048':'f048'" />
          <div class="label">
            代理
          </div>
        </div>
      </linker>
      <linker
        v-if="$hasPower(['cart:use'])"
        to="/cart"
        class="btn"
        v-slot="{isActive}"
      >
        <div class="inner">
          <div
            v-if="cartCount"
            class="mark"
          >
            {{ cartCount }}
          </div>
          <XIcon :content="isActive?'f004':'f08b'" />
          <div class="label">
            购物车
          </div>
        </div>
      </linker>
      <!--<linker
                to="/order/list"
                class="btn"
            >
                <div class="inner">
                    <XIcon content="f00b" />
                    <div class="label">订单</div>
                </div>
            </linker>-->
      <linker
        to="/buyer/home"
        class="btn"
        v-slot="{isActive}"
      >
        <div class="inner">
          <XIcon :content="isActive?'f003':'f08c'" />
          <div class="label">
            我的
          </div>
        </div>
      </linker>
    </div>
    <!--分销商页脚-->
    <!--<div
            class="page-footer main"
            :key="2"
            v-else-if="type===2">
            &lt;!&ndash;<popup-menu class="btn">
                <template slot="inner">
                    <i class="f-icon">&#xf08a;</i>
                    <div class="label">商品</div>
                </template>
                <menu-item
                    v-for="(item) in catList"
                    :key="item.id"
                    :to="`/distributor/goods/list/${item.id}`"
                    :label="item.name"/>
            </popup-menu>&ndash;&gt;
            <linker
                to="/distributor/goods/list/0"
                class="btn">
                <div class="inner">
                    <i class="f-icon">&#xf08a;</i>
                    <div class="label">商品</div>
                </div>
            </linker>
            <linker
                to="/distributor/home"
                class="btn">
                <div class="inner">
                    <i class="f-icon">&#xf08c;</i>
                    <div class="label">分销中心</div>
                </div>
            </linker>
        </div>-->
    <!-- 门店 -->
    <StoreFooter
      :key="3"
      v-else-if="type===3"
    />
  </transition-group>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import StoreFooter from '@/pages/store-old/_storeFooter';

export default {
  name: 'FooterMain',
  components: { StoreFooter },
  data() {
    return {
      // catList: [],
    };
  },
  props: {},
  computed: {
    ...mapState('goods', { catList_: 'catList' }),
    ...mapGetters('global', { moduleConfig: 'moduleConfig' }),
    ...mapGetters('countInfo', { cartCount: 'cartCount' }),
    /**
     * 页脚显示规则
     * 0/空、不显示
     * 1、普通全局
     * 2、即时活动
     * @returns {computed.footerShow|number|*}
     */
    type() {
      const { query, meta } = this.$route;
      if (query.hasOwnProperty('footerShow')) return +query.footerShow || 0;
      else if (meta.hasOwnProperty('footerShow')) return +meta.footerShow || 0;
      return 0;
    },
    orderStatus() {
      return this.$route.params.orderStatus || 0;
    },
    catList() {
      const { catList_ } = this;
      if (!catList_) {
        this.getCatList();
        return [];
      }
      return this.catList_;
    },

    isGoodsList() {
      const currentPath = this.$route.path;
      return (
        /^\/goods\/category/.test(currentPath) ||
        /^\/goods\/list/.test(currentPath)
      );
    },
  },
  watch: {
    /* type: {
            handler(val) {
                if (val === 1) this.getCatList();
            },
            immediate: true,
        }, */
  },
  methods: {
    ...mapActions('goods', ['getCatList']),
  },
};
</script>

<style lang="scss" scoped>
.group-buy-icon {
  > .inner {
    &:before {
      @include make-icon;
      content: '\f000';
      font-size: 0.4rem;
    }
    > .f-icon {
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      bottom: 0.3rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-main;
      border: 0.1rem solid #fff;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-shadow: 0 -0.04rem 0.2rem -0.12rem rgba(0, 0, 0, 0.2);
    }
    > .label {
      position: relative;
      z-index: 10;
    }
  }
}
</style>
<style lang="scss">
.page-footer.main {
  background-color: #fff;
  //border-top: 1px solid $color-border;
  box-shadow: 0 -0.04rem 0.2rem -0.12rem rgba(0, 0, 0, 0.2);

  > .btn {
    color: $gray6;
    > .inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > .f-icon {
        flex: 1 1 auto;
        margin: 0;
        font-size: 0.5rem;
      }
      > .label {
        flex: 0 0 auto;
        font-size: 0.22rem;
        margin-top: 5px;
        line-height: 1;
      }
      > .mark {
        font-size: 75%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        background-color: $color-red;
        color: #fff;
        line-height: 1.5;
        min-width: 1.5em;
        text-align: center;
        border-radius: 100rem;
      }
    }
    &.active,
    &.exact-active {
      color: $color-main;
    }
    &.self-flex {
      flex: 0 0 auto;
      min-width: 1rem;
    }
  }

  > .desc {
    flex: 1 1 1%;
    padding: 0 $padding;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  &.gray {
    background-color: $gray8;
    border-top: 0;
    > .btn {
      > .inner {
        color: $gray2;
      }
    }
  }
}
</style>
