/**
 * Created by henian.xu on 2018/9/11.
 * buyer 基类 继承用
 */
import HttpBase from '@/api/base/HttpBase';

class Buyer extends HttpBase {
  constructor() {
    super();
    this.baseUrl += '/rpc/buyer';
  }
}

export default new Buyer();
export { Buyer };
