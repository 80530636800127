<!-- Created by henian.xu on 2018/4/18. -->

<template>
  <div 
    :to="`/distributor/detail/${data.buyerId}`" 
    class="buyer-item"
  >
    <div class="header">
      <div class="name">
        {{ data.name }}
      </div>
      <div class="mobile">
        {{ data.mobile }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyerItem',
  data() {
    return {
      countdown: {},
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.buyer-item {
  display: block;
  padding: $padding;
  background-color: #fff;
  margin-top: $margin;
  &:first-child {
    margin-top: 0;
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small 0;
  }
  > .body {
    padding: $padding-small 0;
  }
  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small 0;
  }
}
</style>
