/**
 * Created by henian.xu on 2018/1/10.
 * 公用工具类库
 */
import Url from 'url';
import { Buyer } from '@/api';
import GlobalVar from 'globalVar';
import Vue from 'vue';
import QRCode from 'qrcode';
import Nprogress from 'nprogress';
import weixin from '@/custom/weixin';

class Utils {
  constructor() {
    this.autoIncrement = 0;
    this.hasOwnProperty = Object.prototype.hasOwnProperty;
  }

  /**
   * 空函数
   */
  emptyFn() {}

  mandatory(name) {
    throw new Error(`Missing parameter ${name}`);
  }

  /**
   * 验证参数必填
   * @param name
   * @param parameter
   */
  /* eslint-disable no-unused-vars */
  required(name, parameter = this.mandatory(name)) {}

  /**
   * 创建不重复的 Id
   * @param prefix
   * @returns {string}
   */
  getUniqueId(prefix = '') {
    this.autoIncrement++;
    const cDate = new Date().getTime();
    const offDate = new Date(2010, 1, 1).getTime();
    const offset = cDate - offDate;
    return prefix + parseFloat(offset + '').toString(16) + this.autoIncrement;
  }

  /**
   * 创建不重复Key
   * @returns {string}
   */
  getKey() {
    // const t  = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    const t = 'xxxxxxxx';
    return t.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /**
   * 判断是否为对象属性
   * @param pattern
   * @param name
   * @returns {boolean}
   */
  matches(pattern, name) {
    if (Array.isArray(pattern)) {
      return pattern.indexOf(name) > -1;
    } else if (typeof pattern === 'string') {
      return pattern.split(',').indexOf(name) > -1;
    } else if (Object.prototype.toString.call(pattern) === '[object RegExp]') {
      return pattern.test(name);
    }
    return false;
  }

  /**
   * 获取页面中的 popup-wrap
   * @returns {Element}
   */
  getPopupWrap(page) {
    let r = Array.prototype.indexOf.call(page.$el.classList || [], 'page');
    let r2 = Array.prototype.indexOf.call(page.$el.classList || [], 'pages');
    while (r === -1 && r2 === -1) {
      page = page.$parent;
      r = Array.prototype.indexOf.call(page.$el.classList, 'page');
      r2 = Array.prototype.indexOf.call(page.$el.classList, 'pages');
    }
    page = page.$el;
    let popupWrap = page.querySelector('.popup-wrap');
    if (!popupWrap) {
      popupWrap = document.createElement('div');
      popupWrap.classList.add('popup-wrap');
      page.appendChild(popupWrap);
    }
    return popupWrap;
  }

  /**
   * 加载图片
   * @param src
   * @returns {Promise<any>}
   */
  loadImg(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      // 当为线上图片时，需要设置 crossOrigin 属性；
      if (src.indexOf('http') === 0) img.crossOrigin = '*';
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(img);
      };
      img.src = src;
    });
  }

  loadImgs(srcs) {
    const lng = srcs.length;
    let count = 0;
    const imgs = [];
    return new Promise((resolve, reject) => {
      srcs.forEach((item, index) => {
        this.loadImg(item)
          .then(img => {
            ++count;
            imgs[index] = img;
            if (count >= lng) {
              resolve(imgs);
            }
          })
          .catch(_err => {
            reject(_err);
          });
      });
    });
  }

  /**
   * 获取剩余时间
   * @param startTime
   * @param endTime
   * @returns {{d: number, h: number, m: number, s: number}}
   */
  getRemainTime(startTime, endTime) {
    startTime = new Date(startTime);
    endTime = new Date(endTime);
    const t = endTime.getTime() - startTime.getTime();
    const r = { d: 0, h: 0, m: 0, s: 0 };
    if (t > 0) {
      r.d = Math.floor(t / 1000 / 3600 / 24);
      r.h = Math.floor((t / 1000 / 60 / 60) % 24);
      r.m = Math.floor((t / 1000 / 60) % 60);
      r.s = Math.floor((t / 1000) % 60);
    }
    for (const k in r) {
      if (r.hasOwnProperty(k)) {
        r[k] = r[k] < 10 ? `0${r[k]}` : `${r[k]}`;
      }
    }
    return r;
  }

  /**
   * 格式化时间
   * @param time
   * @returns {{d: number, h: number, m: number, s: number}}
   */
  fixedTime(time) {
    const t = +time;
    if (isNaN(time)) return {};
    const r = { d: 0, h: 0, m: 0, s: 0 };
    if (t > 0) {
      r.d = Math.floor(t / 1000 / 3600 / 24);
      r.h = Math.floor((t / 1000 / 60 / 60) % 24);
      r.m = Math.floor((t / 1000 / 60) % 60);
      r.s = Math.floor((t / 1000) % 60);
    }
    for (const k in r) {
      if (r.hasOwnProperty(k)) {
        r[k] = r[k] < 10 ? `0${r[k]}` : `${r[k]}`;
      }
    }
    return r;
  }

  /**
   * 根据路径取对象值
   * @param obj
   * @param path
   * @param strict
   * @returns {{o: *, k: *|string, v: null}}
   */
  getPropByPath(obj, path = '', strict) {
    let tempObj = obj;
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');

    const keyArr = path.split('.');
    let i = 0;
    for (let len = keyArr.length; i < len - 1; ++i) {
      if (!tempObj && !strict) break;
      const key = keyArr[i];
      if (key in tempObj) {
        tempObj = tempObj[key];
      } else {
        if (strict) {
          throw new Error('please transfer a valid prop path to form item!');
        }
        break;
      }
    }
    return {
      o: tempObj,
      k: keyArr[i],
      v: tempObj ? tempObj[keyArr[i]] : null,
    };
  }

  /**
   * 是否对象属性
   * @param obj
   * @param key
   * @returns {boolean | *}
   */
  hasOwn(obj, key) {
    return this.hasOwnProperty.call(obj, key);
  }

  /**
   * 是否 VNode
   * @param node
   * @returns {boolean|boolean|*}
   */
  isVNode(node) {
    return (
      node !== null &&
      typeof node === 'object' &&
      this.hasOwn(node, 'componentOptions')
    );
  }

  isDef(val) {
    return val !== undefined;
  }

  /**
   * 数值格式化
   * @param value
   * @param length
   * @param strict
   * @returns {*}
   */
  filterNumber(value, length = 2, strict = false) {
    if (value === null || value === undefined) return '';
    let numberList;
    if (isNaN(value)) {
      numberList = (value + '').split('-');
    } else {
      numberList = [+value];
    }
    // console.log(numberList);
    return numberList
      .reduce((pre, cur) => {
        let item = '';
        if (!isNaN(cur) && cur !== '') {
          // throw new Error('价格格式化的 value 格式出错');
          item = (+cur).toFixed(length);
          if (!strict) {
            item = +item;
          }
        }
        pre.push(item);
        return pre;
      }, [])
      .join('-');
  }
  filterNumberOld(value, length = 2) {
    if (isNaN(value)) {
      const nArr = (value + '').split('-');
      if (nArr.length === 1) return value;
      const rArr = [];
      nArr.forEach(item => {
        rArr.push(isNaN(item) ? item : +Number(item).toFixed(length));
      });
      return rArr.join('-');
    } else {
      return +Number(value).toFixed(length);
    }
  }

  arrayFlatten(array, childrenKey) {
    return array.reduce((pre, cur) => {
      let children;
      if (childrenKey) {
        const child = cur[childrenKey];
        children =
          child && child.length ? this.arrayFlatten(child, childrenKey) : [];
      }
      return pre.concat(
        Array.isArray(cur) ? this.arrayFlatten(cur, childrenKey) : cur,
        children,
      );
    }, []);
  }

  makeDataMap(data, map) {
    if (Array.isArray(data)) {
      for (let i = 0, l = data.length; i < l; i++) {
        const item = data[i];
        this.makeDataMap(item, map);
      }
    } else {
      for (const key in map) {
        if (map.hasOwnProperty(key)) {
          const keyData = map[key];
          let rawData;
          if (keyData.key) {
            rawData = data[keyData.key];
          } else {
            rawData = data[keyData];
          }
          if (rawData === undefined) continue;
          data[key] = rawData;
          if (rawData === null) continue;
          if (Array.isArray(rawData)) {
            for (let i = 0, l = data[key].length; i < l; i++) {
              const item = data[key][i];
              this.makeDataMap(item, keyData.children);
            }
          } else {
            this.makeDataMap(data[key], keyData.children);
          }
        }
      }
    }
  }

  /**
   * 微信支付方法（暂放这里）
   * @param orderIds
   * @param payType       支付类型 allinPay / alphaPay
   * @param payModule     支付模块 1:order;2:prepaid;3:distributor
   * @returns {Promise<any>}
   */
  payment(orderIds, payType, payModule = 1) {
    // TODO 支付方法过长过大 应该改造重构
    let API = Buyer.Ex.Order;
    switch (payModule) {
      case 2:
        API = Buyer.Mb.PrepaidCard;
        break;
      case 3:
        API = Buyer.Mb.Distributor;
        break;
    }
    let payforWeixin = 'payforWeixin';
    let payforWeixinByScanCode = 'payforWeixinByScanCode';
    if (payType === 'allinPay') {
      payforWeixin = 'payforAllinPayWeixin';
      payforWeixinByScanCode = 'payforAllinPayWeixinByScanCode';
    } else if (payType === 'alphaPay') {
      payforWeixin = '';
      payforWeixinByScanCode = 'payforAlphaPayWeixinByScanCode';
    }
    return new Promise((resolve, reject) => {
      if (GlobalVar.device.isWeiXin) {
        API[payforWeixin]({ orderIds }).then(json => {
          const res = json.data.data;
          weixin.chooseWXPay(res.payInfo).then(() => {
            // this.$router.replace(`/order/list/20`);
            resolve(res);
          });
        });
      } else {
        Nprogress.start();
        API[payforWeixinByScanCode]({ orderIds })
          .then(json => {
            const res = json.data.data;
            QRCode.toDataURL(
              res.QRCodeUrl,
              {
                margin: 2,
                scale: 30,
                errorCorrectionLevel: 'H',
              },
              (error, url) => {
                Nprogress.done();
                if (error) reject(error);
                Vue.prototype.$messageBox
                  .confirm(
                    `<div class="ta-c">
                                            <div>请用微信扫一扫支付订单<br>支付成功后可点击下方【已支付】<br>按钮完成本次支付</div>
                                        </div>
                                        <img src="${url}" width="100%"/>
                                        <div class="ta-c">订单金额：<span class="price">${
                                          res.paymentAmount
                                        }</span></div>
                                        `,
                    '微信支付',
                    {
                      isClickOtherClose: false,
                      confirmBtnText: '已支付',
                      validCallback(action) {
                        return new Promise((resolve, reject) => {
                          if (action !== 'confirm') return resolve(true);
                          API.isPay({
                            paymentId: res.paymentId,
                          }).then(json2 => {
                            const res2 = json2.data;
                            if (res2.success) {
                              resolve(true);
                            }
                            const obj = {
                              msg: `<div class="tc-red">${res2.msg}</div>`,
                            };
                            reject(obj);
                          });
                        });
                      },
                    },
                  )
                  .then(action => {
                    resolve(res);
                  })
                  .catch(_err => {
                    reject(_err);
                  });
              },
            );
          })
          .catch(() => {
            Nprogress.done();
          });
        /*} else {
                console.log('只能在微信端或pc端可用');*/
      }
    });
  }

  /**
   *
   * @param orderIds
   * @param payModule     支付模块 1:order;2:prepaid;3:distributor
   * @returns {Promise<any>}
   */
  alipayPayment(orderIds, payModule = 1) {
    // TODO 支付方法过长过大 应该改造重构
    let API = Buyer.Ex.Order;
    switch (payModule) {
      case 2:
        API = Buyer.Mb.PrepaidCard;
        break;
      case 3:
        API = Buyer.Mb.Distributor;
        break;
    }
    let payforAlipayByScanCode = 'payforAlphaPayAlipayByScanCode';

    return new Promise((resolve, reject) => {
      Nprogress.start();
      API[payforAlipayByScanCode]({ orderIds })
        .then(json => {
          const res = json.data.data;
          QRCode.toDataURL(
            res.QRCodeUrl,
            {
              margin: 2,
              scale: 30,
              errorCorrectionLevel: 'H',
            },
            (error, url) => {
              Nprogress.done();
              if (error) reject(error);
              Vue.prototype.$messageBox
                .confirm(
                  `<div class="ta-c">
                                            <div>请用支付宝扫一扫支付订单<br>支付成功后可点击下方【已支付】<br>按钮完成本次支付</div>
                                        </div>
                                        <img src="${url}" width="100%"/>
                                        <div class="ta-c">订单金额：<span class="price">${
                                          res.paymentAmount
                                        }</span></div>
                                        `,
                  '支付宝支付',
                  {
                    isClickOtherClose: false,
                    confirmBtnText: '已支付',
                    validCallback(action) {
                      return new Promise((resolve, reject) => {
                        if (action !== 'confirm') return resolve(true);
                        API.isPay({
                          paymentId: res.paymentId,
                        }).then(json2 => {
                          const res2 = json2.data;
                          if (res2.success) {
                            resolve(true);
                          }
                          const obj = {
                            msg: `<div class="tc-red">${res2.msg}</div>`,
                          };
                          reject(obj);
                        });
                      });
                    },
                  },
                )
                .then(action => {
                  resolve(res);
                })
                .catch(_err => {
                  reject(_err);
                });
            },
          );
        })
        .catch(() => {
          Nprogress.done();
        });
    });
  }

  /**
   * 去抖
   * @param fun
   * @param delay
   * @returns {Function}
   */
  debounce(fun, delay = 200) {
    return function(...args) {
      const self = this;
      const _args = args;
      // 每次事件被触发，都会清除当前的timeer,然后重写设置超时调用
      clearTimeout(fun.timeId);
      fun.timeId = setTimeout(() => {
        fun.apply(self, _args);
      }, delay);
    };
  }

  getSarch(url = '', key) {
    // if (!key) return;
    const uri = Url.parse(url);
    const query = {};
    if (uri.query) {
      const queryArr = uri.query.split('&');
      for (let i = 0; i < queryArr.length; i++) {
        const item = queryArr[i];
        const itemArr = item.split('=');
        query[itemArr[0]] = itemArr.length > 1 ? itemArr[1] : '';
      }
    }
    return key ? query[key] : query;
  }

  // 创建二维码
  creatQRCode(text) {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(
        text || 'QRCodeUrl 为空',
        {
          margin: 2,
          scale: 10,
          errorCorrectionLevel: 'H',
        },
        (error, url) => (error ? reject(error) : resolve(url)),
      );
    });
  }
  mergeTracking(list) {
    const iconMap = {
      0: {
        icon: '&#xf07c',
        label: '物流跟踪信息',
      },
      1: {
        icon: '&#xf079',
        label: '清关跟踪信息',
      },
      2: {
        icon: '&#xf07a',
        label: '国际跟踪信息',
      },
    };
    return list.reduce((pre, cur, index) => {
      let tracking = cur || [];
      if (typeof tracking === 'string') {
        tracking = JSON.parse(tracking);
      }
      tracking = tracking.map((item, n) => {
        const obj = { ...item };
        if (!n) {
          obj.icon = iconMap[index].icon;
          obj.label = iconMap[index].label;
        }
        return obj;
      });
      return pre.concat(tracking);
    }, []);
  }

  dataURLtoBlob(dataurl, fileName = '') {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });
    if (fileName) {
      // ios 某些版本不支持 new File
      // return new File([u8arr], fileName, { type: mime });
      blob.name = fileName;
      blob.lastModifiedDate = new Date();
    }
    return blob;
  }

  createObjectURL(obj) {
    return window.URL.createObjectURL(obj);
  }

  random(min, max) {
    return parseInt(Math.random() * (max - min + 1) + min, 10);
  }
}

export default new Utils();
