<!-- Created by henian.xu on 2018/5/9. -->

<template>
  <div class="coupon-picker">
    <div
      class="inner"
      @click="onSwitch"
    >
      <div
        class="label"
        v-if="label"
      >
        {{ label }}
      </div>
      <slot name="extra">
        <div class="extra">
          <div v-if="!model.id && available">
            <div class="badge badge-red plane">
              有 {{ available }} 张可用
            </div>
            <span class="tc-red">&nbsp;去使用&nbsp;</span>
          </div>
          <div
            v-else-if="available==0"
            class="details"
          >
            无可用
          </div>
          <div v-else>
            - <span class="price">{{ model.discountAmount }}</span>
          </div>
        </div>
      </slot>
    </div>
    <!--popup-->
    <transition :name="aniName">
      <Page
        class="bc-g2"
        v-show="popupShow"
        ref="popup"
      >
        <query-box
          class="second-header"
          top=".89rem"
          icon="&#xf00d;"
          placeholder="请输入优惠码"
          show-search-btn
          search-btn-str="兑换"
          v-model="couponCodes"
          @query="onConvertCoupon"
        />
        <div
          class="second-header"
          @click="onSecondHeader"
        >
          <div class="label">
            不使用优惠券
          </div>
          <checkbox
            type="radio"
            :val="0"
            :stop-propagation="false"
            v-model="currChecked"
          />
        </div>
        <container>
          <list class="pa-a">
            <coupon-item
              v-for="(item) in listData"
              :key="item.id"
              :data="item"
              v-model="currChecked"
              is-select
              @click="onItem(item)"
            />
          </list>
          <!--<infinite-loading
                        ref="infinite"
                        :disabled="infiniteDisabled"
                        @infinite="onInfinite"
                    />-->
        </container>
      </Page>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'CouponPicker',
  mixins: [popup, infiniteLoading],
  data() {
    return {
      appLevel: true,
      listData: [],
      currChecked: 0,
      infiniteDisabled: true,
      couponCodes: '',
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default: '',
    },
    uuid: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    available() {
      return this.listData.reduce(
        (p, c) => ((p += c.isAvailable ? 1 : 0), p),
        0,
      );
    },
  },
  watch: {
    /*popupShow(val) {
            if (val) {
                this.listData = [];
                this.pagination = {};
                this.infiniteDisabled = false;
            } else {
                this.infiniteDisabled = true;
            }
        },*/
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Mb.Coupon.orderCouponList({
        uuid: this.uuid,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        console.log(res);
        res.data.forEach(item => {
          item.checked = 0;
        });
        this.listData = res.data;
        return Promise.resolve(json);
      });
    },
    onItem(item) {
      if (!item.isAvailable) return;
      this.currChecked = item.id;
      this.model = item;
      this.popupShow = false;
    },
    onSecondHeader() {
      this.currChecked = 0;
      this.model = {};
      this.popupShow = false;
    },
    refreshData() {
      // this.infiniteDisabled = true;
      // this.pagination = {};
      // this.listData = [];
      // this.$nextTick(() => (this.infiniteDisabled = false));
      this.getListData();
    },
    onConvertCoupon() {
      const { couponCodes } = this;
      if (!couponCodes) return;
      console.log(couponCodes);
      this.$api.Buyer.Mb.Coupon.receiveCoupon({
        couponCodes,
      }).then(json => {
        const result = json.data;
        if (!result.success) {
          this.$messageBox.tips(result.msg, 3000);
          return;
        }
        this.$messageBox
          .alert('<div class="ta-c">领取成功</div>', '领券成功')
          .then(() => {
            this.refreshData();
          });
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss" scoped>
.second-header {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
}
</style>
<style lang="scss">
.coupon-picker {
  flex: 1 1 auto;
  padding: $padding 0;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
