<!-- Created by henian.xu on 2019/10/17. -->

<template>
  <div
    :class="classes"
    :style="styles"
    v-bind="linkerProps"
  />
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'Avatar',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 80,
    },
    radius: {
      type: [Boolean, String],
      default: '',
    },
    border: {
      type: [Boolean, String],
      default: '',
    },
  },
  computed: {
    classes() {
      const { radius, border } = this;
      console.log(border);
      return ['avatar', radius, { radius }, { border }];
    },
    size_() {
      const { size } = this;
      const int = parseInt(size);
      if (!int) return '';
      if (!+size) return size;
      return int + 'px';
    },
    styles() {
      const res = {};
      const { size_, src, border } = this;
      if (typeof border === 'string') {
        res.border = border;
      }
      if (size_) {
        res.width = size_;
        res.height = size_;
      }
      res.backgroundImage = `url(${src})`;
      return res;
    },
  },
};
</script>

<style lang="scss">
.avatar {
  display: inline-block;
  background: transparent center center no-repeat;
  background-size: cover;
  &.radius {
    border-radius: $padding-small;
    &.all {
      border-radius: 100vw;
    }
  }
  &.border {
    border: 0.05rem solid #fff;
  }
}
</style>
