<!-- Created by henian.xu on 2018/5/2. -->

<template>
  <div
    :class="['buy-popup',{'bc-g6 tc-w':disabled}]"
    @click.stop.prevent="onSwitch_"
  >
    <slot>
      <template v-if="type==='cart'">
        加入购物车
      </template>
      <template v-if="type==='buy'">
        立即购买
      </template>
    </slot>
    <transition
      name="show"
    >
      <div
        class="buy popup"
        ref="popup"
        v-show="popupShow"
        @click.self="onSwitch_"
      >
        <transition
          name="popup-down"
        >
          <div
            class="inner"
            v-show="popupShow"
          >
            <div
              class="close"
              @click="onSwitch_"
            >
              <i class="f-icon">&#xf01a;</i>
            </div>
            <div class="header">
              <div class="goods">
                <div
                  class="img-wrap"
                  :style="`background-image: url('${$options.filters.imgCropping(spuImg||data.img||$defaultImg,{width:200})}');`"
                />
                <div class="inner">
                  <!--                  <div class="name">{{ data.name }}</div>-->
                  <div class="extra">
                    <div class="price fs-more">
                      {{ selectedSku.price||data.price | price }}
                      <span
                        v-if="data.marketPrice"
                        class="old"
                      >{{ data.marketPrice | price }}</span>
                    </div>
                    <!--<div
                                            class="stock"
                                            v-if="data.showStock"
                                        >
                                            库存: {{ selectedSku.showStock === undefined ? data.showStock : selectedSku.showStock }}
                                        </div>-->

                    <div class="fs-small tc-g6">
                      进口税: 商品已包含
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="body">
              <spu-picker
                v-model="selectedSku"
                @spuImgChange="onSpuImgChange"
                :data="spu"
              />
              <div class="buy-count">
                <div class="label">
                  数量
                </div>
                <input-number
                  :min="minBuyQuantity"
                  :max="maxBuyQuantity"
                  v-model.number="buyQuantity"
                  :disabled="!maxBuyQuantity"
                  @plusdisabled="onPlusdisabled"
                />
              </div>
            </div>
            <div class="footer pa-l pa-r pa-ts pa-bs">
              <div
                class="btn btn-main radius all"
                v-if="selectedSku.id"
                @click="onNext"
              >
                {{ type==='cart'?'完成':'下一步' }}
              </div>
              <div
                class="btn btn-g5"
                v-else
              >
                {{ type==='cart'?'完成':'下一步' }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';

export default {
  name: 'BuyPopup',
  mixins: [popup],
  data() {
    return {
      appLevel: true,
      selectedSku: {},
      spuImg: '',
      buyQuantity: 1,
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: 'buy',
    },
    orderType: {
      type: [String, Number],
      default: 100, // 100:普通商品; 20:拼团
    },
    groupBuyId: {
      type: Number,
      default: 0,
    },
    tipsMsg: {
      type: String,
      default: '',
    },
  },
  computed: {
    spu() {
      const spu = {
        isSpec: this.data.isSpec,
        sku: this.data.sku,
        specs: this.data.specConfigs,
        skuMap: this.data.skuMap,
      };
      return spu;
    },
    maxBuyQuantity() {
      if (!this.selectedSku.id) return NaN;
      let res = NaN;
      const stock = this.selectedSku.showStock || this.selectedSku.stock;
      if (
        stock < this.data.maxPerOrderQuantity ||
        !this.data.maxPerOrderQuantity
      ) {
        // this.tips = '已超过最大库存数量!';
        res = stock;
      } else {
        // this.tips = '已超过最大购买数量!';
        res = this.data.maxPerOrderQuantity;
      }
      return res;
    },
    tips() {
      const stock = this.selectedSku.showStock || this.selectedSku.stock;
      if (
        stock < this.data.maxPerOrderQuantity ||
        !this.data.maxPerOrderQuantity
      ) {
        return '已超过最大库存数量!';
      }
      return '已超过最大购买数量!';
    },
    minBuyQuantity() {
      return this.data.minPerOrderQuantity || 1;
    },
  },
  methods: {
    onSwitch_() {
      if (this.disabled && this.tipsMsg && !this.popupShow) {
        this.$messageBox.tips(this.tipsMsg);
      }
      this.onSwitch();
    },
    onNext() {
      if (this.type === 'cart') {
        this.addCart();
      } else {
        this.$api.Buyer.Ex.Order.buyNowConfirm({
          orderType: this.orderType,
          groupBuyId: this.groupBuyId,
          skuId: this.selectedSku.id,
          quantity: this.buyQuantity,
        }).then(json => {
          const res = json.data.data;
          // this.popupShow = false;
          this.done().then(() => {
            this.$router.push(`/order/new/${res.uuid}`);
          });
        });
      }
    },
    addCart() {
      this.$api.Buyer.Mb.Cart.addSkuToCart({
        skuId: this.selectedSku.id,
        quantity: this.buyQuantity,
      }).then(json => {
        this.$store.dispatch('countInfo/updated');
        this.$store.dispatch('cart/updated');
        const res = json.data;
        this.done().then(() => {
          this.$messageBox.tips(res.msg, 2000);
        });
      });
    },
    onPlusdisabled() {
      this.$messageBox.tips(this.tips, 2000);
    },
    onSpuImgChange(val) {
      this.spuImg = val;
    },
  },
};
</script>

<style lang="scss">
.buy.popup {
  z-index: $z-index-7 + 10;
  justify-content: flex-end;
  align-items: stretch;
  > .inner {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-top-left-radius: $padding-small;
    border-top-right-radius: $padding-small;

    > .close {
      position: absolute;
      top: $padding;
      right: $padding;
      color: $gray4;
      font-size: 120%;
    }

    > .header {
      line-height: $line-height;
      > .goods {
        flex: 1 1 1%;
        background-color: #fff;
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .img-wrap {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          flex: 0 0 auto;
          width: (200/750 * 100vw);
          height: (200/750 * 100vw);
          max-width: 2rem;
          max-height: 2rem;
          margin-right: $margin;
          border-radius: 0.05rem;
          overflow: hidden;
        }
        > .inner {
          flex: 1 1 1%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          > .name {
            /*flex: 0 0 auto;*/
            @include text-line(2);
            font-size: 0.3rem;
            line-height: 1.2;
            color: #000;
          }
          > .extra {
            /*font-size: .2rem;*/
            /*display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            > span {
              display: inline-block;
              + span {
                margin-left: $margin-small;
              }
            }*/
          }
        }
      }
    }
    > .body {
      > .spu-picker {
        border-bottom: 1px solid $color-border;
      }
      > .buy-count {
        padding: $padding $padding-small;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
