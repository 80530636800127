<!-- Created by henian.xu on 2019/10/24. -->

<template>
  <div
    :class="selfClasses"
    :style="selfStyles"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <img
      v-if="!isRatio"
      v-lazy="$options.filters.imgCropping(src || $defaultImg, cut)"
      :alt="alt"
      :style="imgStyles"
    >
    <!-- 有设长宽比时需要嵌套一层 -->
    <div
      v-else
      :class="innerClasses"
      :style="innerStyles"
    >
      <img
        v-lazy="$options.filters.imgCropping(src || $defaultImg, cut)"
        :alt="alt"
        :style="imgStyles"
      >
    </div>
  </div>
</template>

<script>
import linkerMixin from 'packages/mixins/linkerMixin';

export default {
  name: 'XImage',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: undefined,
    },
    // 确定图片如何适应容器框，同原生 object-fit
    fit: {
      type: String,
      default: 'fill',
      validator(val) {
        return /^(fill|contain|cover|none|scale-down)$/.test(val);
      },
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    // 圆角
    radius: {
      type: [Boolean, String],
      default: false,
      validator(val) {
        return !(typeof val === 'string' && val !== 'all');
      },
    },
    // 值必须是大于 0 的数值, 设置此值后组件的 display 为 block
    aspectRatio: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0;
      },
    },
    cut: {
      type: [String, Object],
      default: 'normal',
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRatio() {
      return !!this.aspectRatio;
    },
    selfClasses() {
      const { isRatio, radius } = this;
      return ['x-image', radius, { radius }, { ratio: isRatio }];
    },
    selfStyles() {
      const { width, height, aspectRatio } = this;
      const w = this.$utils.transformCssValue(width) || undefined;
      const h = aspectRatio
        ? undefined
        : this.$utils.transformCssValue(height) || undefined;
      return {
        width: w,
        height: h,
      };
    },
    innerClasses() {
      // const { isRatio } = this;
      return ['inner' /* , { ratio: isRatio } */];
    },
    innerStyles() {
      const { aspectRatio } = this;
      if (!aspectRatio) return {};
      return {
        paddingTop: `${aspectRatio * 100}%`,
      };
    },
    imgStyles() {
      const { fit: objectFit, width, height, aspectRatio } = this;
      const res = {};
      if (height && !width && !aspectRatio) {
        res.width = 'auto';
      }
      if (objectFit === 'fill') return res;
      res.objectFit = objectFit;
      return res;
    },
    listeners() {
      const { excludeInputListeners, preview } = this;
      const res = { ...excludeInputListeners };
      if (preview) {
        res.click = this.onClick;
      }
      return res;
    },
  },
  methods: {
    onClick(e) {
      console.log(e);
      if (this.preview) {
        const { src } = this;
        this.$wxSDK.previewImage(src, [src]).catch(() => {
          console.log('onListItem: 目前仅支持微信预览');
          /* console.log(index, this.swiper);
                    this.swiper.slideTo(index, 1000, false);
                    this.swiperPopup.open(); */
        });
      }
    },
  },
  created() {
    const { aspectRatio, height } = this;
    if (aspectRatio && height) {
      console.warn(
        '[XImage] aspectRatio 属性与 height 属性,不可同时设置,已忽略 height 属性！',
      );
    }
  },
};
</script>

<style lang="scss">
.x-image {
  overflow: hidden;
  display: inline-block;
  vertical-align: top; // 为解决 inline-block 下多余的空白

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &.ratio {
    display: block;

    > .inner {
      position: relative;
      height: 0;

      > img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &.radius {
    border-radius: $padding-small;

    &.all {
      border-radius: 100%;
    }
  }
}
</style>
