<!-- Created by henian.xu on 2018/5/3. -->

<template>
  <div
    class="addr"
    @click="gotoType(type)"
  >
    <template v-if="!label">
      <div
        class="addr-select"
        v-if="!value.consignee"
      >
        <i class="f-icon add">&#xf015;</i>
        <span>选择收货地址</span>
        <i class="f-icon">&#xf012;</i>
      </div>
      <div
        class="addr-info"
        :class="isSimple && 'simple'"
        v-else
      >
        <div
          v-if="isSimple"
          class="inner"
        >
          <dl>
            <dd>{{ currAddr.regionNamePath }} {{ currAddr.address }} {{ currAddr.postcode }}</dd>
          </dl>
        </div>
        <div
          v-else
          class="inner"
        >
          <div class="header">
            <span
              class="badge badge-main small"
              v-if="currAddr.isDefault"
            >默认</span>
            <span class="ma-l tc-minor">{{ currAddr.consignee }}</span>
            <span class="ma-l tc-minor">{{ currAddr.mobile }}</span>
          </div>
          <div class="body">
            {{ currAddr.regionNamePath }} {{ currAddr.address }} {{ currAddr.postcode }}
          </div>
        </div>
        <i class="f-icon fs-little tc-g6 link">&#xf012;</i>
      </div>
    </template>
    <div
      v-else
      v-html="label"
    />

    <!-- popup -->
    <transition-group :name="aniName">
      <!-- select -->
      <Page
        class="bc-g2"
        ref="popupSelect"
        key="select"
        v-show="popupShow === 'select'"
      >
        <div class="header">
          <div class="ctrl-left">
            <div
              class="btn"
              @click="gotoType(-1);"
            >
              <i class="f-icon">&#xf011;</i>
            </div>
          </div>
          <div class="label">
            选择地址
          </div>
          <div class="ctrl-right">
            <div
              class="btn"
              v-if="addrList.length"
              @click="gotoType('manage')"
            >
              管理
            </div>
            <div
              class="btn"
              v-else
            />
          </div>
        </div>
        <div class="footer">
          <div
            class="btn btn-main fill"
            @click="onPlusAddress"
          >
            {{ +tabs===2?'提交':'添加新地址' }}
          </div>
        </div>
        <container>
          <Tabs
            v-if="$globalVar.shopData.isQuickBuyerAddress"
            average
            :index="tabsIndex"
            @tabChange="(item)=>tabs=item.sign"
          >
            <TabPanel
              :sign="1"
              label="常用地址"
            />
            <TabPanel
              :sign="2"
              label="低频地址"
            >
              <x-form
                class="pa-l pa-r bc-w"
                ref="tempForm"
                :rules="rules"
                :model="tempAddressFormData"
              >
                <FormItem class="npa-a">
                  <XInput
                    :auto-size="{minRows:3,maxRows:3}"
                    type="textarea"
                    placeholder="请输入收货人信息, 以空格隔开会自动拆分"
                    v-model="tempAddressFormData.addressStr"
                  />
                </FormItem>
                <Button
                  class="w-100per"
                  theme="main"
                  label="智能识别"
                  @click="onCleanseAddress(tempAddressFormData)"
                />
                <div class="tc-red fs-small pa-a">
                  例如: 王** 139***1234 **省**市**区**街道**号
                </div>
                <form-item
                  label="收货人："
                  prop="consignee"
                >
                  <x-input
                    type="text"
                    v-model="tempAddressFormData.consignee"
                    placeholder="请输入收货人"
                  />
                </form-item>
                <form-item
                  label="手机号："
                  prop="mobile"
                >
                  <x-input
                    type="text"
                    v-model="tempAddressFormData.mobile"
                    placeholder="请输入手机"
                  />
                </form-item>
                <!-- 区域 -->

                <form-item
                  link
                  label="收货地址："
                  :prop="addressManager.mapPicker?'region':'regionId'"
                >
                  <area-map-picker
                    v-if="addressManager.mapPicker"
                    v-model="tempAddressFormData.region"
                  />
                  <region-picker
                    ref="tempRegionPicker"
                    v-else
                    v-model="tempAddressFormData.regionId"
                  />
                </form-item>
                <form-item
                  label="详细地址："
                  prop="address"
                >
                  <x-input
                    type="textarea"
                    :auto-size="{minRows:2,maxRows:4}"
                    v-model="tempAddressFormData.address"
                    placeholder="如街道，楼层，门牌号等"
                  />
                </form-item>
                <form-item
                  label="邮政编码："
                  prop="postcode"
                >
                  <x-input
                    type="text"
                    v-model="tempAddressFormData.postcode"
                    placeholder="请输入邮政编码"
                  />
                </form-item>
                <form-item
                  prop="isDefault"
                >
                  <checkbox
                    v-model="tempAddressFormData.isAddToAddress"
                  >
                    是否加入常用地址
                  </checkbox>
                </form-item>
              </x-form>
            </TabPanel>
          </Tabs>
          <template v-if="!$globalVar.shopData.isQuickBuyerAddress || ($globalVar.shopData.isQuickBuyerAddress&&tabs===1)">
            <query-box
              placeholder="请输入姓名或手机号"
              show-search-btn
              v-model="keywords"
              @query="getAddressList"
            />
            <list class="addr-list">
              <address-item
                v-for="(item) in addrList"
                :key="item.id"
                :data="item"
                v-model="defaultId"
                @click="onSelectItem(item)"
              />
            </list>
          </template>
        </container>
      </Page>
      <!-- manage -->
      <Page
        class="bc-g2"
        ref="popupManage"
        key="manage"
        v-show="popupShow === 'manage'"
      >
        <div class="header">
          <div class="ctrl-left">
            <div
              class="btn"
              @click="gotoType(-1);"
            >
              <i class="f-icon">&#xf011;</i>
            </div>
          </div>
          <div class="label">
            管理收货地址
          </div>
          <div class="ctrl-right">
            <div class="btn" />
          </div>
        </div>
        <div class="footer">
          <div
            class="btn btn-main fill"
            @click="onPlusAddress"
          >
            添加新地址
          </div>
        </div>
        <container>
          <query-box
            placeholder="请输入姓名或手机号"
            show-search-btn
            v-model="keywords"
            @query="getAddressList"
          />
          <list class="addr-list">
            <address-item
              v-for="(item,index) in addrList"
              :key="item.id"
              :data="item"
              v-model="defaultId"
              @delete="onDelete(index)"
              @edit="onEdit($event)"
              is-edit
            />
          </list>
        </container>
      </Page>
      <!-- plus -->
      <Page
        class="bc-g2"
        ref="popupPlus"
        key="plus"
        v-show="popupShow === 'plus'"
      >
        <div class="header">
          <div class="ctrl-left">
            <div
              class="btn"
              @click="gotoType(-1);"
            >
              <i class="f-icon">&#xf011;</i>
            </div>
          </div>
          <div class="label">
            {{ this.plusFormData.editIng?'编辑':'添加新' }}地址
          </div>
          <div class="ctrl-right">
            <div class="btn" />
          </div>
        </div>
        <div class="footer">
          <div
            class="btn btn-main fill"
            @click="onSavePlus"
          >
            保存
          </div>
        </div>
        <container>
          <x-form
            class="pa-l pa-r bc-w"
            ref="form"
            :rules="rules"
            :model="plusFormData"
          >
            <FormItem class="npa-a">
              <XInput
                :auto-size="{minRows:3,maxRows:3}"
                type="textarea"
                placeholder="请输入收货人信息, 以空格隔开会自动拆分"
                v-model="plusFormData.addressStr"
              />
            </FormItem>
            <Button
              class="w-100per"
              theme="main"
              label="智能识别"
              @click="onCleanseAddress(plusFormData)"
            />
            <div class="tc-red fs-small pa-a">
              例如: 王** 139***1234 **省**市**区**街道**号
            </div>
            <form-item
              label="收货人："
              prop="consignee"
            >
              <x-input
                type="text"
                v-model="plusFormData.consignee"
                placeholder="请输入收货人"
              />
            </form-item>
            <form-item
              label="手机号："
              prop="mobile"
            >
              <x-input
                type="text"
                v-model="plusFormData.mobile"
                placeholder="请输入手机"
              />
            </form-item>
            <!-- 区域 -->

            <form-item
              link
              label="收货地址："
              :prop="addressManager.mapPicker?'region':'regionId'"
            >
              <area-map-picker
                v-if="addressManager.mapPicker"
                v-model="plusFormData.region"
              />
              <region-picker
                v-else
                v-model="plusFormData.regionId"
              />
            </form-item>
            <form-item
              label="详细地址："
              prop="address"
            >
              <x-input
                type="textarea"
                :auto-size="{minRows:2,maxRows:4}"
                v-model="plusFormData.address"
                placeholder="如街道，楼层，门牌号等"
              />
            </form-item>
            <form-item
              label="邮政编码："
              prop="postcode"
            >
              <x-input
                type="text"
                v-model="plusFormData.postcode"
                placeholder="请输入邮政编码"
              />
            </form-item>
            <form-item
              prop="isDefault"
            >
              <checkbox
                v-model="plusFormData.isDefault"
              >
                是否默认地址
              </checkbox>
            </form-item>
          </x-form>
        </container>
      </Page>
    </transition-group>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import emitter from '@/mixins/emitter';
import { Valid } from '@/utils';
import { mapGetters } from 'vuex';

const defaultFormData = JSON.stringify({
  consignee: '',
  mobile: '',
  regionId: '', // 10353
  region: {}, // 10353
  address: '',
  postcode: '',
  isAddToAddress: false,
  isDefault: false,
  editIng: false,
  addressStr: '',
  // addressStr: 'xhn 18060082532 福建省泉州市鲤城区美食街69-38号万星影楼5层',
});
export default {
  name: 'AddressPicker',
  mixins: [popup, emitter],
  data() {
    return {
      appLevel: true,
      addrList: [],
      defaultId: 0,
      plusFormData: JSON.parse(defaultFormData),
      tempAddressFormData: JSON.parse(defaultFormData),
      rules: {
        consignee: {
          required: true,
          message: '收货人不能为空',
        },
        mobile: [
          {
            required: true,
            message: '电话不能为空',
          },
          {
            validator: Valid.mobile,
            message: '请输入正确的手机号码',
          },
        ],
        regionId: {
          required: true,
          message: '收货地址不能为空',
        },
        region: {
          type: 'object',
          required: true,
          message: '收货地址不能为空',
          fields: {
            id: {
              type: 'number',
              required: true,
              message: '收货地址不能为空',
            },
          },
        },
        address: {
          required: true,
          message: '邮政编码不能为空',
        },
      },
      tabs: 1,
      tabsIndex: 0,
      onCleanseAddress_: this.$utils.Comm.debounce(this.onCleanseAddress),
      keywords: '',
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    uuid: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default() {
        return 'select';
      },
      // required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('global', ['moduleConfig']),
    addressManager() {
      return this.moduleConfig.addressManager || {};
    },
    _popupSelect() {
      return this.$refs['popupSelect'];
    },
    _popupManage() {
      return this.$refs['popupManage'];
    },
    _popupPlus() {
      return this.$refs['popupPlus'];
    },
    _form() {
      return this.$refs['form'];
    },
    _tempForm() {
      return this.$refs['tempForm'];
    },
    currAddr: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$nextTick(() => {
          this.dispatch('formItem', 'x.form.change', [val.id]);
        });
      },
    },
  },
  watch: {
    'plusFormData.region.address'(val) {
      this.plusFormData.address = val || '';
    },
  },
  methods: {
    gotoType(type) {
      if (this.disabled) return;
      if (isNaN(type)) {
        if (!this.addrList || !this.addrList.length) this.getAddressList();
        this.popupShow = type;
      } else {
        this.$router.go(type);
      }
    },
    getAddressList({ uuid = this.uuid } = {}) {
      this.$api.Buyer.Mb.BuyerDeliveryAddress.data({
        keywords: this.keywords,
        uuid,
      }).then(json => {
        const result = json.data;
        this.addrList = result.data;
        const lng = this.addrList.length;
        for (let i = 0; i < lng; i++) {
          const item = this.addrList[i];
          if (item.isDefault) {
            this.defaultId = item.id;
            break;
          }
          this.defaultId = -1;
        }
      });
    },
    onPlusAddress() {
      if (+this.tabs === 1) {
        this.plusFormData = JSON.parse(defaultFormData);
        this.gotoType('plus');
      } else if (+this.tabs === 2) {
        this._tempForm.validate().then(() => {
          const { tempAddressFormData, plusFormData, $refs } = this;
          console.log(this.$refs['tempRegionPicker']);
          const regionNamePath = $refs['tempRegionPicker'].getNamePath();
          const tempCurrAddr = {
            ...tempAddressFormData,
            regionNamePath,
            isDefault: false,
          };
          if (tempAddressFormData.isAddToAddress) {
            Object.keys(plusFormData).forEach(
              key =>
                (plusFormData[key] =
                  tempAddressFormData[key] || plusFormData[key]),
            );
            this.onSavePlus().then(json => {
              tempCurrAddr.id = json.data.data.id;
              this.currAddr = tempCurrAddr;
            });
          } else {
            this.gotoType(-1);
            this.currAddr = tempCurrAddr;
          }
        });
      }
    },
    onSelectItem(item) {
      this.currAddr = {
        ...item,
        isDefault: item.id === this.defaultId,
      };
      this.gotoType(-1);
    },
    onSavePlus() {
      return new Promise((resolve, reject) => {
        this._form
          .validate()
          .then(() => {
            if (!this.plusFormData.editIng) {
              delete this.plusFormData.id;
            }
            const data = {
              ...this.plusFormData,
            };
            if (this.addressManager.mapPicker) {
              data.regionJson = JSON.stringify(data.region);
            }
            this.$api.Buyer.Mb.BuyerDeliveryAddress.save(data)
              .then(json => {
                this.getAddressList();
                this.$router.go(-1);
                this._form.resetFields();
                resolve(json);
              })
              .catch(reject);
          })
          .catch(reject);
      });
    },
    onDelete(index) {
      this.addrList.splice(index, 1);
    },
    onEdit(data) {
      this.plusFormData = {
        ...data,
        region: {
          id: data.regionId,
          province: data.province || '',
          city: data.city || '',
          district: data.district || '',
          address: data.address || '',
          lat: data.addressLatitude,
          lng: data.addressLongitude,
        },
        editIng: true,
      };
      this.gotoType('plus');
    },
    onCleanseAddress(formData) {
      this.$api.Buyer.Mb.BuyerDeliveryAddress.cleanseAddress({
        address: formData.addressStr,
      }).then(json => {
        const res = json.data.data;
        Object.keys(formData).forEach(key => {
          formData[key] =
            (key === 'mobile' ? res['phone'] : res[key]) || formData[key];
        });
      });
    },
    close() {
      this.gotoType(-1);
    },
    open(tabs) {
      if (tabs) {
        tabs = this.$globalVar.shopData.isQuickBuyerAddress ? tabs : 1;
        this.tabs = tabs;
        this.tabsIndex = tabs - 1;
      }
      this.gotoType(this.type);
    },
  },
  created() {},
  mounted() {
    this.$$popupWrap.appendChild(this._popupSelect.$el);
    this.$$popupWrap.appendChild(this._popupManage.$el);
    this.$$popupWrap.appendChild(this._popupPlus.$el);
  },
  beforeDestroy() {
    this.$$popupWrap.removeChild(this._popupSelect.$el);
    this.$$popupWrap.removeChild(this._popupManage.$el);
    this.$$popupWrap.removeChild(this._popupPlus.$el);
  },
};
</script>

<style lang="scss">
.addr {
  cursor: pointer;
  flex: 1 1 auto;
}

.addr-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid $color-border;
  padding: $padding;
  margin-bottom: $margin-big;

  > span {
    flex: 1 1 auto;
    display: inline-block;
    padding-left: $padding;
  }

  > .f-icon {
    font-size: 120%;
    width: 1em;
    text-align: center;
    color: $gray6;

    &.add {
      font-size: 0.5rem;
      color: $color-blue;
    }
  }
}

.addr-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff url('~@/assets/images/comm/address-border.png') left top
    repeat-x;
  background-size: auto 0.1rem;
  padding: ($padding + 0.1rem) $padding $padding $padding;
  margin-bottom: $margin-big;
  line-height: $line-height;

  > .f-icon {
    display: inline-block;
    width: 0.4rem;
    font-size: 0.4rem;
    text-align: center;
    margin-right: $margin-small;
  }

  > .inner {
    flex: 1 1 auto;

    > .body {
      font-weight: bold;
    }

    dl {
      @include clearfix;

      dt {
        float: left;
        text-align: justify;
        width: 4em;
        height: $body-font-size * $line-height;
        overflow: hidden;

        &:after {
          content: '';
          display: inline-block;
          padding-left: 100%;
          height: 0;
        }
      }

      dd {
        white-space: initial;
        overflow: hidden;
      }
    }
  }

  > .link {
    align-self: center;
    margin-right: 0;
  }

  &.simple {
    align-items: center;
    background: transparent;
  }
}

.form-item {
  .addr-info,
  .addr-select {
    /*padding: 0;*/
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.list-item {
  .addr-info,
  .addr-select {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
</style>
