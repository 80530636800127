<template>
  <div
    v-if="isCommand"
    class="linker"
    @click="onCommand"
  >
    <slot />
  </div>
  <a
    v-else-if="linkerProps.is === 'a'"
    :href="linkerProps.href"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-bind="linkerProps"
    custom
    v-slot="{ href, route, navigate, isActive, isExactActive }"
  >
    <a
      :href="href"
      :class="[isActive && 'active', isExactActive && 'exact-active']"
      @click="navigate"
    >
      <slot :isActive="isActive||isExactActive" />
    </a>
  </router-link>
</template>

<script>
import linkerMixin from '@/mixins/linker';

export default {
  name: 'Linker',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
};
</script>

<style scoped>
</style>
