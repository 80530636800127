<!-- Created by henian.xu on 2018/12/6. -->

<template>
  <div class="group-invite">
    <GroupCountdown
      class="countdown"
      :end-time="groupBuyDetail.endTime"
    />
    <p class="ma-t fs-big fw-bold">
      拼团还未成功，点击右上角发送给朋友
    </p>
    <div
      :class="['btn-invite',{disabled:!data.groupBuyAvailable}]"
      @click="onInvite"
    >
      邀请好友参团
    </div>
    <div
      class="btn ma-t"
      @click="onQRCode"
    >
      <i class="f-icon ma-r">&#xf028;</i>面对面扫码参团
    </div>

    <!--分享弹框-->
    <transition-group name="bounce">
      <div
        key="share"
        ref="popupShare"
        class="share-popup"
        v-show="popupShow==='share'"
        @click="close"
        :style="`background-image: url('${shareImg}')`"
      >
        <div
          class="ta-c fs-more tc-w"
          style="margin-bottom: 1rem;line-height: 2em;"
        >
          主人<br>
          拼团还未成功<br>
          请点击右上角<br>
          发送给朋友<br>
          或者<br>
          分享到朋友圈<br>
          抓紧凑齐人数<br>
          享受震撼拼团价<br>
        </div>
      </div>
      <!--二维码弹框-->
      <div
        key="qrcode"
        ref="popupQrcode"
        class="share-popup"
        v-show="popupShow==='qrcode'"
        @click="close"
      >
        <img
          ref="QRCode"
          :src="QRCodeSrc"
          width="60%"
          style="border-radius: .15rem;"
        >
        <div class="tc-w ma-t ta-c fs-big">
          用手机微信扫一扫二维码<br>参加我的团
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
import QRCode from 'qrcode';
import shareImg from '@/assets/images/share/share-tip-txt.png';
export default {
  name: 'Invite',
  mixins: [popupMixin],
  data() {
    return {
      shareImg,
      QRCodeSrc: '',
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    groupBuyDetail() {
      return this.data.groupBuyDetail || {};
    },
  },
  methods: {
    onInvite() {
      console.log(123);
      this.popupShow = 'share';
    },
    onQRCode() {
      this.popupShow = 'qrcode';
    },
  },
  mounted() {
    const shareUrl = this.$route.fullPath
      ? `${window.location.search ? '&' : '?'}shareUrl=${this.$route.fullPath}`
      : '';
    const href = `${window.location.href.split('#')[0]}${shareUrl}`;
    QRCode.toDataURL(href, { margin: 3, scale: 10 }, (error, url) => {
      if (error) console.error(error);
      this.QRCodeSrc = url;
    });
  },
  created() {
    this.popupShow = 'share';
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  border-radius: 100em;
  background-color: $color-main;
  color: #fff;
  text-align: center;
  padding: $padding-small $padding;
  font-size: 110%;
}
</style>
<style lang="scss">
.group-invite {
  padding: $padding;
  text-align: center;
  .btn-invite {
    margin-top: $margin;
    font-size: 120%;
    background-color: $color-main;
    color: #fff;
    border-radius: 0.1rem;
    padding: $padding;

    &.disabled {
      background-color: $gray6;
    }
  }
}

.share-popup {
  position: absolute;
  z-index: $z-index-7;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7) right top no-repeat;
  background-size: 40% auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
