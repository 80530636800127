/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreActivity extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStoreActivity';
  }

  /**
   * 列表
   * @returns {AxiosPromise<any>}
   */
  shopStoreActivityList({ currentPage, pageSize }) {
    return super.get(`/shopStoreActivityList`, { currentPage, pageSize });
  }

  /**
   * 保存
   * @param id
   * @param activityName
   * @param activityBeginTime
   * @param activityEndTime
   * @param activityNotice
   * @param activityCover
   * @param activityDesc
   * @param paymentType
   * @param shipMethod
   * @param pickupServiceStation
   * @param pickupDate
   * @param deliverBeginDate
   * @param deliverEndDate
   * @param deliverDesc
   * @param isForbidCancelOrder
   * @param isAvailable
   * @param activityFreightJson
   * {
   *   zipcode 邮编
   *   freeFreightOrderAmount 免邮费金额
   *   freightAmount 运费金额
   * }
   * @param activityGoodsJson
   * @returns {*}
   */
  saveShopStoreActivity({
    id,
    activityName, //活动名称
    activityBeginTime, //开始时间
    activityEndTime, //结束时间
    activityNotice, //活动须知
    activityCover, //活动封面
    activityDesc, //活动描述
    paymentType, //付款类型
    shipMethod, //送货方式
    pickupServiceStation, //自取门店 逗号分隔id
    pickupDate, //自取时间
    deliverBeginDate, //送货上门开始时间
    deliverEndDate, //送货上门结束时间
    deliverDesc, //送货上门描述
    isForbidCancelOrder, //是否可取消订单
    isAvailable, //是否可用
    activityFreightJson, //送货上门区域设置json
    activityGoodsJson, //活动商品json
  }) {
    return super.post(`/saveShopStoreActivity`, {
      id,
      activityName, //活动名称
      activityBeginTime, //开始时间
      activityEndTime, //结束时间
      activityNotice, //活动须知
      activityCover, //活动封面
      activityDesc, //活动描述
      paymentType, //付款类型
      shipMethod, //送货方式
      pickupServiceStation, //自取门店 逗号分隔id
      pickupDate, //自取时间
      deliverBeginDate, //送货上门开始时间
      deliverEndDate, //送货上门结束时间
      deliverDesc, //送货上门描述
      isForbidCancelOrder, //是否可取消订单
      isAvailable, //是否可用
      activityFreightJson, //送货上门区域设置json
      activityGoodsJson, //活动商品json
    });
  }
  /**
   * 获取活动详情
   * @returns {AxiosPromise<any>}
   */
  shopStoreActivityDetail(id) {
    return super.get(`/shopStoreActivityDetail`, { id });
  }

  /**
   * 手工截止活动
   * @param id
   * @returns {*}
   */
  closeShopStoreActivity(id) {
    return super.post(`/closeShopStoreActivity`, { id });
  }
}

export default new ShopStoreActivity();
