<!-- Created by henian.xu on 2018/2/1. -->

<template>
  <div
    :class="['form-item',validateState]"
    :is="link?'a':'div'"
    :herf="link?'javascript:;':null"
  >
    <span class="asterisk tc-red"><i class="f-icon">*</i></span>
    <div class="inner">
      <div
        class="label"
        v-if="label"
      >
        <span v-html="label" />
      </div>
      <div class="body">
        <div class="content">
          <slot />
        </div>
        <i
          class="f-icon tc-red"
          v-if="!showError && validateState === 'error'"
          @click="onError"
        >&#xf041;</i>
        <i
          class="f-icon tc-green"
          v-if="_showValidSuccess && validateState === 'success'"
        >&#xf019;</i>
      </div>
    </div>
    <div
      v-if="showError && validateState === 'error'"
      class="feedback"
    >
      {{ validateMessage }}
    </div>
  </div>
</template>

<script>
import AsyncValidator from 'async-validator';
import { Comm } from '@/utils';
import emitter from '@/mixins/emitter';

export default {
  name: 'FormItem',
  componentName: 'formItem',
  mixins: [emitter],
  data() {
    return {
      validateState: '',
      validateMessage: '',
      validateDisabled: false, // 在重置时避免验证用
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: Boolean,
      default: false,
    },
    prop: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, Array],
      default() {
        return null;
      },
    },
    required: {
      type: Boolean,
      default: undefined,
    },
    showValidSuccess: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    form() {
      let parent = this.$parent;
      let parentName = parent.$options.componentName;
      while (parentName !== 'XForm') {
        parent = parent.$parent;
        parentName = parent.$options.componentName;
      }
      return parent;
    },
    _showValidSuccess() {
      return this.showValidSuccess || this.form.showValidSuccess;
    },
    fieldValue: {
      cache: false,
      get() {
        const model = this.form.model;
        let path = this.prop;
        if (!model || !path) return;

        if (path.indexOf(':') !== -1) {
          path = path.replace(/:/, '.');
        }
        return Comm.getPropByPath(model, path, true).v;
      },
    },
  },
  methods: {
    getRules() {
      let formRules = this.form.rules;
      const selfRules = this.rules;
      // const requiredRule = this;
      formRules = formRules
        ? Comm.getPropByPath(formRules, this.prop).o[this.prop || '']
        : [];
      return [].concat(selfRules || formRules || []);
    },
    getFilteredRule(trigger) {
      const rules = this.getRules();
      return rules
        .filter(rule => {
          return !rule.trigger || rule.trigger.indexOf(trigger) !== -1;
        })
        .map(rule => ({ ...rule }));
    },
    validate(trigger, cbFn) {
      let promise;
      // if no callback, return promise
      if (typeof cbFn !== 'function' && window.Promise) {
        promise = new Promise((resolve, reject) => {
          cbFn = function(valid) {
            valid = !valid;
            valid ? resolve(valid) : reject(valid);
          };
        });
      }
      this.validateDisabled = false;
      const rules = this.getFilteredRule(trigger);
      const descriptor = {};
      if (rules && rules.length > 0) {
        rules.forEach(rule => {
          delete rule.trigger;
        });
      }
      this.validateState = 'validating';
      descriptor[this.prop] = rules;
      if (!rules.length) cbFn(false);
      const validator = new AsyncValidator(descriptor);
      const model = {};
      model[this.prop] = this.fieldValue;
      validator.validate(model, { firstFields: true }, errors => {
        // if (!errors) {
        //     debugger;
        // }
        this.validateState = !errors ? 'success' : 'error';
        this.validateMessage = errors ? errors[0].message : '';
        cbFn(this.validateMessage);
      });
      return promise;
    },
    clearValidate() {
      this.validateState = '';
      this.validateMessage = '';
      this.validateDisabled = false;
    },
    resetField() {
      this.validateState = '';
      this.validateMessage = '';

      const model = this.form.model;
      const value = this.fieldValue;
      let path = this.prop;
      if (path.indexOf(':') !== -1) {
        path = path.replace(/:/, '.');
      }

      const prop = Comm.getPropByPath(model, path, true);
      if (Array.isArray(value)) {
        this.validateDisabled = true;
        prop.o[prop.k] = [].concat(this.initialValue);
      } else {
        this.validateDisabled = true;
        prop.o[prop.k] = this.initialValue;
      }
    },
    onFieldBlur() {
      this.validate('blur').catch(() => {
        // 只为了去除控制台多余的输出
      });
    },
    onFieldChange() {
      if (this.validateDisabled) {
        this.validateDisabled = false;
        return;
      }
      this.validate('change').catch(() => {
        // 只为了去除控制台多余的输出
      });
    },
    onError() {
      this.$messageBox.tips(this.validateMessage);
    },
  },
  mounted() {
    if (!this.prop) return;
    this.dispatch('XForm', 'x.form.addField', [this]);

    let initialValue = this.fieldValue;
    if (Array.isArray(initialValue)) {
      initialValue = [...initialValue];
    } else if (typeof initialValue === 'object') {
      initialValue = { ...initialValue };
    }
    Object.defineProperty(this, 'initialValue', {
      value: initialValue,
    });

    const rules = this.getRules();
    if (rules.length || this.required) {
      this.$on('x.form.blur', this.onFieldBlur);
      this.$on('x.form.change', this.onFieldChange);
    }
  },
  beforeDestroy() {
    this.dispatch('XForm', 'x.form.removeField', [this]);
  },
};
</script>

<style lang="scss">
</style>
