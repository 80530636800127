/**
 * Created by henian.xu on 2018/9/11.
 * 商品模块
 */
import { Buyer } from '@/api/buyer/Buyer';

class Gd extends Buyer {
  constructor() {
    super();
    this.baseUrl += '/gd';
  }
}

export default new Gd();
export { Gd };
