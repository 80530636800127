/**
 * Created by henian.xu on 2018/1/10.
 * 常用 dom 操作类库
 */

class Dom {
  constructor() {
    this.$app = null;
  }

  get App() {
    return this.$app || (this.$app = document.querySelector('#app'));
  }

  get page() {
    let page = document.querySelector(
      '#app>.pages>[class*="-enter-active"].page',
    );
    page = page || document.querySelector('#app>.pages>.page');
    return page;
  }

  createPopupWrap(page) {
    page = page || this.App;
    const childNodes = page.childNodes;
    let popup;
    for (let i = 0, l = childNodes.length; i < l; i++) {
      const item = childNodes[i];
      if (item.nodeType !== 1) continue;
      if (/\bpopup-wrap\b/.test(item.className)) {
        popup = item;
        break;
      }
    }
    if (!popup) {
      popup = document.createElement('div');
      popup.classList.add('popup-wrap');
      page.appendChild(popup);
    }
    return popup;
  }

  createMountNode(page) {
    page = page || this.App;
    const node = document.createElement('div');
    const popup = this.createPopupWrap(page);
    popup.appendChild(node);
    return node;
  }
}

export default new Dom();
