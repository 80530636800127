<!-- Created by henian.xu on 2018/8/24. -->

<template>
  <div class="page-bar">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageBar',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.page-bar {
  flex: 0 0 auto;
  order: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  min-height: $navHeight;

  > .button {
    flex: 1 1 1%;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
}
</style>
