/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreGoods extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStoreGoods';
  }

  /**
   * 商品列表
   * @returns {AxiosPromise<any>}
   */
  goodsList({ currentPage, pageSize }) {
    return super.get(`/goodsList`, { currentPage, pageSize });
  }

  /**
   * 商品详情
   * @returns {AxiosPromise<any>}
   */
  getGoodsDetailVo(id) {
    return super.get(`/getGoodsDetailVo`, { id });
  }

  /**
   * 代理商品列表
   * @param goodsName // 商品名称筛选
   * @param isAgent 是否已代理
   * @param goodsCatId
   * @param shopGoodsCatId
   * @param currentPage
   * @param pageSize
   * @returns {*}
   */
  agentGoodsList({
    goodsName,
    isAgent,
    goodsCatId,
    shopGoodsCatId,
    currentPage,
    pageSize,
  }) {
    return super.get(`/agentGoodsList`, {
      goodsName,
      isAgent,
      goodsCatId,
      shopGoodsCatId,
      currentPage,
      pageSize,
    });
  }
  agentGoodsIdList({ goodsName, isAgent, goodsCatId, shopGoodsCatId }) {
    return super.get(`/agentGoodsIdList`, {
      goodsName,
      isAgent,
      goodsCatId,
      shopGoodsCatId,
    });
  }

  /**
   * 代理商品详情
   * @param id
   * @returns {*}
   */
  agentGoodsDetail(id) {
    return super.get(`/agentGoodsDetail`, {
      id,
      _isRepeat: true,
    });
  }

  /**
   * 一键代发商品
   * @param data
   * @returns {*}
   */
  saveAgentGoods(data) {
    return super.post(`/saveAgentGoods`, data);
  }

  /**
   * 批量一键代发商品
   * @param json
   * @returns {*}
   */
  batchSaveAgentGoods(json) {
    return super.post(`/batchSaveAgentGoods`, { json });
  }

  /**
   * 批量加价
   * @param ids
   * @param addPrice
   * @param addPriceType      //1:固定加价2:按比例加价
   * @return {*}
   */
  batchEditAgentGoods({ ids, addPrice, addPriceType }) {
    return super.post(`/batchEditAgentGoods`, { ids, addPrice, addPriceType });
  }

  /**
   * 删除代理商品
   * @param id
   * @param goodsCatId
   * @returns {*}
   */
  delAgentGoodsCat({ id, goodsCatId }) {
    return super.post(`/delAgentGoodsCat `, { id, goodsCatId });
  }

  /**
   * 取消代理商品
   * @param id
   * @returns {*}
   */
  cancelAgentGoods({ ids }) {
    return super.post(`/cancelAgentGoods `, { ids });
  }

  /**
     * 保存商品
     * @param goodsJson
     * {
            goodsName, //商品名称
            price, //价格
            goodsImage, //列表图
            goodsDetailImage, //详情图
        }
     * @returns {*}
     */
  saveGoods(goodsJson) {
    return super.post(`/saveGoods`, { goodsJson });
  }
  /**
   * 批量修改类目
   * @param ids
   * @param goodsCatId
   * @return {*}
   */
  batchEditAgentGoodsRelCat({ ids, goodsCatIds }) {
    return super.post('/batchEditAgentGoodsRelCat', {
      ids,
      goodsCatIds,
    });
  }
}

export default new ShopStoreGoods();
