<!-- Created by henian.xu on 2018/1/3. -->

<template>
  <div class="magic-cube">
    <div
      class="inner"
      :style="innerStyles"
    >
      <div
        :is="item.href?'Linker':'div'"
        :to="item.href"
        class="item"
        v-for="(item, index) in this.data"
        :style="getStyle()"
        :key="item.id || index"
      >
        <img
          :src="item.src || $defaultImg | imgCropping"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MagicCube',
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    column: {
      type: [Number, String],
      default: 3,
    },
    itemPadding: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    itemPadding_() {
      const itemPadding = +this.itemPadding || 0;
      if (!itemPadding) return 0;
      return (itemPadding * 2) / 100;
    },
    innerStyles() {
      const { itemPadding_ } = this;
      if (!itemPadding_) return '';
      return {
        margin: `-${itemPadding_ / 2}rem`,
      };
    },
  },
  methods: {
    getStyle() {
      const column = +this.column || 1;
      const { itemPadding_ } = this;
      const res = {
        width: `${(1 / column) * 100}%`,
      };
      if (itemPadding_) res.padding = `${itemPadding_ / 2}rem`;
      return res;
    },
  },
};
</script>

<style lang="scss">
.magic-cube {
  overflow: hidden;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    line-height: 0;

    > .item {
      display: inline-block;
      flex: 1 1 auto;
      > img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
