/**
 * Created by henian.xu on 2018/6/22.
 *
 */

import { Buyer } from '@/api';

export default {
  namespaced: true,
  state: {
    levelList: null,
  },
  getters: {},
  mutations: {
    setLevelList(state, data) {
      state.levelList = data;
    },
  },
  actions: {
    getLevelList({ commit }) {
      return Buyer.Mb.Distributor.distributorLevelList().then(json => {
        const res = json.data.data;
        console.log(res);
        commit('setLevelList', res);
      });
    },
  },
};
