/**
 * Created by henian.xu on 2017/11/22.
 *
 */

import Vue from 'vue';
import Vuex from 'vuex';

import global from './global';
import meiQia from './meiQia';
import goods from './goods';
import cart from './cart';
import user from './user';
import distributor from './distributor';
import countInfo from './countInfo';
import store from './store';
import shelvesAddCart from './shelvesAddCart';
import portalInfo from './portalInfo';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    meiQia,
    goods,
    cart,
    user,
    distributor,
    countInfo,
    store,
    shelvesAddCart,
    portalInfo,
  },
});
