<template>
  <!--:class="{'device-pc-nvw':$globalVar.device.dpr<=1 && $globalVar.device.width>=500}"-->
  <div
    id="app"
    :class="{'device-pc-nvw':!isMobile}"
  >
    <!--<pre style="color: #fff;overflow: auto;">
            {{$globalVar.device}}
        </pre>-->
    <div
      class="page-header"
      v-if="!isMobile"
    >
      <div class="ctrl-left">
        <a
          href="javascript:history.go(-1);"
          class="btn"
        ><i class="f-icon">&#xf011;</i></a>
      </div>
      <div class="label">
        {{ pageTitle || $route.query.pageTitle || $route.meta.title }}
      </div>
      <div class="ctrl-right">
        <a
          href="javascript:;"
          class="btn"
        ><!--<i class="f-icon">&#xf08c;</i>--></a>
      </div>
    </div>
    <SecondHeader />
    <div
      class="pages"
      v-show="!$globalVar.routerEdReplace"
    >
      <transition :name="transitionName">
        <navigation>
          <router-view />
        </navigation>
      </transition>
      <div
        v-if="noticeData.isShowNotice && isNoticeDataShowed"
        class="popup-wrap"
      >
        <div
          class="page notice-popup"
          @click.self="onCloseNotice"
        >
          <component
            class="inner"
            :is="noticeData.shopNoticeLink?'Linker':'div'"
            :to="noticeData.shopNoticeLink"
          >
            <img :src="noticeData.shopNoticeImageUrl">
            <div
              class="close-btn"
              @click.stop.prevent="onCloseNotice"
            >
              <i class="f-icon">&#xf01a;</i>
            </div>
          </component>
        </div>
      </div>
    </div>
    <footer-main />
    <!--    <quickNavigation />-->
  </div>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import { Local } from '@/store/storage';

export default {
  name: 'App',
  mixins: [pageMixin],
  data() {
    return {
      transitionName: '',
      pageTitle: '',
      // isNoticeDataShowed: Local.get('_NOTICE_DATA_SHOWEd_'),
    };
  },
  head() {
    const meta = this.$route.meta;
    this.pageTitle = '';
    return {
      title: `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}`,
    };
  },
  computed: {
    isMobile() {
      return this.$globalVar.device.isMobile || this.$globalVar.isWeiXin;
    },
    isNoticeDataShowed() {
      const isShow = Local.get('_NOTICE_DATA_SHOWEd_');
      const { $route } = this;
      return $route.name === 'Index' && !isShow;
    },
    noticeData() {
      return this.$globalVar.noticeData || {};
    },
  },
  methods: {
    onCloseNotice() {
      Local.set('_NOTICE_DATA_SHOWEd_', true);
      this.noticeData.isShowNotice = 0;
    },
  },
  created() {
    this.$navigation.on('forward', (to, from) => {
      if (from.route.path === '/') {
        this.transitionName = '';
      } else {
        this.transitionName = 'left';
      }
    });
    this.$navigation.on('replace', () => {
      this.transitionName = '';
    });

    this.$navigation.on('back', () => {
      this.transitionName = 'right';
    });

    if (!this.isMobile) {
      document.body.style.overflowX = 'auto';
    }
    this.$bus.$on('pageTitle', val => {
      // console.log(val);
      this.pageTitle = val;
    });
  },
};
</script>

<style lang="scss">
.device-pc-nvw {
  width: 500px !important;
  margin: 0 auto;
  .page {
    > .container {
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #757575;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track {
        background: #e1e1e1;
        //background: var(--md-theme-demo-light-scrollbar-background-on-background-variant,#e1e1e1);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.notice-popup {
  background: rgba(0, 0, 0, 0.5) center no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .inner {
    position: relative;
    max-width: 63vw;
    > img {
      display: block;
      width: 100%;
      height: auto;
    }
    > .close-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1rem;
      width: 0.66rem;
      height: 0.66rem;
      border-radius: 0.1rem;
      //background: rgba(0, 0, 0, 0.5);
      //color: rgba(255, 255, 255, 0.5);
      color: #fff;
      font-size: 0.5rem;
      line-height: 1;
      padding: 0.08rem;
    }
  }
}
</style>
