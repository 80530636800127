<!-- Created by henian.xu on 2019/10/22. -->

<template>
  <div class="withdraw-popup">
    <div
      class="out-show"
      @click.stop.prevent="onSwitch"
    >
      <slot />
    </div>

    <!--popup-->
    <transition name="show">
      <div
        class="popup down"
        ref="popup"
        v-show="popupShow"
        @click.self="onSwitch"
      >
        <transition name="popup-down">
          <div
            class="inner"
            v-show="popupShow"
          >
            <div class="header">
              <div class="label">
                提现
              </div>
            </div>
            <div class="body">
              <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
              >
                <!--                <FormItem
                  label="提现至:"
                  prop="withdrawType"
                >
                  <Selector
                    tiling
                    :data="[{id:1,name:'零钱'},{id:2,name:'支付宝'}]"
                    v-model="formModel.withdrawType"
                  />
                </FormItem>-->
                <div
                  class="tc-red pa-l"
                  v-if="+formModel.withdrawType===1"
                >
                  提现至零钱只能在平台消费,禁止从零钱中再提现
                </div>
                <div
                  class="tc-red pa-l"
                  v-if="+formModel.withdrawType===2"
                >
                  最小提现金额 {{ minWithdrawAmount }}
                </div>
                <FormItem
                  label="金额:"
                  prop="withdrawAmount"
                >
                  <XInput
                    type="number"
                    v-model.number="formModel.withdrawAmount"
                  />
                </FormItem>
                <template v-if="+formModel.withdrawType===2">
                  <FormItem
                    label="支付宝姓名:"
                    prop="accountName"
                  >
                    <XInput v-model="formModel.accountName" />
                  </FormItem>
                  <FormItem
                    label="支付宝账号:"
                    prop="accountNum"
                  >
                    <XInput v-model="formModel.accountNum" />
                  </FormItem>
                </template>
              </XForm>
              <div
                class="tc-red pa-l"
                v-if="+formModel.withdrawType===2 && withdrawPoundageRate"
              >
                提现至支付宝需要收取 {{ withdrawPoundageRate }} 的手续费
              </div>
            </div>
            <div class="footer">
              <Button
                theme="main"
                label="提交"
                @click="()=>{onSubmit()}"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
export default {
  name: 'WithdrawPopup',
  mixins: [popup],
  data() {
    return {
      formModel: {
        withdrawAmount: '',
        withdrawType: 2,
        accountName: '',
        accountNum: '',
      },
      formRules: {
        withdrawAmount: [
          {
            required: true,
            message: '不能为空',
          },
          {
            type: 'number',
            min: 0.01,
            // max: this.maxRefundAmount,
            message: '金额必须是有效数值,且大于 0.',
          },
          {
            validator: this.checkWithdrawAmount,
          },
        ],
        accountName: {
          required: true,
          message: '不能为空',
        },
        accountNum: {
          required: true,
          message: '不能为空',
        },
      },
    };
  },
  props: {
    //1:分销;2:店铺;3:合并
    source: {
      type: Number,
      default: 1,
    },
    maxAmount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    withdraw() {
      return this.$globalVar.withdraw || {};
    },
    withdrawPoundageRate() {
      const { withdrawPoundageRate } = this.withdraw;
      return withdrawPoundageRate ? withdrawPoundageRate + '%' : '';
    },
    minWithdrawAmount() {
      const { minWithdrawAmount } = this.withdraw;
      return minWithdrawAmount || 0;
    },
  },
  methods: {
    checkWithdrawAmount(rule, value, callback) {
      const {
        formModel: { withdrawType },
        minWithdrawAmount,
        maxAmount,
      } = this;
      if (value > maxAmount) {
        callback(new Error('不能大于总金额'));
        return;
      } else if (+withdrawType === 1) {
        callback();
        return;
      } else if (value < minWithdrawAmount) {
        callback(new Error('不能小于最小提现金额'));
        return;
      }
      callback();
    },
    async onSubmit() {
      await this.$refs['form'].validate();
      const { formModel } = this;
      const params = {
        ...formModel,
        withdrawSource: this.source,
      };
      const {
        data: { msg },
      } = await this.$api.Buyer.Mb.Distributor.distributorWithdraw(params);
      await this.done();
      this.$messageBox.tips(msg);
      this.$emit('success', formModel.withdrawAmount);
    },
  },
};
</script>

<style lang="scss">
.withdraw-popup {
  flex: 1 1 1%;
  > .out-show {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    > .button {
      flex: 1 1 1%;
    }
  }
}
</style>
