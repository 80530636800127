<!-- Created by henian.xu on 2018/4/25. -->

<template>
  <div class="sku-picker">
    <div
      class="inner"
      @click="onSwitch"
    >
      <!--sku picker-->
    </div>
    <transition name="show">
      <div
        class="popup sku-picker"
        ref="popup"
        v-show="popupShow"
        @click.self="onSwitch"
      >
        <transition name="popup-down">
          <div
            class="inner"
            v-show="popupShow"
          >
            <div class="body">
              <sku-item
                v-for="(item) in insideData"
                :key="item.id"
                :data="item"
                v-model="item.buyQuantity"
              />
            </div>
            <div class="footer">
              <div
                class="btn btn-main"
                @click="onConfirm"
              >
                确认
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';

export default {
  name: 'SkuPicker',
  mixins: [popup],
  data() {
    return {
      insideData: [],
    };
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    data: {
      handler(val) {
        this.insideData = [];
        if (!val || !val.length) return;
        val.forEach(item => {
          this.insideData.push({
            ...item,
          });
        });
      },
      immediate: true,
    },
  },
  methods: {
    onConfirm() {
      const res = [];
      this.insideData.forEach(item => {
        item.buyQuantity &&
          res.push({
            ...item,
          });
      });
      this.popupShow = false;
      this.$emit('confirm', res);
    },
  },
};
</script>

<style lang="scss">
.sku-picker.popup {
  justify-content: flex-end;
  align-items: stretch;
  > .inner {
    background-color: #fff;
    width: 100%;
    max-height: 80%;
    > .body {
      padding: 0;
    }
  }
}
</style>
