<!-- Created by henian.xu on 2019/3/14. -->

<template>
  <div class="store-order-shipping">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <slot />
    </div>

    <!-- popup -->
    <transition :name="aniName">
      <Page
        ref="popup"
        v-show="popupShow"
      >
        <Container>
          <XForm>
            <FormItem label="配送方式">
              <Selector
                label="配送方式"
                tiling
                v-model="formModel.shipMethod"
                :data="shipMethodList"
              />
            </FormItem>
            <template v-if="formModel.shipMethod===1">
              <FormItem label="快递单号">
                <XInput v-model="formModel.trackingNumber" />
              </FormItem>
              <FormItem label="快递公司">
                <XInput v-model="formModel.expressName" />
              </FormItem>
            </template>
          </XForm>
        </Container>
        <PageBar>
          <Button
            label="确认提交"
            theme="main"
            @click="onSubmit"
          />
        </PageBar>
      </Page>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
export default {
  name: 'StoreOrderShipping',
  mixins: [popupMixin],
  data() {
    return {
      formModel: {
        shipMethod: 1,
        trackingNumber: '',
        expressName: '',
      },
      shipMethodList: [
        {
          id: 1,
          name: '快递送货',
        },
        {
          id: 2,
          name: '自己送货',
        },
      ],
    };
  },
  props: {
    orderId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onSubmit() {
      const data = {
        ...this.formModel,
        orderId: this.orderId,
      };
      this.$api.Buyer.Sp.ShopStoreOrder.shippingOrder(data).then(json => {
        const res = json.data;
        this.$emit('update', res.data);
        this.close();
      });
    },
  },
};
</script>

<style lang="scss">
.store-order-shipping {
  display: inline-block;
}
</style>
