<!-- Created by henian.xu on 2019/10/17. -->

<template>
  <div class="ProxyStoreEditSendInfo">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <slot />
    </div>
    <transition name="show">
      <div
        v-show="popupShow"
        ref="popup"
        class="popup down"
        @click.self="onSwitch"
      >
        <transition name="popup-down">
          <div class="inner">
            <div class="header">
              <div class="label">
                修改发货信息
              </div>
            </div>
            <div class="body">
              <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
              >
                <FormItem
                  prop="sendConsignor"
                  label="发件人姓名:"
                >
                  <XInput
                    placeholder="请输入(可不填)"
                    v-model="formModel.sendConsignor"
                  />
                </FormItem>
                <FormItem
                  prop="sendTel"
                  label="发件人电话:"
                >
                  <XInput
                    placeholder="请输入(可不填)"
                    v-model="formModel.sendTel"
                  />
                </FormItem>
              </XForm>
            </div>
            <div class="footer">
              <Button
                label="确定"
                theme="main"
                @click="()=>{onSubmit()}"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
export default {
  name: 'ProxyStoreEditSendInfo',
  mixins: [popupMixin],
  data() {
    return {
      formModel: {
        sendConsignor: '',
        sendTel: '',
      },
      formRules: {
        sendConsignor: {
          required: true,
          message: '不能为空',
        },
        /*sendTel: {
                    required: true,
                    message: '不能为空',
                },*/
      },
    };
  },
  props: {
    initData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    popupShow(val) {
      if (!val) return;
      this.formModel.sendConsignor = this.initData.sendConsignor || '';
      this.formModel.sendTel = this.initData.sendTel || '';
    },
  },
  methods: {
    async onSubmit() {
      const {
        data: { msg },
      } = await this.$api.Buyer.Sp.ShopStore.saveShopStoreSendInfo(
        this.formModel,
      );
      this.$emit('success', { ...this.formModel });
      await this.done();
      this.$messageBox.tips(msg);
    },
  },
};
</script>

<style lang="scss">
</style>
