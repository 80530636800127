<!-- Created by henian.xu on 2019/1/10. -->

<template>
  <div
    class="rich-text"
    :style="componentStyles"
  >
    <div
      class="inner"
      v-html="data.text"
    >
      RichText
    </div>
  </div>
</template>

<script>
export default {
  name: 'RichText',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    componentStyles() {
      const obj = {};
      if (!isNaN(this.data.padding)) {
        obj.padding = `0 ${this.data.padding / 2}px`;
      }
      if (this.data.backgroundColor) {
        obj.backgroundColor = this.data.backgroundColor;
      }
      return obj;
    },
  },
};
</script>

<style lang="scss">
</style>
