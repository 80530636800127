/**
 * Created by henian.xu on 2017/10/24.
 *
 */
import { Store } from './_Store';

class ShopStoreServiceStation extends Store {
  constructor() {
    super();
    this.baseUrl += '/shopStoreServiceStation';
  }

  /**
   * 列表
   * @returns {AxiosPromise<any>}
   */
  serviceStationList({ currentPage, pageSize }) {
    return super.get(`/shopStoreServiceStationList`, { currentPage, pageSize });
  }

  /**
   * 列表选择是用
   * @returns {*}
   */
  getServiceStationList() {
    return super.get(`/findShopStoreServiceStationList`);
  }

  /**
   * 详情
   * @param id
   * @returns {*}
   */
  serviceStationDetail(id) {
    return super.get(`/getShopStoreServiceStationDetail`, { id });
  }

  /**
   * 保存
   * @returns {AxiosPromise<any>}
   */
  saveServiceStation({
    id,
    stationName, //服务站名称
    stationRegionId, //地区ID
    stationDetailAddress, //详细地址
    houseNum, //门牌号
    contactFullname, //联系人姓名
    contactTel, //联系人电话
    orderNum, //排序号
    isAvailable, //是否可用
  }) {
    return super.post(`/saveShopStoreServiceStation`, {
      id,
      stationName, //服务站名称
      stationRegionId, //地区ID
      stationDetailAddress, //详细地址
      houseNum, //门牌号
      contactFullname, //联系人姓名
      contactTel, //联系人电话
      orderNum, //排序号
      isAvailable, //是否可用
    });
  }

  /**
   * 删除
   * @param id
   * @returns {*}
   */
  delServiceStation(id) {
    return super.post(`/delShopStoreServiceStation`, { id });
  }
}

export default new ShopStoreServiceStation();
