/**
 * Created by henian.xu on 2018/9/11.
 * 商品模块
 */
import { Buyer } from '@/api/buyer/Buyer';

class Mb extends Buyer {
  constructor() {
    super();
    this.baseUrl += '/mb';
  }
}

export default new Mb();
export { Mb };
