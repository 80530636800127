<!-- Created by henian.xu on 2019/3/14. -->

<template>
  <div
    class="store-goods-stat-item"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <div class="img-obj">
      <div
        class="inner"
        :style="`background-image: url('${data.mainImageUrl||data.specImageUrl||$defaultImg}');`"
      />
    </div>
    <div class="inner">
      <div class="body">
        <div class="label">
          {{ data.goodsName }}
        </div>
      </div>
      <div class="footer">
        <div class="price">
          {{ data.price }}
        </div>
        <!--<div class="ctrl">
                    <Button
                        theme="red"
                        radius
                        size="small"
                        icon="&#xf008;"/>
                </div>-->
        <div v-if="data.totalQuantity">
          x {{ data.totalQuantity }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'StoreGoodsStatItem',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.store-goods-stat-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  background-color: #fff;
  padding: $padding;
  + .store-goods-item {
    margin-top: $margin;
  }
  > .img-obj {
    flex: 0 0 auto;
    width: 1.4rem;
    margin-right: $margin;
    > .inner {
      width: 100%;
      padding-top: 100%;
      background: transparent center no-repeat;
      background-size: cover;
    }
  }
  > .inner {
    flex: 1 1 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    > .body {
      flex: 1 1 1%;
    }
    > .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
