<!-- Created by henian.xu on 2018/5/11. -->

<template>
  <linker
    class="refund-item"
    tag="div"
    :to="`/refund/detail/${data.id}${isShopStore ? '?isShopStore' : ''}`"
  >
    <div class="header">
      <div class="label">
        售后单号: {{ data.refundSn }}
      </div>
      <div class="extra">
        <span class="tc-main">{{ data.refundStatusName }}</span>
      </div>
    </div>
    <div class="goods-wrap">
      <list class="bc-g1">
        <OrderGoodsItem
          v-for="(item,index) in data.goodsList"
          :key="index"
          :data="item"
          no-can-refund
        />
      </list>
    </div>
    <list>
      <list-item
        label="订单号"
        :extra="data.orderSn"
      />
      <list-item
        label="下单人："
        :extra="data.buyerMobile"
      >
        <div
          class="extra"
          slot="extra"
        >
          <span>{{ data.buyerNickName }}</span>&nbsp;
          <Linker
            class="tc-blue"
            :to="`tel://${data.buyerMobile}`"
          >
            <Icon code="&#xf072;" />{{ data.buyerMobile }}
          </Linker>
        </div>
      </list-item>
      <list-item
        label="退款数量"
        :extra="data.refundQuantity"
      />
      <list-item
        label="退款金额"
      >
        <div
          class="extra price"
          slot="extra"
        >
          {{ data.refundAmount | price }}
        </div>
      </list-item>
    </list>
  </linker>
</template>

<script>
export default {
  name: 'RefundItem',
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    isShopStore: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.refund-item {
  background-color: #fff;
  margin-top: $margin;
  &:first-child {
    margin-top: 0;
  }

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    border-bottom: 1px solid $color-border;
    > .label {
      flex: 1 1 1%;
    }
    > .extra {
      flex: 0 0 auto;
    }
  }
  > .goods-wrap {
  }
  > .body {
  }
  > .footer {
    padding: $padding;
    border-top: 1px solid $color-border;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
