<!-- Created by henian.xu on 2019/6/21. -->

<template>
  <div class="tracking-list">
    <div class="label">
      {{ label }}
    </div>
    <div
      class="item"
      v-for="(item,index) in data"
      :key="index"
    >
      <div class="time">
        <div>{{ item.time | moment('MM-DD') }}</div>
        <div>{{ item.time | moment('HH:mm') }}</div>
      </div>
      <div class="line">
        <icon
          v-if="item.icon"
          :code="item.icon"
          class="fs-more"
          icon-cls="tc-main"
        />
        <div
          v-else
          class="dot"
        />
      </div>
      <div class="content">
        <div
          v-if="item.label"
          class="label"
        >
          {{ item.label }}
        </div>
        <div class="desc">
          {{ item.context }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrackingList',
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.tracking-list {
  background-color: #fff;
  > .label {
    color: $color-text-minor;
    padding: $padding $padding 0 $padding;
  }
  > .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 $padding;
    color: $gray6;
    line-height: 1.2;

    > .line {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-width: 0.7rem;

      &:before,
      &:after {
        display: block;
        content: '';
        width: 1px;
        background-color: $color-main;
      }
      &:before {
        flex: 0 0 auto;
        height: ($padding-big + $padding-small/2);
      }
      &:after {
        flex: 1 1 1%;
      }
      > .dot {
        width: 0.16rem;
        height: 0.16rem;
        border-radius: 100vw;
        background-color: $color-main;
      }
    }
    > .time,
    > .content {
      padding: $padding-big 0;
    }
    > .time {
      flex: 0 0 auto;
      min-width: 0.8rem;
      text-align: right;
      font-size: 0.85 * 100%;
    }
    > .content {
      flex: 1 1 1%;
      word-break: break-all;
      > .label {
        font-size: 1.25 * 100%;
        margin-bottom: $margin-small;
        color: $gray7;
      }
      > .desc {
        color: $gray6;
      }
    }
  }
}
</style>
