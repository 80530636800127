<!-- Created by henian.xu on 2018/12/28. -->

<template>
  <div
    class="distributor-pay"
    @click.stop.prevent="onOpen"
  >
    <div class="out-show">
      <slot />
    </div>

    <transition-group
      name="show"
      v-if="!delayedCreate"
    >
      <div
        v-show="popupShow === 'type'"
        ref="popupType"
        key="type"
        class="popup pay"
        @click.self="close"
      >
        <transition name="popup-down">
          <div
            v-show="popupShow === 'type'"
            class="inner"
          >
            <div class="header">
              <div class="label">
                请选择支付方式
              </div>
            </div>
            <div class="body">
              <List>
                <ListItem
                  v-for="(item,index) in payMethods"
                  :key="index"
                  :icon-class-name="item.iconClassName"
                  :icon="item.icon"
                  :label="item.label"
                  sub-label-class-name="tc-red"
                  :sub-label="item.exportStr"
                  :extra="item.extra"
                  @click="action(item.action,item)"
                >
                  <i
                    v-if="item.iconUrl"
                    :class="['icon',item.iconClassName]"
                    slot="icon"
                  >
                    <img :src="item.iconUrl">
                  </i>
                </ListItem>
                <ListItem
                  v-if="orderPayUrl"
                  v-show="$hasPower(['order:payOnBehalf'])"
                  icon-class-name="tc-second"
                  icon="&#xf060"
                  label="【他人代付】点击复制代付链接"
                  ref="copyLinker"
                  :data-clipboard-text="orderPayUrl"
                />
              </List>
            </div>
            <div
              v-if="hasBack"
              class="footer"
              style="min-height: 0.8rem"
            >
              <Button
                @click="onBack"
              >
                <span class="fs-small tc-g5">稍后支付继续购物</span>
              </Button>
            </div>
          </div>
        </transition>
      </div>

      <!-- credit -->
      <div
        v-show="popupShow === 'credit'"
        ref="popupCredit"
        key="credit"
        class="popup pay"
        @click.self="close"
      >
        <transition name="popup-down">
          <div class="inner">
            <div class="header">
              <div class="label">
                请选择银行卡
              </div>
            </div>
            <div class="body">
              <List>
                <ListItem
                  v-for="item in stripeCardInfoList"
                  :key="item.id"
                  :to="`/pay/stripe/${module}/${orderIds}?cardInfoId=${item.id}`"
                  :label="item.cardNo"
                >
                  <i
                    class="icon visa-icon"
                    slot="icon"
                  >
                    <img src="~@/assets/images/icon/visa-icon.png">
                  </i>
                </ListItem>
                <ListItem
                  :to="`/pay/stripe/${module}/${orderIds}`"
                  label="使用新卡"
                  icon="&#xf015;"
                  icon-class-name="tc-blue"
                />
              </List>
            </div>
          </div>
        </transition>
      </div>

      <!--checkoutCounter-->
      <Page
        v-show="popupShow === 'checkoutCounter'"
        class="bc-w"
        ref="popupCheckoutCounter"
        key="checkoutCounter"
      >
        <Container class="checkout-counter">
          <div class="logo-info">
            <img
              :src="$globalVar.shopData.logoUrl"
              class="log"
            >
            <div class="label">
              {{ $globalVar.shopData.name }}
            </div>
            <!--<div class="desc">{{ $globalVar.shopData.siteUrl }}</div>-->
          </div>
          <div class="checkout-counter-list">
            <div class="item">
              <div class="label">
                订单金额
              </div>
              <div class="content">
                {{ $globalVar.countryData.currencyCode }} {{ payInfo.paymentAmount }}
              </div>
            </div>
            <div
              class="item"
              v-if="paymentPoundage.paymentPoundage"
            >
              <div class="label">
                支付手续费
              </div>
              <div class="content">
                <span class="">+{{ $globalVar.countryData.currencyCode }} {{ paymentPoundage.paymentPoundage }}</span>
                <span
                  v-if="paymentPoundage"
                  class="tc-red fs-small"
                >( 需额外收取 {{ paymentPoundage.paymentPoundageRate }}% 的订单金额 )</span>
              </div>
            </div>
            <div
              class="item"
              v-if="paymentPoundage.exchangeRate"
            >
              <div class="label">
                汇率
              </div>
              <div class="content">
                1{{ $globalVar.countryData.currencyCode }}≈{{ paymentPoundage.exchangeRate }}CNY
              </div>
            </div>
            <div class="item">
              <div class="label">
                总金额
              </div>
              <div class="content">
                {{ $globalVar.countryData.currencyCode }} {{ payInfo.paymentAmount + (paymentPoundage?paymentPoundage.paymentPoundage:0) | price }}
              </div>
            </div>
            <!--<div class="item">
                            <div class="label">商户订单ID</div>
                            <div class="content">AUD 77.77</div>
                        </div>-->
          </div>
          <div class="ctrl">
            <Button
              label="立即支付"
              theme="green"
              class="w-100per"
              size="big"
              @click="action(currentAction.action,currentAction)"
            />
            <!--            <Button
              v-if="orderPayUrl"
              v-show="$hasPower(['order:payOnBehalf'])"
              label="点击复制链接发好友代付"
              theme="second"
              class="w-100per ma-t"
              size="big"
              ref="copyLinker"
              :data-clipboard-text="orderPayUrl"
            />-->
          </div>
        </Container>
        <!--<PageBar>
                    <Button
                        label="立即支付"
                        theme="green"
                        @click="action(currentAction.action,currentAction)"/>
                </PageBar>-->
      </Page>
    </transition-group>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
import payMixin from '@/mixins/pay';
import Clipboard from 'clipboard';
export default {
  name: 'PayPopup',
  mixins: [popupMixin, payMixin],
  data() {
    return {
      delayedCreate: true,
    };
  },
  props: {
    type: {
      type: String,
      default: 'type', // credit
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasOrderPayUrl() {
      const { orderPayUrl, popupShow } = this;
      return orderPayUrl && popupShow === 'type';
    },
  },
  watch: {
    hasOrderPayUrl: {
      handler(val) {
        if (!val) return;
        this.initClipboard();
      },
      immediate: true,
    },
  },
  methods: {
    initClipboard() {
      const { copyLinker } = this.$refs;
      if (!copyLinker) {
        setTimeout(() => {
          this.initClipboard();
        }, 500);
      }

      const copySuccess = () => {
        this.done().then(() => {
          this.$messageBox.alert(
            '链接已拷贝，请转发给您朋友进行代付！请确保【代付人】和订单填写的【付款人】是同一人，订单才能海关申报成功。',
          );
        });
      };
      const copyError = () => {
        this.$messageBox.tips('链接复制失败', 3000);
      };
      [copyLinker].forEach(item => {
        if (!item) return;
        const $el = item.$el || item;
        if (!item || $el.clipboard) return;
        const $node = new Clipboard(item.$el || item);
        $el.clipboard = $node;
        $node.on('success', copySuccess);
        $node.on('error', copyError);
      });
    },
    mount() {
      if (this.delayedCreate) return;
      this.$$popupWrap.appendChild(this.$refs['popupType']);
      this.$$popupWrap.appendChild(this.$refs['popupCredit']);
      this.$$popupWrap.appendChild(this.$refs['popupCheckoutCounter'].$el);
      this.isAppend = true;
    },
    onOpen() {
      const { orderIdsChange, type } = this;
      if (orderIdsChange) {
        this.toPay().then(() => {
          this.gotoType(type);
        });
      } else {
        this.gotoType(type);
      }
    },
    onBack() {
      this.done().then(() => {
        this.$nextTick(() => {
          this.$router.back();
        });
      });
    },
  },
  beforeDestroy() {
    if (!this.isAppend) return;
    this.$$popupWrap.removeChild(this.$refs['popupType']);
    this.$$popupWrap.removeChild(this.$refs['popupCredit']);
    this.$$popupWrap.removeChild(this.$refs['popupCheckoutCounter'].$el);
  },
};
</script>

<style lang="scss">
.distributor-pay {
  &,
  > .out-show {
    /*flex: 1 1 1%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        > .button {
            flex: 1 1 1%;
        }*/
    //width: 100%;
    //height: 100%;
  }
}

.pay.popup {
  justify-content: flex-end;
  align-items: stretch;
  > .inner {
    width: 100%;
    background-color: #fff;
    max-height: 80vh;
  }
}
</style>
