<!-- Created by henian.xu on 2018/4/28. -->

<template>
  <linker
    :to="linkerTo"
    class="collect-item"
    :class="{'double-row':doubleRow}"
  >
    <div class="inner">
      <div
        class="img-wrap"
        :style="`background-image: url('${$options.filters.imgCropping(data.mainImageUrl||$defaultImg,{width:350})}');`"
      >
        <div
          v-if="data.goodsStatus!==3"
          class="sold-out"
        />
      </div>
      <div class="inner">
        <div class="info">
          <div class="name">
            {{ data.goodsName }}
          </div>
          <div class="desc">
            {{ data.goodsSubtitle }}
          </div>
          <div
            class="tag"
            v-if="data.goodsSendMode!==1"
          >
            跨境进口
          </div>
        </div>
        <div
          class="store badge badge-blue"
          v-if="isStore"
        >
          <i class="f-icon">&#xf089;</i> {{ data.storeName }}
        </div>
        <div class="ctrl">
          <div class="price">
            {{ data.price | price }}
            <span
              v-if="data.marketPrice"
              class="old"
            >{{ data.marketPrice | price }}</span>
          </div>
          <div
            class="btn btn-main link fs-big"
            @click.prevent.stop="onCollect"
          >
            <i class="f-icon nma-a">&#xf03b;</i>
          </div>
        </div>
      </div>
    </div>
  </linker>
</template>

<script>
import goodsItemMixin from './goodsItemMixin';

export default {
  name: 'CollectItem',
  mixins: [goodsItemMixin],
  data() {
    return {};
  },
  props: {
    doubleRow: {
      type: Boolean,
      default: false,
    },
    shareEarn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStore() {
      return this.data.storeId;
    },
    linkerTo() {
      if (this.isStore) {
        return `/store/${this.isStore}/goodsDetail/${this.data.goodsId}`;
      } else {
        return `/goods/detail/${this.data.goodsId}`;
      }
    },
  },
  methods: {
    onCollect() {
      this.$messageBox.confirm('确定要取消收藏?', '温馨提示').then(() => {
        this.$api.Buyer.Mb.CollectGoods.delCollectGoods({
          ids: this.data.id,
        }).then(json => {
          const res = json.data;
          this.$emit('delete');
          this.$messageBox.tips(res.msg, '温馨提示');
        });
      });
    },
  },
};
</script>

<style lang="scss">
.collect-item {
  display: block;
  flex: 1 1 1%;
  min-width: 100%;
  padding: 0 $padding;
  background-color: #fff;
  + .collect-item {
    > .inner {
      border-top: 1px solid $color-border;
    }
  }
  > .inner {
    padding: $padding 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    > .img-wrap {
      position: relative;
      background: no-repeat center;
      background-size: cover;
      flex: 0 0 auto;
      width: (280/750 * 100vw);
      height: (280/750 * 100vw);
      max-width: 2.8rem;
      max-height: 2.8rem;
      margin-right: $margin;
      border-radius: 0.05rem;
      overflow: hidden;
      //border: 1px solid $color-border;

      > .sold-out {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png')
          center no-repeat;
        background-size: 70%;
        width: 100%;
        height: 100%;
      }
    }
    > .inner {
      flex: 1 1 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      > .info {
        > .name {
          /*flex: 0 0 auto;*/
          @include text-line(2);
          font-size: 0.3rem;
          line-height: 1.2;
          color: #000;
        }
        > .desc {
          @include text-line(1);
          font-size: 0.26rem;
          color: $color-text-minor;
        }
        > .tag {
          align-self: flex-start;
          display: inline-block;
          font-size: 0.2rem;
          color: $color-main;
          padding: 0 0.2rem;
          border-radius: 100vw;
          vertical-align: middle;
          border: 1px solid $color-main;
        }
      }
      > .store {
        font-size: 0.26rem;
        align-self: flex-start;
      }
      > .share-earn {
        font-size: 0.26rem;
        color: $color-main;
      }
      > .extra {
        font-size: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        > span {
          display: inline-block;
          + span {
            margin-left: $margin-small;
          }
        }
      }

      > .ctrl {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .price {
          font-weight: bold;
          > .old {
            font-weight: normal;
          }
        }
      }
    }
  }

  /* 双排 */
  &.double-row {
    flex: 0 0 auto;
    min-width: initial;
    width: 50%;
    padding: $padding/2;

    + .goods-item {
      > .inner {
        border-top: 0;
      }
    }
    &:nth-child(odd) {
      padding-left: $padding;
    }
    &:nth-child(even) {
      padding-right: $padding;
    }
    > .inner {
      padding: 0;
      height: 100%;
      flex-direction: column;

      > .img-wrap {
        width: 100%;
        height: 0;
        padding-top: 100%;
        margin: 0 0 $margin 0;
        max-width: inherit;
        max-height: inherit;
      }
    }
  }
}
</style>
