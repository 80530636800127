/**
 * Created by henian.xu on 2017/11/9.
 *
 */

import Routes from '../routes';
import { getRouteKey, matches } from '../utils';
import mixin from './mixin';

export default keyName => {
  return {
    name: 'navigation',
    mixins: [mixin],
    abstract: true,
    props: {},
    data() {
      return {
        keyName,
        routes: Routes,
        cache: {},
      };
    },
    computed: {},
    watch: {
      routes(val) {
        for (const key in this.cache) {
          if (this.cache.hasOwnProperty(key) && !matches(val, key)) {
            const vnode = this.cache[key];
            vnode && vnode.componentInstance.$destroy();
            delete this.cache[key];
          }
        }
      },
    },
    created() {},
    destroyed() {
      for (const key in this.cache) {
        if (this.cache.hasOwnProperty(key)) {
          const vnode = this.cache[key];
          vnode && vnode.componentInstance.$destroy();
        }
      }
    },
    render() {
      const vnode = this.$slots.default ? this.$slots.default[0] : null;
      if (vnode) {
        vnode.key = vnode.key || (vnode.isComment ? 'comment' : vnode.tag);
        // prevent vue-router reuse component
        const key = getRouteKey(this.$route, keyName);
        if (vnode.key.indexOf(key) === -1) {
          vnode.key = `__navigation-${key}-${vnode.key}`;
        }
        if (this.cache[key]) {
          if (vnode.key === this.cache[key].key) {
            // restore vnode from cache
            vnode.componentInstance = this.cache[key].componentInstance;
          } else {
            // replace vnode to cache
            this.cache[key].componentInstance.$destroy();
            this.cache[key] = vnode;
          }
        } else {
          // cache new vnode
          this.cache[key] = vnode;
        }
        vnode.data.keepAlive = true;
      }
      return vnode;
    },
  };
};
