/**
 * Created by henian.xu on 2018/4/19.
 *
 */

export const waterfallMixin = {
  data() {
    return {
      isWaterfallBusy: false,
      disabledWaterfall: false,
    };
  },
  methods: {
    checkWaterfallBusy() {
      let count = 0;
      return new Promise(resolve => {
        if (!this.isWaterfallBusy) return resolve();
        const timeId = setInterval(() => {
          if (this.isWaterfallBusy && count < 4) {
            count += 1;
            return;
          }
          resolve();
          clearInterval(timeId);
        }, 500);
      });
    },
    onReflowed() {
      this.isWaterfallBusy = false;
    },
  },
};

export default {
  data() {
    return {
      infiniteDisabled: false,
      pagination: {},
    };
  },
  computed: {
    $$infiniteState() {
      const infinite = this.$refs['infinite'];
      return infinite ? infinite.infiniteState : null;
    },
  },
  methods: {
    async onInfinite($state) {
      let { currentPage, totalRows, totalPages } = this.pagination;
      if (totalRows && currentPage >= totalPages) {
        $state.loaded();
        $state.complete();
        return;
      }
      const json = await this.getListData();
      if (!json.disabledWaterfall && this.checkWaterfallBusy) {
        this.isWaterfallBusy = true;
        await this.checkWaterfallBusy();
      }
      const result = json.data;
      this.pagination = result.pagination;
      ({ currentPage, totalRows, totalPages } = this.pagination);
      if (this.pagination.currentPage === 1) {
        $state.reset();
      }
      if (!totalRows || currentPage >= totalPages) {
        if (totalRows) {
          $state.loaded();
        }
        $state.complete();
      } else {
        $state.loaded();
      }
    },
  },
};
