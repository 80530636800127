<!-- Created by henian.xu on 2019/6/4. -->

<template>
  <transition-group name="second">
    <div
      v-if="type===1"
      :key="1"
      class="page-second-header"
    >
      <Tabs
        average
        @tabChange="onTabsChange"
      >
        <TabPanel
          :active-panel="sign === 'personal'"
          sign="personal"
          label="个人"
        />
        <TabPanel
          :active-panel="sign === 'team'"
          sign="team"
          label="团队"
        />
      </Tabs>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: 'SecondHeader',
  data() {
    return {};
  },
  computed: {
    sign() {
      return this.$route.params.sign + '';
    },
    type() {
      const { query, meta } = this.$route;
      if (query.hasOwnProperty('secondHeaderShow'))
        return +query.secondHeaderShow || 0;
      else if (meta.hasOwnProperty('secondHeaderShow'))
        return +meta.secondHeaderShow || 0;
      return 0;
    },
  },
  methods: {
    onTabsChange(item) {
      this.infiniteDisabled = true;
      this.dataList = [];
      this.pagination = {};
      this.$nextTick(() => {
        this.$router.replace(
          `/distributor/salesman/${item.sign}`,
          () => {},
          () => {
            this.infiniteDisabled = false;
          },
        );
      });
    },
  },
};
</script>

<style lang="scss">
</style>
