<!-- Created by henian.xu on 2018/12/14. -->

<template>
  <div class="country-code">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <div class="label">
        {{ modelStr||placeholder }}
      </div>
      <i class="f-icon">&#xf012;</i>
    </div>

    <!-- popup -->
    <transition :name="aniName">
      <Page
        v-show="popupShow"
        ref="popup"
      >
        <div class="header">
          <div class="label">
            国家（或地区）码
          </div>
        </div>
        <container>
          <List>
            <ListItem
              v-for="item in dataList"
              :key="item.id"
              :label="item.regionName"
              :extra="`+${item.countryCode}`"
              @click="onItem(item)"
            />
          </List>
        </container>
      </Page>
    </transition>
  </div>
</template>

<script>
import emitter from '@/mixins/emitter';
import popupMixin from '@/mixins/popup';
export default {
  name: 'CountryCode',
  mixins: [popupMixin, emitter],
  data() {
    return {
      dataList: [],
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.dispatch('formItem', 'x.form.change', [val]);
      },
    },
    modelStr() {
      const currentItem = this.dataListMap[this.model] || {};
      const countryCode = currentItem.countryCode || '';
      return countryCode ? `${currentItem.regionName} (+${countryCode})` : '';
    },
    dataListMap() {
      return this.dataList.reduce((p, c) => ((p[c.id] = c), p), {});
    },
    initInput() {
      const { model, dataList } = this;
      if (model || !dataList || !dataList.length) return 0;
      const selectedItem = dataList.find(item => item.isSelect);
      return selectedItem ? selectedItem.id : dataList[0].id;
    },
  },
  watch: {
    initInput: {
      handler(val) {
        if (!val) return;
        this.model = val;
      },
      immediate: true,
    },
  },
  methods: {
    getDataList() {
      this.$api.Comm.Rs.CountryCode.findShopCountryCodeList().then(json => {
        this.dataList = json.data.data;
      });
    },
    onItem(item) {
      this.model = item.id;
      this.done();
    },
  },
  created() {
    this.getDataList();
  },
};
</script>

<style lang="scss">
.country-code {
  flex: 1 1 1%;
  line-height: $formItemHeight;
  .out-show {
    /*line-height: 0.3rem;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .f-icon {
      font-size: 60%;
      line-height: 1;
      margin-left: $margin-small/2;
    }
  }
}
</style>
