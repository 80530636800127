<!-- Created by henian.xu on 2019/3/6. -->

<template>
  <div class="store-goods-popup">
    <div
      class="out-show"
      @click="onSwitch"
    >
      <slot />
    </div>
    <!-- popup -->
    <transition :name="aniName">
      <Page
        ref="popup"
        v-show="popupShow"
      >
        <Container>
          <List>
            <StoreGoodsItem
              v-for="(item) in goodsList"
              :key="item.id"
              :data="item"
              @click="onItem(item)"
            />
          </List>

          <infinite-loading
            ref="infinite"
            :disabled="infiniteDisabled"
            @infinite="onInfinite"
          />
        </Container>
      </Page>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';
import infiniteLoading from '@/mixins/infiniteLoading';
import StoreGoodsItem from './_StoreGoodsItem';

export default {
  name: 'StoreGoodsPopup',
  mixins: [popupMixin, infiniteLoading],
  components: { StoreGoodsItem },
  data() {
    return {
      goodsList: [],
      infiniteDisabled: true,
    };
  },
  watch: {
    popupShow(val) {
      if (val) {
        this.goodsList = [];
        this.pagination = {};
        this.infiniteDisabled = false;
      } else {
        this.infiniteDisabled = true;
      }
    },
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreGoods.goodsList({
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.goodsList = this.goodsList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onItem(item) {
      this.$emit('input', item);
      this.close();
    },
  },
};
</script>

<style lang="scss">
.store-goods-popup {
}
</style>
