<!-- Created by henian.xu on 2019/7/5. -->

<template>
  <div class="id-card-valid">
    <template v-if="!orderId">
      <XInput
        class="id-card-valid"
        v-model="model"
        :disabled="disabled"
      />

      <template v-if="goodsSendMode===2&&isValidate">
        <div
          v-if="isPass"
          class="pass tc-green"
        >
          <Icon code="&#xf019;" />已认证
        </div>
        <div
          v-else
          class="pass tc-red"
          @click="onCertification"
        >
          <Icon code="&#xf01a;" />点击认证
        </div>
      </template>
    </template>

    <!--popup-->
    <transition :name="aniName">
      <Page
        class="bc-g2"
        ref="popup"
        v-show="popupShow"
      >
        <Container>
          <XForm
            ref="form"
            :rules="formRules"
            :model="formModel"
          >
            <FormItem
              label="姓名:"
              prop="idCardName"
            >
              <XInput
                v-model="consignee"
                :readonly="!orderId"
              />
            </FormItem>
            <FormItem
              label="身份证号:"
              prop="idCardNo"
            >
              <XInput
                v-model="model"
                :readonly="!orderId"
              />
              <template v-if="orderId&&goodsSendMode===2&&isValidate">
                <div
                  v-if="isPass"
                  class="pass tc-green"
                >
                  <Icon code="&#xf019;" />已认证
                </div>
              </template>
            </FormItem>
            <template v-if="!orderId || (goodsSendMode===2&&isValidate&&!isPass)">
              <FormItem
                label="人像面"
                prop="idCardFront"
              >
                <upload
                  class="npa-a id-upload front"
                  action="/rpc/comm/rs/attachment/uploadFileLibrary"
                  accept="image/*"
                  v-model="formModel.idCardFront"
                  :max-files="1"
                  :column="1"
                />
              </FormItem>
              <FormItem
                label="国徽面"
                prop="idCardBack"
              >
                <upload
                  class="npa-a id-upload back"
                  action="/rpc/comm/rs/attachment/uploadFileLibrary"
                  accept="image/*"
                  v-model="formModel.idCardBack"
                  :max-files="1"
                  :column="1"
                />
              </FormItem>
            </template>
          </XForm>
        </Container>
        <PageBar>
          <Button
            label="提交"
            theme="main"
            @click="()=>{onSubmit()}"
          />
        </PageBar>
      </Page>
    </transition>
  </div>
</template>

<script>
import { Valid } from '@/utils';
import mixinPopup from '@/mixins/popup';

export default {
  name: 'IdCardValid',
  mixins: [mixinPopup],
  data() {
    return {
      formModel: {
        idCardName: '',
        idCardNo: '',
        idCardFront: [],
        idCardBack: [],
      },
      formRules: {
        idCardName: {
          required: true,
          message: '姓名不能为空',
        },
        idCardNo: [
          {
            required: true,
            message: '身份证号不能为空',
          },
          {
            validator: this.$utils.Valid.idCard,
          },
        ],
        idCardFront: {
          type: 'array',
          min: 1,
          required: true,
          message: '身份证正面不能为空',
        },
        idCardBack: {
          type: 'array',
          min: 1,
          required: true,
          message: '身份证反面不能为空',
        },
      },
      isPass: false,
      isValidate: false,
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    deliveryId: {
      type: [String, Number],
      default: '',
    },
    deliveryConsignee: {
      type: String,
      default: '',
    },
    /**
     * 1:普通商品无关身份证
     * 2:个人行邮 必须身份认证(要上传身份证)
     * 3:(BC直邮) 需要身份证号
     * 4:(保税区发货) 需要身份证号
     */
    goodsSendMode: {
      type: [String, Number],
      default: '',
    },
    // 有订单id时只为了订单列表上传身份证认证
    orderId: {
      type: Number,
      default: void 0,
    },
  },
  computed: {
    model: {
      get() {
        const { value, orderId, formModel } = this;
        return orderId ? formModel.idCardNo : value;
      },
      set(val) {
        const { orderId, formModel } = this;
        if (orderId) {
          formModel.idCardNo = val;
        } else {
          this.$emit('input', val);
        }
        this.validate(val).catch(() => {});
      },
    },
    consignee: {
      get() {
        const { orderId, formModel, deliveryConsignee } = this;
        return orderId ? formModel.idCardName : deliveryConsignee;
      },
      set(val) {
        const { orderId, formModel } = this;
        if (orderId) {
          formModel.idCardName = val;
        }
      },
    },
    deliveryChange() {
      // if (this.orderId) return '';
      return `${this.deliveryId}${this.consignee}`;
    },
  },
  watch: {
    deliveryChange: {
      handler() {
        this.validate().catch(() => {});
      },
      immediate: true,
    },
    value: {
      handler(val) {
        this.formModel.idCardNo = val;
      },
      immediate: true,
    },
    deliveryConsignee: {
      handler(val) {
        this.formModel.idCardName = val;
      },
      immediate: true,
    },
  },
  methods: {
    async validate(val = this.model) {
      this.isValidate = false;
      if (!Valid.idCard(null, val)) return Promise.reject();
      const { deliveryId, consignee, goodsSendMode } = this;
      if (!consignee) {
        this.isValidate = true;
        return Promise.reject();
      }
      if (deliveryId) {
        await this.$api.Buyer.Mb.BuyerDeliveryAddress.edit({
          id: deliveryId,
          idCardNo: val,
        });
      }
      if (goodsSendMode !== 2 && goodsSendMode !== 4) {
        this.isValidate = true;
        return Promise.resolve();
      }
      const json = await this.$api.Buyer.Mb.BuyerIdCard.checkBuyerIdCard({
        idCardName: consignee,
        idCardNo: val,
      });
      const res = json.data;
      if (!res.success) {
        this.isPass = false;
        this.isValidate = true;
        return Promise.reject();
        // return Promise.resolve(); // 身份证未认证也可以提交订单
      }
      this.isPass = true;
      this.isValidate = true;
    },
    async onSubmit() {
      await this.$refs['form'].validate();
      const { formModel, orderId } = this;
      const data = {
        orderId,
        idCardFront: formModel.idCardFront
          .reduce((pre, curr) => (pre.push(curr.name), pre), [])
          .join(','),
        idCardBack: formModel.idCardBack
          .reduce((pre, curr) => (pre.push(curr.name), pre), [])
          .join(','),
        idCardName: this.consignee,
        idCardNo: this.model,
      };
      await this.$api.Buyer.Mb.BuyerIdCard.saveBuyerIdCard(data);
      this.isPass = true;
      this.isValidate = true;
      await this.done();
      this.$emit('pass', { deliveryConsignee: this.consignee });
    },
    onCertification() {
      this.open();
    },
  },
};
</script>

<style lang="scss">
.id-card-valid {
  flex: 1 1 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > .pass {
    flex: 0 0 auto;
  }
}
.upload.id-upload {
  > .item {
    > .inner {
      padding-top: 32%;
      padding-bottom: 32%;
    }

    &.add {
      > .inner {
        background: transparent center no-repeat;
        background-size: contain;
        > .f-icon {
          display: none;
        }
      }
    }
  }
  &.front {
    > .item.add > .inner {
      background-image: url('~@/assets/images/comm/idzhengmian.png');
    }
  }
  &.back {
    > .item.add > .inner {
      background-image: url('~@/assets/images/comm/idbeimian.png');
    }
  }
}
</style>
