<!-- Created by henian.xu on 2018/9/12. -->

<template>
  <div class="widget">
    <!--<div class="console">{{ loadedLength }} / {{ componentLength }}</div>-->
    <template v-for="(item) in componentList">
      <x-shell
        :key="item.id"
        :data="item"
        @complete="onShellComplete"
      >
        {{ item.componentType }}
      </x-shell>
    </template>
    <!--    <infinite-loading
      ref="infinite"
      :no-show-complete="noShowComplete"
      :disabled="infiniteDisabled"
      @infinite="onInfinite"
    />-->
  </div>
</template>

<script>
import storeMixin from './storeMixin';
import Shell from './_shell';
export default {
  name: 'Widget',
  mixins: [storeMixin],
  components: { XShell: Shell },
  data() {
    return {
      infiniteDisabled: true,
      loadedLength: 0,
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    json: {
      type: String,
      default: '[]',
    },
    noShowComplete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentLength() {
      return this.componentList.length;
    },
    hasComponent() {
      return !!this.componentLength;
    },
  },
  watch: {
    id: {
      handler(val) {
        if (!isNaN(val) && +val) {
          // api
          this.getJsonData(val).then(json => {
            const res = json.data.data;
            this.setDecoration(res || '[]');
            this.$emit('decorationData', this.decorationData);
          });
        } else {
          // json
          this.setDecoration(this.json || '[]');
          this.$emit('decorationData', this.decorationData);
        }
      },
      immediate: true,
    },
    hasComponent: {
      handler(val) {
        this.infiniteDisabled = !val;
      },
      immediate: true,
    },
  },
  methods: {
    getJsonData(id = this.id) {
      return this.$api.Buyer.Sp.ShopDecoration.getAdPageJson({
        adPageType: id,
      }).then(json => {
        return Promise.resolve(json);
      });
    },
    onShellComplete() {
      this.infiniteDisabled = false;
    },
    onInfinite($state) {
      // const { loadedLength, componentLength } = this;
      this.loadedLength++;
      if (this.loadedLength > this.componentLength) {
        $state.loaded();
        $state.complete();
        console.log('complete');
      } else {
        $state.loaded();
        this.infiniteDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss">
.console {
  position: fixed;
  z-index: $z-index-10;
  top: 0.9rem;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: $padding;
}
</style>
