<!-- Created by henian.xu on 2019/3/5. -->

<template>
  <div
    class="store-service-station-item"
    v-bind="linkerProps"
    v-on="$listeners"
  >
    <div class="header">
      <div class="label">
        {{ data.stationName }}
      </div>
      <div
        v-if="data.isAvailable"
        class="badge badge-main fs-small"
      >
        已启用
      </div>
      <div
        v-else
        class="badge badge-g5 fs-small"
      >
        未启用
      </div>
    </div>
    <div class="footer">
      <div class="address">
        {{ data.stationRegionName }}
      </div>
      <Button
        @click.prevent.stop="onDel"
        theme="red"
        size="small"
        radius
        icon="&#xf008;"
      />
    </div>
  </div>
</template>

<script>
import linkerMixin from '@/mixins/linker';
export default {
  name: 'StoreServiceStationItem',
  mixins: [linkerMixin],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onDel() {
      this.$messageBox.confirm('是否确认删除').then(() => {
        this.$api.Buyer.Sp.ShopStoreServiceStation.delServiceStation(
          this.data.id,
        ).then(json => {
          const res = json.data;
          this.$messageBox.tips(res.msg);
          this.$emit('del');
        });
      });
    },
  },
};
</script>

<style lang="scss">
.store-service-station-item {
  display: block;
  background-color: #fff;
  padding: $padding;
  + .service-station-item {
    margin-top: $margin;
  }
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .label {
      font-weight: bold;
    }
  }
  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .address {
      font-size: 85%;
      color: $gray6;
    }
  }
}
</style>
