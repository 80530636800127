/**
 * Created by henian.xu on 2019/6/10.
 *
 */

import { createNamespacedHelpers } from 'vuex';
const {
  // mapState,
  mapGetters,
  // mapMutations,
  // mapActions,
} = createNamespacedHelpers('meiQia');
export default {
  computed: {
    ...mapGetters(['meiQiaAllSet', 'unreadMsg', 'unreadMsgCount']),
  },
};
