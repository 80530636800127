<!-- Created by henian.xu on 2017/10/2. -->

<template>
  <div :class="['ad-cube',{'border':border},{'reverse':reverse}]">
    <div
      :is="firstItem.href?'Linker':'div'"
      class="item"
      :to="firstItem.href"
    >
      <div
        class="inner"
      >
        <img
          :src="firstItem.src || $defaultImg | imgCropping"
          alt=""
        >
      </div>
    </div>
    <div class="surplus-wrap">
      <div
        v-for="(item,index) in surplusItem"
        :is="item.href?'Linker':'div'"
        :to="item.href"
        class="item"
        :key="item.id || index"
      >
        <div
          class="inner"
        >
          <img
            :src="item.src || $defaultImg | imgCropping"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '@/assets/images/comm/default.jpg';

export default {
  name: 'AdCube',
  data() {
    return {
      defaultImg,
    };
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    noZoom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstItem() {
      const { data } = this;
      return [...data].shift();
    },
    surplusItem() {
      const { data } = this;
      return [...data].splice(1, data.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.ad-cube {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  > .item,
  > .surplus-wrap {
    flex: 1 1 auto;
    width: 50%;
  }
  .item {
    > .inner {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      //border-radius: $padding-small;
      //overflow: hidden;

      > img {
        display: block;
        width: 100%;
        height: 100%;
        //height: auto;
        object-fit: contain;
      }
    }
  }
  > .surplus-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    > .item {
      flex: 1 1 1%;
    }
  }

  &.border {
    &,
    .item {
      padding: $padding-small;
    }
  }

  &.reverse {
    > .item {
      order: 2;
    }
    > .surplus-wrap {
      order: 1;
    }
  }
}
</style>
