<!-- Created by henian.xu on 2018/8/6. -->

<template>
  <div
    class="container"
    ref="scrollContainer"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
import scrollMemory from '@/mixins/scrollMemory';

export default {
  name: 'Container',
  mixins: [scrollMemory],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
