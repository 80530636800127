<!-- Created by henian.xu on 2018/3/2. -->

<template>
  <div class="time-select">
    <div
      class="inner"
      @click="open"
    >
      <div
        class="label"
        v-if="allTime_"
      >
        所有
      </div>
      <div
        class="label"
        v-else
      >
        {{ value.beginDate||'所有' }} 至 {{ value.endDate||'所有' }}
      </div>
      <div class="btn btn-link">
        <i class="f-icon">&#xf035;</i>
      </div>
    </div>

    <transition :name="aniName">
      <Page
        class="bc-g1"
        v-show="!!popupShow"
        ref="popup"
      >
        <div class="header">
          <div class="ctrl-left">
            <a
              href="javascript:history.go(-1);"
              class="btn"
            ><i class="f-icon">&#xf011;</i></a>
          </div>
          <div class="label">
            选择时间
          </div>
          <div class="ctrl-right">
            <div
              class="btn"
              @click="timeFinish()"
            >
              完成
            </div>
          </div>
        </div>
        <container>
          <list>
            <list-item
              class="reverse"
              extra="开始日期"
            >
              <div
                class="label input"
                slot="label"
              >
                <!--<input type="date" v-model="formDateData.beginDate" placeholder="请选择日期">-->
                <datetime
                  :min-year="1950"
                  class="datetime"
                  v-model="formDateData.beginDate"
                  :format="format"
                >
                  {{ formDateData.beginDate||'请选择日期' }}
                </datetime>
              </div>
            </list-item>
            <list-item
              class="reverse"
              extra="结束日期"
            >
              <div
                class="label input"
                slot="label"
              >
                <!--<input type="date" v-model="formDateData.endDate" placeholder="请选择日期">-->
                <datetime
                  :min-year="1950"
                  class="datetime"
                  v-model="formDateData.endDate"
                  :format="format"
                >
                  {{ formDateData.endDate||'请选择日期' }}
                </datetime>
              </div>
            </list-item>
          </list>
          <div class="pa-a">
            <div
              class="btn btn-g6 link bor fs-big fill"
              @click="onClear"
            >
              清空
            </div>
          </div>
        </container>
      </Page>
    </transition>
  </div>
</template>

<script>
import popup from '@/mixins/popup';

export default {
  name: 'TimeSelect',
  mixins: [popup],
  data() {
    return {
      appLevel: true,
      formDateData: {
        beginDate: this.value.beginDate,
        endDate: this.value.endDate,
      },
      allTime_: this.allTime,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    allTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {
    'formDateData.beginDate'() {
      this.formDateDataChange();
    },
    'formDateData.endDate'() {
      this.formDateDataChange();
    },
    format() {
      this.timeFinish(false);
    },
  },
  methods: {
    formDateDataChange() {
      const stateBeginDate = this.$moment(this.formDateData.beginDate);
      const endDate = this.$moment(this.formDateData.endDate);
      let begin = stateBeginDate.format('x');
      let end = endDate.format('x');
      begin = isNaN(begin) ? 0 : +begin;
      end = isNaN(end) ? 0 : +end;
      if (begin > end) {
        [begin, end] = [end, begin];
        [this.formDateData.beginDate, this.formDateData.endDate] = [
          this.formDateData.endDate,
          this.formDateData.beginDate,
        ];
      }
      if (
        this.formDateData.endDate === '' &&
        this.formDateData.beginDate === ''
      ) {
        this.allTime_ = true;
      } else {
        this.allTime_ = false;
      }
    },
    timeFinish(close = true) {
      this.$emit('input', this.formDateData);
      this.$emit('change', this.formDateData);
      if (close) {
        this.popupShow = false;
      }
    },
    onClear() {
      this.formDateData.endDate = this.formDateData.beginDate = '';
      this.timeFinish();
    },
  },
  created() {
    if (this.allTime_) {
      this.formDateData.endDate = this.formDateData.beginDate = '';
      return;
    }
    const now = this.$moment();
    if (!this.value.endDate) {
      this.formDateData.endDate = this.value.endDate = now
        .endOf('month')
        .format(this.format);
    }
    if (!this.value.beginDate) {
      this.formDateData.beginDate = this.value.beginDate = now
        .startOf('month')
        .format(this.format);
    }
    this.$emit('input', this.formDateData);
    this.$emit('change', this.formDateData);
  },
  mounted() {},
};
</script>

<style lang="scss">
.time-select {
  padding: 0 $padding;
  background-color: #fff;
  line-height: 0.9rem;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
