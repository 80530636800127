/**
 * Created by henian.xu on 2018/4/27.
 *
 */

import event from './event';
import Vue from 'vue';
import Goods from '@/api/buyer/Gd/Goods';
import { Comm } from '@/utils';

const vueInstance = new Vue();

export default {
  namespaced: true,
  modules: {
    event,
  },
  state: {
    catData: {},
  },
  getters: {
    catMap(state) {
      const res = {};
      // if (!state.catData) return res;
      Object.keys(state.catData).forEach(key => {
        const flatten = Comm.arrayFlatten(state.catData[key], 'children');
        res[key] = flatten.reduce((p, c) => ((p[c.id] = c), p), {});
      });
      return res;
    },
    catList(state) {
      const { catData } = state;
      return activityType => {
        return catData[activityType] || null;
      };
    },
  },
  mutations: {
    setCatList(state, data) {
      state.catData = state.catData || {};
      vueInstance.$set(state.catData, data.activityType, data.data);
    },
  },
  actions: {
    getCatList({ commit }, { activityType = 0, fulfilGiveId } = {}) {
      if (isNaN(activityType)) return;
      return Goods.shopGoodsCatList({ activityType, fulfilGiveId }).then(
        json => {
          const res = json.data.data;
          commit('setCatList', { activityType, data: res });
        },
      );
    },
  },
};
