/**
 * Created by henian.xu on 2018/2/6.
 * weixin js sdk
 */

import Vue from 'vue';
import wx from 'jweixin';
import GlobalVar from 'globalVar';
import Http from '@/api/base/HttpBase';
import { Comm } from '@/utils';
import Url from 'url';
import Qs from 'qs';

class Weixin extends Http {
  constructor() {
    super();
    this._ready = false;
    this.oldShareData = {};
    this.paySign = {
      used: true,
    };
    this.shareFnMap = {
      timeline: ['onMenuShareTimeline'], // 分享到朋友圈
      appMessage: ['onMenuShareAppMessage'], // 分享给朋友
      qq: 'onMenuShareQQ', // 分享到QQ
      weibo: 'onMenuShareWeibo', // 分享到腾讯微博
      qzone: 'onMenuShareQZone', // 分享到QQ空间
    };
    this.wxConfig = {
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: '', // 必填，企业号的唯一标识，此处填写企业号corpid
      timestamp: 0, // 必填，生成签名的时间戳
      nonceStr: '', // 必填，生成签名的随机串
      signature: '', // 必填，签名，见附录1
      jsApiList: [
        'openEnterpriseChat',
        'openEnterpriseContact',
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'onVoicePlayEnd',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'translateVoice',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
      ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    };
    if (!GlobalVar.device.isWeiXin) return;
    this.getSign();
    this.initEvent();
  }

  /**
   * 获取签名
   */
  getSign() {
    super
      .get('/rpc/buyer/mp/wechat/sign', {
        url: window.location.href.split('#')[0],
        // url: 'http://testpay.hsilu.com/',
        _isHandleError: true,
      })
      .then(json => {
        let { wxConfig } = this;
        const result = json.data;
        wxConfig = {
          ...wxConfig,
          ...GlobalVar.wxConfig,
          ...result.data,
        };
        wx.config(wxConfig);
      });
  }

  initEvent() {
    wx.error(this.error);
    wx.ready(this.ready);
  }

  error(/*res*/) {}

  ready() {
    this._ready = true;
  }

  isReady() {
    return new Promise((resolve, reject) => {
      if (!GlobalVar.device.isWeiXin) {
        reject(new Error('不是微信浏览器'));
        return;
      }
      if (this._ready) {
        resolve();
      } else {
        wx.ready(() => {
          this._ready = true;
          resolve();
        });
      }
    });
  }

  /**
   * 设置分享内容
   * @param data
   * @param force
   */
  setShare(data, force) {
    if (this.oldShareData === data && !force) return;
    this.oldShareData = data;

    let uuid = '';
    if (data.setUuid !== false) {
      uuid = data.uuid || Comm.getUniqueId() + GlobalVar.userData.id;
    }
    const uri = Url.parse(GlobalVar.shareLinkUrl);
    const query = Qs.parse(uri.query);
    delete query.shareUuid;
    const search = Qs.stringify(query);
    uri.search = search ? `?${search}` : null;
    const shareBaseUrl = uri.format().replace(/\/$/, '');
    const shareUrl = (() => {
      return data.link
        ? `${uri.search ? '&' : '?'}shareUrl=` +
            encodeURIComponent(`${data.link}`) +
            (uuid ? `&shareUuid=${uuid}` : '')
        : uuid
          ? `${uri.search ? '&' : '?'}shareUuid=${uuid}`
          : '';
    })();
    // const link = `${window.location.href.split('#')[0]}${shareUrl}`;
    // const link = `${window.location.protocol}//${window.location.host}/${shareUrl}`;
    const link = `${shareBaseUrl}/${shareUrl}`;
    data = {
      ...data,
      link,
      uuid,
    };
    const self = this;
    this.isReady()
      .then(() => {
        const { shareFnMap } = this;
        for (const key in shareFnMap) {
          if (shareFnMap.hasOwnProperty(key)) {
            const item = shareFnMap[key];
            const fns = Array.isArray(item) ? item : [item];
            fns.forEach(fn => {
              wx[fn]({
                ...data,
                ...data[key],
                success() {
                  let shareChannel = 3;
                  switch (key) {
                    case 'timeline':
                      shareChannel = 1;
                      break;
                    case 'appMessage':
                      shareChannel = 2;
                      break;
                  }
                  self.shareLater({
                    ...data.successData,
                    shareChannel,
                    shareUuid: uuid,
                    urllink: link,
                  });
                  data.success && data.success(key);
                },
              });
            });
          }
        }
      })
      .catch(() => {});
    return data;
  }

  /**
   * 微信分享成功后
   * @param urllinkType 分享类型。1：商品详情，2：商品类目，3：平台(商城首页)，4：团购分享
   * @param objectId 对象ID  商品详情传goodsId 商品类目传类目ID 商城团购不用传
   * @param urllink 分享地址
   * @param shareUuid 唯一标识
   * @param shareChannel 分享来源。1：朋友圈，2：好友，3：其它不包含QQ等
   */
  shareLater({ urllinkType, objectId, urllink, shareUuid, shareChannel } = {}) {
    const error = JSON.stringify({
      urllinkType,
      objectId,
      urllink,
      shareUuid,
      shareChannel,
    });
    super.post('/rpc/buyer/mb/buyer/error', { error });
    super
      .post('/rpc/buyer/mb/urlLinkShare/urlLinkShare', {
        urllinkType,
        objectId,
        urllink,
        shareUuid,
        shareChannel,
        _isHandleError: true,
      })
      .then(json => {
        console.log(json.data);
      });
  }

  /**
   * 微信扫描二维码
   * @param param
   * @returns {Promise<any>}
   */
  scanQRCode(param = {}) {
    return new Promise((resolve, reject) => {
      this.isReady().then(() => {
        wx.scanQRCode({
          needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          ...param,
          success(res) {
            // const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            param.success && param.success(res);
            resolve(res);
          },
          error(errors) {
            param.error && param.error(errors);
            reject(errors);
          },
        });
      });
    });
  }

  /**
   * 微信支付
   */
  chooseWXPay(paySign) {
    paySign.timestamp = paySign.timestamp || paySign.timeStamp;
    return new Promise((resolve, reject) => {
      this.isReady().then(() => {
        wx.chooseWXPay({
          ...paySign,
          success(res) {
            resolve(res);
          },
          cancel(_err) {
            reject(_err);
          },
        });
      });
    });
  }

  previewImage(current, urls) {
    return this.isReady().then(() => {
      wx.previewImage({
        current: current, // 当前显示图片的http链接
        urls: urls, // 需要预览的图片http链接列表
      });
    });
  }

  /**
   * 获取地理位置接口
   * @param type       // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
   */
  getLocation(/*type = 'wgs84'*/) {
    return new Promise((resolve, reject) => {
      this.isReady()
        .then(() => {
          wx.getLocation({
            // type,
            success(res) {
              resolve(res);
            },
            fail(res) {
              reject(res);
            },
          });
        })
        .catch(reject);
    });
  }

  /**
   * 使用微信内置地图查看位置接口
   * @param latitude
   * @param longitude
   * @param name
   * @param address
   * @param scale
   * @param infoUrl
   */
  openLocation({ latitude, longitude, name, address, scale, infoUrl } = {}) {
    wx.openLocation({
      latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude, // 经度，浮点数，范围为180 ~ -180。
      name, // 位置名
      address, // 地址详情说明
      scale, // 地图缩放级别,整形值,范围从1~28。默认为最大
      infoUrl, // 在查看位置界面底部显示的超链接,可点击跳转
    });
  }
}

const $wx = new Weixin();
Vue.prototype.$wxBase = wx;
Vue.prototype.$wx = $wx;
export default $wx;
export { Weixin };
